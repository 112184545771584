import { MsalProvider, useIsAuthenticated } from "@azure/msal-react";
import AuthWrappedRoutes from "./configs/authWrappedRoutes";

const App = ({ pca }) => {

  return (
      <MsalProvider instance={pca}>
        <AuthWrappedRoutes />
      </MsalProvider>
  );
}

export default App;
