import { trackPromise } from 'react-promise-tracker';
import { acquireToken } from '../configs/authentication/MsGraphApiCall';
import axiosInstance from '../configs/axiosConfigs/interceptors';


const PromotionServices = {
  
    getPromotionList : function (filters) {       
        const data = acquireToken().then((tokenResponse) => {   
            const optionsVal = {
                method: 'GET',
                url: `Promotion/GetPromotions`,
                params: filters,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };            
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    getPromotionDetails : function (filters) {       
        const data = acquireToken().then((tokenResponse) => {   
            const optionsVal = {
                method: 'GET',
                url: `Promotion/GetPromotionDetails`,
                params: filters,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };            
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },
    
    getPromotionById: function (promotionId) {
        const data = acquireToken().then((tokenResponse) => {
            let queryParams = `?PromotionID=${promotionId}`
            const optionsVal = {
                method: 'GET',
                url: `Promotion/GetPromotionDetails${queryParams}`,                        
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    getProgressiveCodes: function (promotionId,  pageNumber, tablePageSize, sorterColumn, sorterType) {
        const data = acquireToken().then((tokenResponse) => {
            let queryParams = `?PromotionID=${promotionId}&PageNo=${pageNumber}&ElementsNo=${tablePageSize}&SortColumn=${sorterColumn}&ascOrdering=${sorterType}`   
            const optionsVal = {
                method: 'GET',
                url: `Promotion/GetProgressiveCodes${queryParams}`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };            
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    generateProgressiveCodes: function(promotionId, nrOfPromotionsToGenerate) {
        const data = acquireToken().then((tokenResponse) => {
          let queryParams = `?PromotionID=${promotionId}&nrOfPromotionsToGenerate=${nrOfPromotionsToGenerate}`;
          const optionsVal = {
            method: 'GET',
            url: `Promotion/GenerateProgressiveCodes${queryParams}`,
            headers: {
              'Authorization': `Bearer ${tokenResponse.accessToken}`,
            }
          };
          const apiResponse = axiosInstance(optionsVal);
          return apiResponse;
        });
        return data;
      },
      
    
    
    createPromotion: function (payload) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'POST',
                url: `Promotion/AddPromotion`,
                data: payload,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    }, 

    getNonActivePromotion: function (selectedDate) {
        const data = acquireToken().then((tokenResponse) => {
            let queryParams = `?WarrantyDate=${selectedDate}`;
            const optionsVal = {
                method: 'GET',
                url: `Promotion/GetNonActivePromotions${queryParams}`,                        
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    updatePromotion: function (payload) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'PUT',
                url: `Promotion/UpdatePromotion`,
                data: payload, 
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    }, 

    sendPromotionCodes: function(promotionId, email) {
        const data = acquireToken().then((tokenResponse) => {
          let queryParams = `?PromotionID=${promotionId}&email=${email}`;
          const optionsVal = {
            method: 'POST',
            url: `Warrenties/SendPromotionalCodesViaMail${queryParams}`,
            headers: {
              'Authorization': `Bearer ${tokenResponse.accessToken}`,
            }
          };
          const apiResponse = axiosInstance(optionsVal);
          return apiResponse;
        });
        return data;
      },

      getLastActionCode: function (year) {
        const data = acquireToken().then((tokenResponse) => {
            let queryParams = `?year=${year}`;
            const optionsVal = {
                method: 'GET',
                url: `Promotion/GetLastActionCode${queryParams}`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                },
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    verifyActionCode: function (actionCode, year) {
        const data = acquireToken().then((tokenResponse) => {
            let queryParams = `?ActionCode=${actionCode}&Year=${year}`;
            const optionsVal = {
                method: 'GET',
                url: `Promotion/VerifyActionCode${queryParams}`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },
    
};

export default PromotionServices;
