
import React, { useState, useEffect } from 'react';
import { ApartmentOutlined, ContainerOutlined, GoldOutlined, HomeOutlined, RightOutlined, SettingOutlined, UserOutlined, PercentageOutlined, ToolOutlined } from '@ant-design/icons';
import { Menu, Layout, Skeleton } from 'antd';
import { Link } from "react-router-dom";
import GeneralServices from '../../../services/generalServices';
import { useSelector } from 'react-redux';

const { Sider } = Layout;

const AppMenu = () => {

  const [menuItems, setMenuItems] = useState([]);
  const currentUser = useSelector(state => state.currentUser.loggedinUserData);

  useEffect(() => {
    getMenuItems();
  }, []);


  const getMenuItems = async () => {
    await GeneralServices.getUserMenuList(currentUser.roleID)
      .then(response => response.data)
      .then(resp => {
        if (resp.success) {
          let respData = resp.responseObject.value.roleMenusList;
          generateUserMenu(respData);
        }
        else {
        }
      }).catch(error => {
      });
  }

  const generateUserMenu = (menuData) => {
    let menuList = [];
    let menu = menuData;

    menu.map((item, index) => {
      if (item.menuItems && item.menuItems.length > 0) {
        let childItems = [];

        // TO DO WHEN THERE ARE MENU ITEMS CHILD

        // item.menuItems.map((child, index) => {
        //     childItems.push({
        //         label: <Tooltip placement="top" title={child.name}><Link to={{ pathname: child.menuItemLink }}> {child.name}</Link> </Tooltip>,
        //         key: `${child.id}`,
        //         icon: <RightOutlined />                       
        //     })
        // });

        // menuList.push({
        //     label: <span>{item.menuName}</span>,
        //     key: `${item.menuID}`,
        //     icon: this.getIconForMenu(item.menuLink),
        //     link: `${item.menuLink}`,
        //     children: childItems
        // })

      }
      else {
        menuList.push({
          label: <Link to={{ pathname: item.link }}>{item.displayName}</Link>,
          key: `${item.id}`,
          icon: generateMenuIcon(item.link),
          link: `${item.link}`,
        })
      }

    })
    
    setMenuItems(menuList);
  }

  const generateMenuIcon = (menuLink) => {
    let icon = null;
    switch (menuLink) {
      case "/dashboard":
        icon = <HomeOutlined />;
        break;
      case "/warranties":
        icon = <ContainerOutlined />;
        break;
      case "/products":
        icon = <GoldOutlined />;
        break;
      case "/clients":
        icon = <ApartmentOutlined />;
        break;
      case "/configs":
        icon = <SettingOutlined />;
        break;
      case "/technicians":
        icon = <ToolOutlined />;
        break;
      case "/users":
        icon = <UserOutlined />
        break;
      case "/promotions":
        icon = <PercentageOutlined />
        break;
      default:
        icon = <RightOutlined />;
    }

    return icon;

  }


  return (
    <Sider width={200} className="site-layout-background menuItems">

      {(menuItems && menuItems.length > 0) && (
        <Menu mode="inline" style={{ height: '100%', borderRight: 0 }} items={menuItems} />
      )}

      {(menuItems.length < 1) && (
        <Skeleton active />
      )}

    </Sider>
  );
}

export default AppMenu;