import React, { useState, useEffect } from 'react';
import { Table, message, Row, Col, Button } from 'antd';
import { notifyMessages } from '../../shared/utils/errorMesages';
import TechniciansFilters from './techniciansFilters';
import { dateFormat, excelfileExtension, excelfileType, tablePageSize } from '../../shared/utils/constants';
import moment from 'moment';
import { ExportOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import GeneralServices from '../../services/generalServices';
import { useSelector } from 'react-redux';
import { isCat } from '../../configs/authRoleFunctions';

const TechniciansList = () => {

  let filtersInitValues = {
    name: '',
    surname: '',
    id: '',
    code: '',
    appaltoCode: '',
    isDescending: true
  };
  

  const [technicsList, setTechnicsList] = useState(null);
  const [loadingTechnicsList, setLoadingTechnicsList] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterValues, setFilterValues] = useState(filtersInitValues);
  const [totalCountRecords, setTotalCountRecords] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [sorterColumn, setSorterColumn] = useState("");
  const [sorterType, setSorterType] = useState(true);
  const currentUser = useSelector(state => state.currentUser.loggedinUserData);
  
  useEffect(() => {
    getTechniciansList();
  }, [pageNumber, filterValues, sorterColumn, sorterType]);


  const getTechniciansList = async (isExport) => {
    let filterObj = filterValues;
    filterObj["pageNo"] = isExport ? 0 : pageNumber;
    filterObj["sortColumn"] = sorterColumn;
    filterObj["ascOrdering"] = sorterType;
    filterObj["elementsNo"] = tablePageSize;

    if (isCat(currentUser)) {
      filterObj["appaltoCode"] = currentUser.appaltoCode; 
    }

    setLoadingTechnicsList(isExport ? false : true);
    setLoadingExport(isExport ? true : false);

    await GeneralServices.getAllTechnicsList(filterObj)
        .then(response => response.data)
        .then(resp => {
            if (resp.success) {
                let respData = resp.responseObject.value;

                if (isExport) {
                    generateExcelFile(respData.allTechniciansList);
                }
                else {
                    let record = respData.allTechniciansList.find(function (element) {
                        return element.recordNo != null;
                    });
                    setTechnicsList(respData.allTechniciansList);
                    setTotalCountRecords(record ? record.recordNo : "")
                    setLoadingTechnicsList(false);
                }                    
            }
            else {
                setLoadingTechnicsList(false);
                message.error(notifyMessages.errorAccured);
            }
        }).catch(error => {
            setLoadingTechnicsList(false);
            message.error(notifyMessages.errorAccured);
        });
  }

  const onPageSorterChange = (page, filters, sorter) => {
    if (page) {
      if (sorter && sorter.field) {
          setSorterColumn(sorter.field);
          setPageNumber(page.current);

          if (sorter.order === "descend") {
              setSorterType(false);
          }
          if (sorter.order === "ascend") {
              setSorterType(true);
          }
      }
      else {
          setPageNumber(page.current);
          setSorterColumn("");
          setSorterType(true);
      }
    }
  }

  const filterData = (values) => {
    setFilterValues(values);
    setPageNumber(1);
  };

  const clearFilters = () => {
    setPageNumber(1);
    setFilterValues(filtersInitValues);
  };

  const technicsColumns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      sorter: (a, b) => {
        a = a.name || '';
        b = b.name || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],
    },
    {
      title: 'Cognome',
      dataIndex: 'surname',
      sorter: (a, b) => {
        a = a.surname || '';
        b = b.surname || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],     
    },
    {
      title: 'Codice',
      dataIndex: 'code',
      sorter: (a, b) => {
        a = a.id || '';
        b = b.id || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],     
    },
    {
      title: 'Codice Appalto',
      dataIndex: 'appaltoCode',
      sorter: (a, b) => {
        a = a.code || '';
        b = b.code || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],
    },
  ];

  const generateExcelFile = (technicsData) => {
    let formatedData = [];
    
    if (technicsData && technicsData.length > 0) {

        technicsData.map(item => {
        formatedData.push({
          name: item.name,
          surname: item.surname,
          code: item.code,
          appaltoID: item.appaltoCode
        })
      });

      const fileName = `ESPORTAZIONE-TECNICI-${moment().format(dateFormat)}`;
      let headers = [['Nome', 'Cognome', 'Codice', 'Codice Appalto']];
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(ws, formatedData, { origin: 'A2', skipHeader: true })
      XLSX.utils.sheet_add_aoa(ws, headers);
      const wb = { Sheets: { 'Esportazione Tecnici': ws }, SheetNames: ['Esportazione Tecnici'] };

        let wsColsWidthFormatter = [
            { wpx: 120 }, // column width
            { wpx: 120 }, // column width
            { wpx: 120 }, // column width
            { wpx: 120 }, // column width
        ];
        ws['!cols'] = wsColsWidthFormatter;

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: excelfileType });
      FileSaver.saveAs(data, fileName + excelfileExtension);
      setLoadingExport(false);

    }
    else {
      message.warning(notifyMessages.noDataFound);
      setLoadingExport(false);
    }
  }

  const onExportTechnicsList = () => {
    getTechniciansList(true);
  }

  return (
    <>
      <Row justify="center" gutter={[16, 16]}>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <TechniciansFilters
            onFilterData={filterData}
            onResetData={clearFilters}
            filterValues={filterValues}
            filtersInitValues={filtersInitValues}
          />
        </Col>

        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <Table
            size='small'
            columns={technicsColumns}
            dataSource={technicsList}
            rowKey={(obj) => obj.id}
            loading={loadingTechnicsList}
            onChange={(pagination, filters, sorter) => onPageSorterChange(pagination, filters, sorter)}
            pagination={{
              pageSize: tablePageSize,
              showSizeChanger: false,
              total: totalCountRecords || '',
              current: pageNumber
            }}
          />
        </Col>

        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <Button onClick={() => onExportTechnicsList()} loading={loadingExport} type="dashed" icon={<ExportOutlined />}>Esporta tecnici</Button>
        </Col>
      </Row>
    </>
  );
};

export default TechniciansList;
