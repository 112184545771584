import React, { useEffect } from 'react';
import { Layout } from 'antd';
import moment from "moment";
const { Footer } = Layout;

const AppFooter = ({ }) => {

    const currentYear = moment().year();

    useEffect(() => {
    }, []);

    return (
        <Footer style={{ textAlign: 'center' }}>
            © {currentYear} Electrolux Garanzie
        </Footer>
    );
}
export default AppFooter;