import axios from 'axios';
import deployment from '../deploymentConfigs';

const key = `open${Date.now()}`;

const apiUrl = deployment.api;

const axiosInstance = axios.create({
    baseURL: apiUrl
});

axiosInstance.defaults.headers.post['Content-Type'] = 'application/json';


axiosInstance.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    console.log("error", error)    
    if (error.response) {     
        if (error.response.status === 401) {
            window.location.href = '/unauthorized'
        }
    }
    else {
        console.log('other error:', error)
    }
    return Promise.reject(error);
});

export default axiosInstance;
