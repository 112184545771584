
import React from 'react';
import { Layout, Menu, Avatar, Typography } from 'antd';
import logo from "../../../assets/elx.jpeg";
import { Link } from "react-router-dom";
import { LogoutOutlined, UserOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';

const { Header } = Layout;
const { Text } = Typography;

const AppHeader = ({ isAuthenticated, userGraphData }) => {
    const { instance } = useMsal();

    const handelSignOut = () => {
        instance.logoutRedirect()
    }

    const usernameMenu = () => {
        let items = [
            { label: <Text onClick={() => handelSignOut()}> Logout</Text>, key: "logout", icon: <LogoutOutlined /> }
        ];
        return items;
    }

    const menuItems = [
        {
            label: (isAuthenticated ? <Text><Avatar icon={<UserOutlined />} style={{ marginRight: 5 }} />  {userGraphData ? userGraphData.displayName : ""}</Text> : null),
            key: 1,
            children: usernameMenu()
        }
    ]

    return (
        <Header className="header" >
            <div className="logo">
                <Link to={{ pathname: `/` }}><img src={logo} width={"130"} alt="LOGO" /></Link>
            </div>
            <Menu className="alignHeaderItems usernameMenu" mode="horizontal" items={menuItems} />
        </Header>
    );
}

export default AppHeader;