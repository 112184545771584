
import React, { useState, useEffect } from 'react';
import PageHeader from '../../shared/components/pageHeader';

const ConfigurationsIndex = (props) => {



    useEffect(() => {
    }, []);


    return (
        <>
             <PageHeader title="Configurazioni" />
        </>
    );
}
export default ConfigurationsIndex;