import { trackPromise } from 'react-promise-tracker';
import { acquireToken } from '../configs/authentication/MsGraphApiCall';
import axiosInstance from '../configs/axiosConfigs/interceptors';


const ProductServices = {
  
    getProductList: function (filters) {       
        const data = acquireToken().then((tokenResponse) => {   
            const optionsVal = {
                method: 'GET',
                url: `Product/GetProductList`,
                params: filters,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };            
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    }, 
    
    getProductBrands: function (filters) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'GET',
                url: `Brand/GetBrandList`,
                params: filters,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    getProductMarkets: function () {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'GET',
                url: `Market/GetMarketList`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },
    
    getMarketsByBrand: function (brandID) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'GET',
                url: `Product/GetMarketsByBrand`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                },
                params: {
                    brandID: brandID,  
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },
    

};

export default ProductServices;
