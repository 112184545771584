import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { msalInstance } from "../..";
import { graphConfig, loginRequest } from "./msalConfigs";

let appRegScope;

if (process.env.REACT_APP_ENV === "developmentenv" || process.env.REACT_APP_ENV === "development") {
  appRegScope = ["api://efe7a0f7-fa9a-4836-82c4-38e3f8376d12/elx-garanzie-api"];
}

if (process.env.REACT_APP_ENV === "test") {
  appRegScope = ["api://903523da-782d-488a-8719-6aa959488841/elxwarextscope"];
}

if (process.env.REACT_APP_ENV === "production") {
  appRegScope = ["api://a4b7877f-aa05-409a-a486-03f8bd7ce14d/elxwarextscope"];
}


export async function callMsGraphUserData(accessToken) {
  if (!accessToken) {
    const account = msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }

    const response = await msalInstance.acquireTokenSilent({
      ...loginRequest,
      account: account
    });
    accessToken = response.accessToken;
  }

  const headers = new Headers();
  const bearer = `Bearer ${accessToken}`;

  headers.append("Authorization", bearer);

  const options = {
    method: "GET",
    headers: headers
  };

  return fetch(graphConfig.graphMeEndpoint, options)
    .then(response => response.json())
    .catch(error => console.log(error));
}

export const acquireToken2 = async () => {
  const account = msalInstance.getActiveAccount();
  const accessTokenRequest = {
    scopes: ["openid", "profile", "user.read"],
    account: account,
  };

  return await msalInstance.acquireTokenSilent(accessTokenRequest)
    .then(function (accessTokenResponse) {
      // Acquire token silent success
      return accessTokenResponse.accessToken;

    })
    .catch(function (error) {
      //Acquire token silent failure, and send an interactive request
      if (error instanceof InteractionRequiredAuthError) {
        msalInstance
          .acquireTokenPopup(accessTokenRequest)
          .then(function (accessTokenResponse) {
            // Acquire token interactive success
            return accessTokenResponse.accessToken;
          })
          .catch(function (error) {
            // Acquire token interactive failure
            console.log(error);
          });
      }
      console.log(error);
    });


};

export const acquireToken = async () => {

  const account = msalInstance.getActiveAccount();

  const requesObj = {
    loginRequest,
    account: account,
    scopes: appRegScope,
  };

  try {
    return await msalInstance.acquireTokenSilent(requesObj);
  } catch (error) {
    if (error) {
      // fallback to interaction when silent call fails
      return msalInstance.acquireTokenRedirect(requesObj);
    } else {
      return msalInstance.acquireTokenRedirect(requesObj);
    }
  }

};