import React, { useState, useEffect } from 'react';
import { Table, message, Row, Col, Button } from 'antd';
import { notifyMessages } from '../../shared/utils/errorMesages';
import ProductsFilters from './productsFilters';
import ProductServices from '../../services/productServices';
import ProductsDetails from './productsDetails';
import { dateFormat, excelfileExtension, excelfileType, tablePageSize } from '../../shared/utils/constants';
import { EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CheckCircleOutlined, CloseCircleOutlined, ExportOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { manageProductProductionDate } from '../../shared/functions/sharedFunctions';

const ProductsList = () => {

  let filtersInitValues = {
    description: '',
    pnc: '',
    brandID: [],
    marketID: []
  };

  const [productsList, setProductsList] = useState(null);
  const [loadingProductsList, setLoadingProductsList] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterValues, setFilterValues] = useState(filtersInitValues);
  const [totalCountRecords, setTotalCountRecords] = useState(null);
  const [editProductModal, setEditProductModal] = useState(false);
  const [selectedProductData, setSelectedProductData] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [sorterColumn, setSorterColumn] = useState("");
  const [sorterType, setSorterType] = useState(true);


  useEffect(() => {
    getProductsList();
  }, [pageNumber, filterValues, sorterColumn, sorterType]);


  const getProductsList = async (isExport) => {
    let filterObj = { ...filterValues };
    filterObj["pageNo"] = isExport ? 0 : pageNumber;
    filterObj["sortColumn"] = sorterColumn;
    filterObj["ascOrdering"] = sorterType;
    filterObj["elementsNo"] = tablePageSize;

    setLoadingProductsList(isExport ? false : true);
    setLoadingExport(isExport ? true : false);
    await ProductServices.getProductList(filterObj)
      .then(response => response.data)
      .then(resp => {
        if (resp.success) {
          let respData = resp.responseObject.value
          if (isExport) {
            generateExcelFile(respData.productList);
          }
          else {
            let record = respData.productList.find(function (element) {
              return element.recordNo != null;
            });

            setProductsList(respData.productList);
            setTotalCountRecords(record ? record.recordNo : "");
            setLoadingProductsList(false);
          }
        }
        else {
          setLoadingProductsList(false);
          message.error(notifyMessages.errorAccured);
        }
      }).catch(error => {
        setLoadingProductsList(false);
        message.error(notifyMessages.errorAccured);
      });
  }

  const onPageSorterChange = (page, filters, sorter) => {
    if (page) {
      if (sorter && sorter.field) {
          setSorterColumn(sorter.field);
          setPageNumber(page.current);

          if (sorter.order === "descend") {
              setSorterType(false);
          }
          if (sorter.order === "ascend") {
              setSorterType(true);
          }
      }
      else {
          setPageNumber(page.current);
          setSorterColumn("");
          setSorterType(true);
      }
    }
  }

  const filterData = (values) => {
    setFilterValues(values);
    setPageNumber(1);
  };

  const clearFilters = () => {
    setPageNumber(1);
    setFilterValues(filtersInitValues);
  };

  const handelModalClose = () => {
    setEditProductModal(false);
  }

  const handelEditProductModal = (data) => {
    setEditProductModal(true);
    setSelectedProductData(data);
  }

  const productColumns = [
    {
      title: 'PNC',
      dataIndex: 'pnc',
      sorter: (a, b) => {
        a = a.pnc || '';
        b = b.pnc || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],
    },
    {
      title: 'PNC Valido',
      dataIndex: 'validPNC',
      sorter: (a, b) => {
        a = a.validPNC ? 1 : 0;
        b = b.validPNC ? 1 : 0;
        return a - b;
      },
      render: (validPNC) => (
        validPNC ? <CheckCircleOutlined style={{ color: "#4da93d" }} /> : <CloseCircleOutlined style={{ color: "#777876" }}/>
      ),
      showSorterTooltip: [false],     
    },
    {
      title: 'PNC soggetto a garanzia',
      dataIndex: 'pncInWarranty',
      sorter: (a, b) => {
        a = a.pncInWarranty ? 1 : 0;
        b = b.pncInWarranty ? 1 : 0;
        return a - b;
      },
      render: (pncInWarranty) => (
        pncInWarranty ? <CheckCircleOutlined style={{ color: "#4da93d" }} /> : <CloseCircleOutlined style={{ color: "#777876" }}/>
      ),
      showSorterTooltip: [false],     
    },
    {
      title: 'Marchio',
      dataIndex: 'brand',
      sorter: (a, b) => {
        a = a.brand || '';
        b = b.brand || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],
    },
    {
      title: 'Codice Marchio',
      dataIndex: 'marketCode',
      sorter: (a, b) => {
        a = a.marketCode || '';
        b = b.marketCode || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],
    },
    {
      title: 'Descrizione',
      dataIndex: 'description',
      sorter: (a, b) => {
        a = a.description || '';
        b = b.description || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],
    },
    {
      title: 'Settore',
      dataIndex: 'sectorCode',
      sorter: (a, b) => {
        a = a.sectorCode || '';
        b = b.sectorCode || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],
    },
    {
      title: 'Data di inizio produzione',
      dataIndex: 'productionStartDate',
      // render: (text, record) => (text ? moment(text).format(dateFormat) : ""),
      render: (text, record) => (text ? manageProductProductionDate(text) : ""),     
    },
    {
      title: 'Data di fine produzione',
      dataIndex: 'productionEndDate',
      // render: (text, record) => (text ? moment(text).format(dateFormat) : ""),
      render: (text, record) => (text ? manageProductProductionDate(text) : "")     
    },
    {
      title: '',
      dataIndex: 'action',
      render: (text, record) => (
        <EyeOutlined  onClick={() => handelEditProductModal(record)} title="Visualizza" style={{color: "#1677ff" }} />
        
      ),
    },
  ];

  const generateExcelFile = (productsData) => {
    let formatedData = [];

    if (productsData && productsData.length > 0) {

      productsData.map(item => {
        formatedData.push({
          pnc: item.pnc,
          validPNC: item.validPNC,
          pncInWarranty: item.pncInWarranty,
          brand: item.brand,
          marketCode: item.marketCode,
          description: item.description,
          sector: item.sectorCode,
          productionStartDate: manageProductProductionDate(item.productionStartDate),
          productionEndDate: manageProductProductionDate(item.productionEndDate)
        })
      });

      const fileName = `ESPORTAZIONE-PRODOTTI-${moment().format(dateFormat)}`;
      let headers = [['PNC', 'PNC Valido', 'PNC soggetto a garanzia', 'Marchio', 'Codice Marchio', 'Descrizione', 'Settore', 'Data di inzio produzione', 'Data di fine produzione']];
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(ws, formatedData, { origin: 'A2', skipHeader: true })
      XLSX.utils.sheet_add_aoa(ws, headers);
      const wb = { Sheets: { 'Esportazione Prodotti': ws }, SheetNames: ['Esportazione Prodotti'] };

        let wsColsWidthFormatter = [
            { wpx: 120 }, // column width
            { wpx: 100 }, // column width
            { wpx: 100 }, // column width
            { wpx: 100 }, // column width
            { wpx: 100 }, // column width
            { wpx: 120 }, // column width
            { wpx: 120 }, // column width
            { wpx: 100 }, // column width
            { wpx: 130 }, // column width
            { wpx: 130 }, // column width
        ];
        ws['!cols'] = wsColsWidthFormatter;

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: excelfileType });
      FileSaver.saveAs(data, fileName + excelfileExtension);
      setLoadingExport(false);

    }
    else {
      message.warning(notifyMessages.noDataFound);
      setLoadingExport(false);
    }
  }

  const onExportProductsList = () => {
    getProductsList(true);
  }

  return (
    <>
      {(selectedProductData && editProductModal) && (
        <ProductsDetails
          isModalOpen={editProductModal}
          handleCancel={handelModalClose}
          data={selectedProductData}
        />
      )}

      <Row justify="center" gutter={[16, 16]}>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <ProductsFilters
            onFilterData={filterData}
            onResetData={clearFilters}
            filterValues={filterValues}
            filtersInitValues={filtersInitValues}
          />
        </Col>

        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <Table
            size='small'
            columns={productColumns}
            dataSource={productsList}
            rowKey={(obj) => obj.id}
            loading={loadingProductsList}
            onChange={(pagination, filters, sorter) => onPageSorterChange(pagination, filters, sorter)}
            pagination={{
              pageSize: tablePageSize,
              showSizeChanger: false,
              total: totalCountRecords || '',
              current: pageNumber
            }}
          />
        </Col>

        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <Button onClick={() => onExportProductsList()} loading={loadingExport} type="dashed" icon={<ExportOutlined />}>Esporta prodotti</Button>
        </Col>
      </Row>
    </>
  );
};

export default ProductsList;
