import { trackPromise } from 'react-promise-tracker';
import axiosInstance from '../configs/axiosConfigs/interceptors';
import { acquireToken } from '../configs/authentication/MsGraphApiCall';


const ClientsServices = { 

    getClientsList: function (filters) {       
        const data = acquireToken().then((tokenResponse) => {   
            const optionsVal = {
                method: 'GET',
                url: `Customer/GetCustomerList`,
                params: filters,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };            
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },   

    exportClients: function (filters) {       
        const data = acquireToken().then((tokenResponse) => {   
            const optionsVal = {
                method: 'GET',
                url: `Customer/ExportCustomersList`,
                params: filters,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };            
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    }, 

    getClientById: function (clientId) {
        const data = acquireToken().then((tokenResponse) => {
            let queryParams = `?CustomerID=${clientId}`
            const optionsVal = {
                method: 'GET',
                url: `Customer/GetCustomerDetails${queryParams}`,                        
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    }, 

    getContractorsList: function (filters) {       
        const data = acquireToken().then((tokenResponse) => {   
            const optionsVal = {
                method: 'GET',
                url: `Customer/GetContractorsList`,
                params: filters,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };            
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },   

};

export default ClientsServices;
