
import React, { useState, useEffect } from 'react';
import PageHeader from '../../shared/components/pageHeader';
import UsersList from './usersList';
import { Col, Row } from 'antd';
import './users.scss'

const UsersIndex = (props) => {



    useEffect(() => {
    }, []);


    return (
        <div className='usersWrappers'>
            <PageHeader title="Utenti" />

            <Row>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <UsersList />
                </Col>

            </Row>

        </div>
    );
}
export default UsersIndex;