import React, { useContext, useEffect, useRef, useState } from "react";
import { Divider, Table, Input, Row, Col, Button, Card, Form, notification, Typography } from 'antd';
import { tablePageSize, tipoAdesione } from '../../shared/utils/constants';
import ProductServices from '../../services/productServices';
import { v4 as uuid } from 'uuid';
import { WarningOutlined } from "@ant-design/icons";

const { Text } = Typography;
const EditableContext = React.createContext(null);

const EditableRow = ({ index, ...props }) => {
    const [form] = Form.useForm();
    return (
        <Form form={form} component={false}>
            <EditableContext.Provider value={form}>
                <tr {...props} />
            </EditableContext.Provider>
        </Form>
    );
};

const EditableCell = ({
    title,
    editable,
    children,
    dataIndex,
    record,
    handleSaveSerialNumber,
    chackSerialNumberDublicates,
    ...restProps
}) => {
    const [editing, setEditing] = useState(false);
    const inputRef = useRef(null);
    const form = useContext(EditableContext);
    const [padded, setPadded] = useState(false);
    

    useEffect(() => {
        setEditing(true);
    }, [editing]);

    const save = async () => {
        try {
            const values = await form.validateFields();
            const inputValue = form.getFieldValue(dataIndex);
            if (padded) {
                handleSaveSerialNumber({ ...record, ...values, serialNumber: inputValue });
            } else if (inputValue && inputValue.length > 0 && inputValue.length <= 8) {
                const paddedValue = inputValue.padStart(8, '0');                              
                form.setFieldsValue({ [dataIndex]: paddedValue });
                setPadded(true);
                handleSaveSerialNumber({ ...record, ...values, serialNumber: paddedValue });
                setPadded(false);   
                
            } else {
                handleSaveSerialNumber({ ...record, serialNumber: "" });
            }
        } catch (errInfo) {
            handleSaveSerialNumber({ ...record, serialNumber: "" });
        }
    };

    const handleSerialNumberInput = (e) => {
        let input = e.target.value;
        if (input.length > 8) {
            input = input.slice(0, 8);
        }
        e.target.value = input;
    };

    let childNode = children;
    if (editable) {
        childNode = editing ? (
            <Form.Item
                style={{
                    margin: 0
                }}
                name={dataIndex}
                rules={[
                    {
                        required: true,
                        message: `${title} è obbligatorio.`
                    },
                    {
                        message: 'Il numero seriale esiste già',
                        validator:  (_, value) => {
                            if (!chackSerialNumberDublicates(record, value)) {
                                return Promise.resolve();
                            } else {
                                return Promise.reject();
                            }
                        }
                    }
                ]}
            >
                <Input ref={inputRef} onPressEnter={save} onBlur={save} onInput={handleSerialNumberInput} />
            </Form.Item>
        ) : (
            <div
                className="editable-cell-value-wrap"
                style={{
                    paddingRight: 24
                }}

            >
                {children}
            </div>
        );
    }
    return <td {...restProps}>{childNode}</td>;
};



const WarrantyProducts2 = ({ onProductLoad, chackSerialNumberDublicates, selectedTipoAdesione, searchProductsDisabled, selectedMarchio }) => {
    const { Search } = Input;
    const [api, contextHolder] = notification.useNotification();

    const [selectedProductRow, setSelectedProductRow] = useState({});
    const [prodottiSelezionatiData, setProdottiSelezionatiData] = useState([]);
    const [loadingProductsList, setLoadingProductsList] = useState(false);
    const [productsList, setProductsList] = useState(null);
    const [btnDisabled, setBtnDisabled] = useState(true);
    const [selectedProductLength, setSelectedProductLength] = useState(0);

    useEffect(() => {
        setProductsList(null);
        setSelectedProductRow(null);
        setProdottiSelezionatiData([]);
    }, [selectedTipoAdesione]);

    useEffect(() => {
        onProductLoad(prodottiSelezionatiData);
    }, [prodottiSelezionatiData]);

    const onSearch = (value) => {
        getProductsList(value);
    }

    const getProductsList = async (searchValue) => {

        let filterObj = {};
        filterObj["description"] = "";
        filterObj["pnc"] = searchValue;
        filterObj["brandID"] = selectedMarchio;
        filterObj["pageNo"] = 0;
        filterObj["elementsNo"] = tablePageSize;
        filterObj["sortColumn"] = "";
        filterObj["ascOrdering"] = true;
        filterObj["warrantyTab"] = true;
        filterObj["pncInWarranty"] = true;

        setLoadingProductsList(true);
        await ProductServices.getProductList(filterObj)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value;
                    setProductsList(respData.productList);
                    setLoadingProductsList(false);
                    setBtnDisabled(true);
                }
                else {
                    setLoadingProductsList(false);
                }
            }).catch(error => {
                setLoadingProductsList(false);
            });
    }

    const productColumns = [
        {

            title: 'PNC',
            dataIndex: 'pnc',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Marchio',
            dataIndex: 'brand',
            sorter: (a, b) => {
                a = a.companyName || '';
                b = b.companyName || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
            render: (text, record) => {
                return (
                    <Text> {record.brand && record.brandDescription
                        ? `${record.brand} (${record.brandDescription})`
                        : record.brand || record.brandDescription || ''}</Text>
                )
            }
        },
        {
            title: 'Descrizione',
            dataIndex: 'description',
        },
        {
            title: 'Settore',
            dataIndex: 'sectorCode',
        },
    ];

    const selectedProductColumns = [
        ...productColumns,
        {
            title: 'Numero seriale',
            dataIndex: 'serialNumber',
            editable: true,
        },
        {
            title: "",
            dataIndex: "actions",
            render: (_, record) => {
                return (
                    <Button danger onClick={() => handleDelete(record.pid)}>Rimuovi</Button>
                )
            }
        },

    ];

    const handleAddNewRow = (singoloType) => {
        let uniqueProductId = uuid();

        if (selectedProductRow.brand && selectedProductRow.brandDescription) {
            var brandNameDescription = selectedProductRow.brand + " (" + selectedProductRow.brandDescription + ")";
        } else if (selectedProductRow.brand) {
            var brandNameDescription = selectedProductRow.brand;
        } else if (selectedProductRow.brandDescription) {
            var brandNameDescription = "(" + selectedProductRow.brandDescription + ")";
        } else {
            var brandNameDescription = "";
        }

        const newRow = {
            id: selectedProductRow.id,
            pnc: selectedProductRow.pnc,
            brand: brandNameDescription,
            brandID: selectedProductRow.brandID,
            marketCode: selectedProductRow.marketCode,
            description: selectedProductRow.description,
            sectorCode: selectedProductRow.sectorCode,
            serialNumber: "",
            pid: uniqueProductId
        };

        if (singoloType === true) {
            setProdottiSelezionatiData([newRow]);
        } else {
            setProdottiSelezionatiData(prodottiSelezionatiData => [...prodottiSelezionatiData, newRow]);
            setSelectedProductLength(selectedProductLength + 1);
            setProdottiSelezionatiData(updatedData => {
                const selectedProductsCount = updatedData.filter(
                    (product) => product.brandID === selectedMarchio
                ).length;
                if (selectedProductsCount > Math.floor(updatedData.length / 2)) {
                    setSelectedProductLength(selectedProductLength + 1);
                    return updatedData;
                } else {
                    openNotification("Più  della metà dei prodotti selezionati devono avere lo stesso marchio della garanzia");
                    setSelectedProductLength(prodottiSelezionatiData.length);
                    return prodottiSelezionatiData;
                }
            })
        }
    };

    const checkBeforeHandleAddNewRow = () => {
        if (selectedTipoAdesione === tipoAdesione.singolo && selectedProductLength === 1) {
            openNotification("Per l'adesione selezionata, il numero dei prodotti deve essere 1");
        }

        if (selectedTipoAdesione === tipoAdesione.singolo && selectedProductLength < 1) {
            handleAddNewRow(true);
            setSelectedProductLength(selectedProductLength + 1);
        }

        if (selectedTipoAdesione === tipoAdesione.set && selectedProductLength === 10) {
            openNotification("Per l'adesione selezionata, il numero dei prodotti deve essere < 10");
        }

        if (selectedTipoAdesione === tipoAdesione.set && selectedProductLength < 10) {
            handleAddNewRow(false);
        }
    };


    const openNotification = (description) => {
        api.open({
            message: 'Attenzione !',
            description: description,
            icon: <WarningOutlined style={{ color: '#ffcc00' }} />,
        });
    };

    const handleDelete = (key) => {
        const newData = prodottiSelezionatiData.filter((item) => item.pid !== key);
        setProdottiSelezionatiData(newData);
        onProductLoad(newData);
        setSelectedProductLength(selectedProductLength - 1);
    };

    const handelRowSelect = (record, selected, selectedRows) => {
        setSelectedProductRow(selectedRows.length > 0 ? selectedRows[selectedRows.length - 1] : null);
        setBtnDisabled(selectedRows.length === 0);
    };

    const handleSaveSerialNumber = (row) => {
        const newData = [...prodottiSelezionatiData];
        const index = newData.findIndex((item) => row.pid === item.pid);
        const item = newData[index];
        newData.splice(index, 1, {
            ...item,
            ...row
        });
        setProdottiSelezionatiData(newData);
        onProductLoad(newData);

        chackSerialNumberDublicates(row, row.serialNumber)
    };


    const tableComponents = {
        body: {
            row: EditableRow,
            cell: EditableCell,
        },
    };

    const columns = selectedProductColumns.map((col) => {
        if (!col.editable) {
            return col;
        }
        return {
            ...col,
            onCell: (record) => ({
                record,
                editable: col.editable,
                dataIndex: col.dataIndex,
                title: col.title,
                handleSaveSerialNumber,
                chackSerialNumberDublicates,
            })
        };
    });

    return (
        <Card size='small' >


            <Row gutter={{ lg: 24 }}>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                    <Search placeholder="Search PNC" onSearch={(value) => onSearch(value)} disabled={searchProductsDisabled} />
                </Col>
            </Row>

            <Divider orientation="left">Risultati di ricerca</Divider>

            <Row gutter={{ lg: 24 }}>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Table
                        rowSelection={{
                            type: "checkbox",
                            onSelect: handelRowSelect,
                            selectedRowKeys: selectedProductRow ? [selectedProductRow.id] : [],
                        }}
                        columns={productColumns}
                        dataSource={productsList}
                        loading={loadingProductsList}
                        rowKey={(obj) => obj.id}
                        pagination={{
                            pageSize: 5,
                            showSizeChanger: false
                        }}
                        size='small'
                    />
                </Col>
            </Row>

            <Row gutter={{ lg: 24 }}>
                <Col className="gutter-row justifyRight" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    {contextHolder}
                    <Button
                        onClick={checkBeforeHandleAddNewRow}
                        type="primary"
                        style={{
                            marginTop: 20,
                        }}
                        disabled={btnDisabled}
                    >
                        Aggiungi prodotto
                    </Button>
                </Col>
            </Row>

            <Divider orientation="left">Prodotti selezionati</Divider>

            <Row gutter={{ lg: 24 }} style={{ padding: "20px 10px 20px 10px", background: "#e1eddc" }}>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Table
                        components={tableComponents}
                        bordered
                        dataSource={prodottiSelezionatiData}
                        columns={columns}
                        rowKey={"pid"}
                        pagination={false}
                    />
                </Col>
            </Row>

        </Card>
    );
};
export default WarrantyProducts2;