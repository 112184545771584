import React, { useState } from 'react';
import { Row, Col, Typography, Divider, Card, DatePicker, Form, Button, message, Input, Space } from 'antd';
import { ArrowLeftOutlined, CheckCircleOutlined, CloseCircleOutlined, SaveOutlined } from '@ant-design/icons';
import { dateFormat } from '../../../shared/utils/constants';
import { notifyMessages, requiredMessages } from '../../../shared//utils/errorMesages';
import PromotionServices from '../../../services/promotionServices';
import dayjs from 'dayjs';
import { handleDateConvertDayJs } from '../../../shared/functions/sharedFunctions';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isCat } from '../../../configs/authRoleFunctions';

const { Text } = Typography;

const PromotionDetails = ({ selectedPromotion }) => {
    const [form] = Form.useForm();
    const [fieldChanged, setFieldChanged] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const navigate = useNavigate();
    const currentUser = useSelector(state => state.currentUser.loggedinUserData);

    const updatePromotionData = async (values) => {

        const startDateFormatted = handleDateConvertDayJs(values.startDate)
        const endDateFormatted = handleDateConvertDayJs(values.endDate)

        const payload = {
            id: selectedPromotion.id,
            startDate: startDateFormatted,
            endDate: endDateFormatted,
        };

        setLoadingSave(true);
        try {
            const response = await PromotionServices.updatePromotion(payload);

            if (response.data.success) {
                setLoadingSave(false);
                message.success(notifyMessages.updateSuccess);
            } else {
                setLoadingSave(false);
                message.error(notifyMessages.errorAccured);
            }
        } catch (error) {
            setLoadingSave(false);
            message.error(notifyMessages.errorAccured);
        }
    };


    return (
        <div className='promotionDetails'>
            <Form
                form={form}
                name="promotionalDetails"
                layout="horizontal"
                autoComplete="off"
                onValuesChange={() => {
                    setFieldChanged(true)
                }}
                onFinish={updatePromotionData}
            >

                <Row gutter={[16, 16]}>
                    <Col className="gutter-row " style={{ display: 'flex', justifyContent: "space-between" }} xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 24 }}>
                        <Button size='small' icon={<ArrowLeftOutlined />} onClick={() => navigate(-1)}> Indietro </Button>


                        {!isCat(currentUser) && (
                            <Form.Item>
                                <Button type='primary' disabled={!fieldChanged} loading={loadingSave} htmlType='submit' icon={<SaveOutlined />}> Salva </Button>
                            </Form.Item>
                        )}
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <Card size='small'>
                            <Form.Item
                                label="Data Inizio"
                                name="startDate"
                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                initialValue={dayjs(selectedPromotion.startDate)}
                            >
                                <DatePicker format={dateFormat} disabled={isCat(currentUser)} />
                            </Form.Item>
                            <Divider />

                            <Form.Item
                                label="Data Fine"
                                name="endDate"
                                rules={[
                                    { required: true, message: requiredMessages.requiredField },
                                    ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (!value || !getFieldValue('startDate') || value.isAfter(getFieldValue('startDate'))) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('La data di fine deve essere successiva alla data di inizio!'));
                                        },
                                    }),
                                ]}
                                initialValue={dayjs(selectedPromotion.endDate)}
                            >
                                <DatePicker format={dateFormat} disabled={isCat(currentUser)} />
                            </Form.Item>
                            <Divider />

                            <Form.Item label="Azione da Fatturare">
                                <Text strong>{selectedPromotion.billable ? <CheckCircleOutlined style={{ color: "#4da93d" }} /> : <CloseCircleOutlined style={{ color: "#777876" }} />}</Text>
                            </Form.Item>
                            <Divider />

                            <Form.Item label="Importo">
                                <Text strong>{selectedPromotion.totalAmount}</Text>
                            </Form.Item>
                            <Divider />

                            <Form.Item label="Service Material">
                                <Text strong>{selectedPromotion.pnc}</Text>
                            </Form.Item>
                            <Divider />

                            <Form.Item label="Marchio">
                                <Text strong>{selectedPromotion.brandName}</Text>
                            </Form.Item>
                            <Divider />

                            <Form.Item label="Tipo d'installazione">
                                <Text strong>{selectedPromotion.inputType}</Text>
                            </Form.Item>
                        </Card>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }} >
                        <Card size='small' >
                            <Form.Item label="Cliente">
                                <Text strong>{`${selectedPromotion.customerName ? selectedPromotion.customerName : ''} ${selectedPromotion.customerSurname ? selectedPromotion.customerSurname : ''}`}</Text>
                            </Form.Item>
                            <Divider />

                            <Form.Item label="Anno di riferimento">
                                <Text strong>{selectedPromotion.referencePeriod}</Text>
                            </Form.Item>
                            <Divider />

                            <Form.Item label="Codice azione" >
                                <Text strong>{selectedPromotion.actionCode}</Text>
                            </Form.Item>
                            <Divider />

                            <Form.Item label="Codice promozionale" className='codPromo' >
                                <Space direction="vertical" size="middle">
                                    <Space.Compact size="large" className='promoCodeBox'>
                                        <Input
                                            style={{ background: "#e5f4de", width: "80%" }}
                                            disabled
                                            value={selectedPromotion.promotionalCode}
                                        />
                                    </Space.Compact>
                                </Space>
                            </Form.Item>
                            <Divider />

                            <Form.Item label="Stato">
                                <Text strong>{selectedPromotion.active ? <CheckCircleOutlined style={{ color: "#4da93d" }} /> : <CloseCircleOutlined style={{ color: "#f5222d" }} />}</Text>
                            </Form.Item>
                            <Divider />

                            <Form.Item label="Totale Codici progressivi">
                                <Text strong>{selectedPromotion.totalProgressiveCodes}</Text>
                            </Form.Item>
                            <Divider />

                            <Form.Item label="Settore">
                                <Text strong>{selectedPromotion.sectorName}</Text>
                            </Form.Item>
                            
                        </Card>
                    </Col>
                </Row>
            </Form>
        </div>
    );
};

export default PromotionDetails;