import React, { useState, useEffect } from 'react';
import { Table, message, Row, Col, Button } from 'antd';
import { useSelector } from 'react-redux';
import { notifyMessages } from '../../shared/utils/errorMesages';
import ClientsServices from '../../services/clientServices';
import ClientsFilters from './clientsFilters';
import { dateFormat, excelfileExtension, excelfileType, tablePageSize } from '../../shared/utils/constants';
import { EditFilled, ExportOutlined, EyeOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { isAdmin } from '../../configs/authRoleFunctions';
import { isCat } from '../../configs/authRoleFunctions';

const ClientsList = () => {

    let filtersInitValues = {
        name: "",
        surname: "",
        fiscalCode: "",
        region: "",
        email: "",
        enabled: true,
        customerCode: null,
        userID: null,
    }

    const currentUser = useSelector(state => state.currentUser.loggedinUserData);

    const [clientsList, setClientsList] = useState(null);
    const [loadingClientsList, setLoadingClientsList] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [filterValues, setFilterValues] = useState(filtersInitValues);
    const [totalCountRecords, setTotalCountRecords] = useState(null);
    const [sorterColumn, setSorterColumn] = useState("");
    const [sorterType, setSorterType] = useState(true);

    useEffect(() => {
        getClientList(false);
    }, [pageNumber, filterValues, sorterColumn, sorterType]);


    const getClientList = async (isExport) => {
        let filterObj = filterValues;
        filterObj["userID"] = currentUser.id;
        filterObj["pageNo"] = isExport ? 0 : pageNumber;
        filterObj["elementsNo"] = tablePageSize;
        filterObj["sortColumn"] = sorterColumn;
        filterObj["ascOrdering"] = sorterType;
        filterObj["userID"] = currentUser.id;

        setLoadingClientsList(isExport ? false : true);
        setLoadingExport(isExport ? true : false);
        await ClientsServices.getClientsList(filterObj)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value;
                    if (isExport) {
                        generateExcelFile(respData.customerList);
                    }
                    else {
                        let record = respData.customerList.find(function (element) {
                            return element.recordNo != null;
                        });
                        setClientsList(respData.customerList);
                        setTotalCountRecords(record ? record.recordNo : "")
                        setLoadingClientsList(false);
                    }
                }
                else {
                    setLoadingClientsList(false);
                    message.error(notifyMessages.errorAccured);
                }
            }).catch(error => {
                setLoadingClientsList(false);
                message.error(notifyMessages.errorAccured);
            });
    }

    const onPageSorterChange = (page, filters, sorter) => {
        if (page) {
            if (sorter && sorter.field) {
                setSorterColumn(sorter.field);
                setPageNumber(page.current);

                if (sorter.order === "descend") {
                    setSorterType(false);
                }
                if (sorter.order === "ascend") {
                    setSorterType(true);
                }
            }
            else {
                setPageNumber(page.current);
                setSorterColumn("");
                setSorterType(true);
            }
        }
    };

    const filterData = (values) => {
        setFilterValues(values);
        setPageNumber(1);
    }

    const clearFilters = () => {
        setPageNumber(1);
        setFilterValues(filtersInitValues);
    }

    const clientsColumns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            sorter: (a, b) => {
                a = a.name || '';
                b = b.name || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Cognome',
            dataIndex: 'surname',
            sorter: (a, b) => {
                a = a.surname || '';
                b = b.surname || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Codice Fiscale',
            dataIndex: 'fiscalCode',
            sorter: (a, b) => {
                a = a.fiscalCode || '';
                b = b.fiscalCode || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => {
                a = a.email || '';
                b = b.email || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'CAP',
            dataIndex: 'cap',
            sorter: (a, b) => {
                a = a.cap || '';
                b = b.cap || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Regione',
            dataIndex: 'region',
            sorter: (a, b) => {
                a = a.region || '';
                b = b.region || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Citta',
            dataIndex: 'city',
            sorter: (a, b) => {
                a = a.city || '';
                b = b.city || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            key: 'action',
            width: "30px",
            render: (text, record) => (
                <Link to={{ pathname: `/client/id/${record.id}` }}><EyeOutlined /></Link>
            ),
        },

    ];

    const generateExcelFile = (clientsData) => {
        let formatedData = [];

        if (clientsData && clientsData.length > 0) {

            clientsData.map(item => {
                formatedData.push({
                    name: item.name,
                    surname: item.surname,
                    email: item.email,
                    fiscalCode: item.fiscalCode,
                    cap: item.cap,
                    region: item.region,
                    city: item.city,
                    address: item.address
                })
            });

            const fileName = `ESPORTAZIONE-CLIENTI-${moment().format(dateFormat)}`;
            let headers = [['Nome', 'Cognome', 'Email', 'Codice Fiscale', 'Cap', 'Regione', 'Citta', 'Indirizo']];
            const ws = XLSX.utils.json_to_sheet([]);
            XLSX.utils.sheet_add_json(ws, formatedData, { origin: 'A2', skipHeader: true })
            XLSX.utils.sheet_add_aoa(ws, headers);
            const wb = { Sheets: { 'Esportazione Clienti': ws }, SheetNames: ['Esportazione Clienti'] };

            let wsColsWidthFormatter = [
                { wpx: 120 }, // column width
                { wpx: 120 }, // column width
                { wpx: 200 }, // column width
                { wpx: 150 }, // column width
                { wpx: 100 }, // column width
                { wpx: 100 }, // column width
                { wpx: 150 }, // column width
                { wpx: 150 }, // column width
            ];
            ws['!cols'] = wsColsWidthFormatter;

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: excelfileType });
            FileSaver.saveAs(data, fileName + excelfileExtension);
            setLoadingExport(false);

        }
        else {
            message.warning(notifyMessages.noDataFound);
            setLoadingExport(false);
        }
    }

    const onExportClientsList = () => {
        getClientList(true);
    }

    return (
        <>

            <Row justify="center" gutter={[16, 16]}>

                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <ClientsFilters onFilterData={filterData} onResetData={clearFilters} filterValues={filterValues} filtersInitValues={filtersInitValues} />
                </Col>

                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Table
                        size='small'
                        columns={clientsColumns}
                        dataSource={clientsList}
                        rowKey={(obj) => obj.id}
                        loading={loadingClientsList}
                        onChange={(pagination, filters, sorter) => onPageSorterChange(pagination, filters, sorter)}
                        pagination={{
                            pageSize: tablePageSize,
                            showSizeChanger: false,
                            total: totalCountRecords ? totalCountRecords : "",
                            current: pageNumber
                        }}
                    />
                </Col>

                {!isCat(currentUser) && (
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Button onClick={() => onExportClientsList()} loading={loadingExport} type="dashed" icon={<ExportOutlined />}>Esporta clienti</Button>
                    </Col>
                )}

            </Row>

        </>
    );
}
export default ClientsList;