
import React, { useState, useEffect } from 'react';
import PageHeader from '../../shared/components/pageHeader';
import DashboardCharts from './dashboardCharts';
import DashboardStatistics from './dashboardStatistics';
import { Row , Col} from 'antd';
import './dashboard.scss'

const DashboardIndex = (props) => {

    useEffect(() => {
    }, []);


    return (
        <>
            <PageHeader title="Dashboard" />

            <Row gutter={{ lg: 24 }} className="mb15">

                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>

                <DashboardStatistics />

                </Col>

            </Row>

            <Row gutter={{ lg: 24 }} className="mb15">

                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>

                <DashboardCharts />

                </Col>

            </Row>
           
        </>
    );
}
export default DashboardIndex;