
import React, { useState, useEffect } from 'react';
import PageHeader from '../../shared/components/pageHeader';
import { Col, Row } from 'antd';
import ClientsList from './clientsList';
import './clients.scss'

const ClientsIndex = () => {



    useEffect(() => {
    }, []);


    return (
        <div className='clientsWrappers'>
             <PageHeader title="Clienti" />

             <Row>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                   <ClientsList />
                </Col>

            </Row>
        </div>
    );
}
export default ClientsIndex;