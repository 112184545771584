import React, { Component } from 'react';
import './404.scss';
import { Card, Row, Col, Typography, Button } from 'antd';
import notFoundImg from '../../../../assets/notFound.png';
import { RollbackOutlined } from '@ant-design/icons';

const { Paragraph, Title } = Typography;


class NF404 extends Component {

    render() {
        return (
            <div className="notFound">
                <Card>
                    <Row gutter={{ lg: 24 }}>
                        <Col className="gutter-row textNF" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                            <Title level={3}>PAGE NOT FOUND</Title>
                            <Paragraph>
                                Sorry but the page you are looking for does not exist, have been removed, name changed or is temporarily unavailable!
                            </Paragraph>
                            <a href="/"><Button type="dashed" icon={<RollbackOutlined />}>Return to homepage</Button></a>
                        </Col>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                            <img src={notFoundImg} alt="Page not found" />
                        </Col>
                    </Row>
                </Card>
            </div>
        );
    }
}

export default NF404