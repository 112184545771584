import React, { useState, useEffect } from 'react';
import { Table, Col, Row, Button, message, Typography, Tooltip, Space } from 'antd';
import WarrantyServices from '../../services/warrantyServices';
import { dateFormat, excelfileExtension, excelfileType, tablePageSize } from '../../shared/utils/constants';
import { EditFilled, ExportOutlined, InfoCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { statusColorIndicator } from '../../shared/functions/sharedFunctions';
import { useNavigate } from 'react-router';
import { notifyMessages } from '../../shared/utils/errorMesages';
import WarrantyFilters from './warrantyFilters';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { isCat } from '../../configs/authRoleFunctions';
import { useSelector } from 'react-redux';

const { Text } = Typography;

const WarrantyList = () => {

    let filtersInitValues = {
        statusID: null,
        appaltoCode: "",
        companyName: "",
        warrantyTypeID: null,
        contractorID: null,
        contractorName: "",
        beneficentID: null,
        progressiveCodeID: "",
        userID: null,
        beneficentPrivacy: null,
        purchaseStartDate: null,
        purchaseEndDate: null,
        creationStartDate: null,
        creationEndDate: null,
        warrantyStartDate: null,
        warrantyEndDate: null,
        sentC4C: null,
        protocolNumber: "",
        confirmationCode: "",
    }

    const navigate = useNavigate();
    const [warrantyList, setWarrantyList] = useState(null);
    const [loadingWarrantyList, setLoadingWarrantyList] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [filterValues, setFilterValues] = useState(filtersInitValues);
    const [totalCountRecords, setTotalCountRecords] = useState(null);
    const [sorterColumn, setSorterColumn] = useState("");
    const [sorterType, setSorterType] = useState(false);
    const currentUser = useSelector(state => state.currentUser.loggedinUserData);


    useEffect(() => {
        getWarrantyList();
    }, [pageNumber, filterValues, sorterColumn, sorterType]);

    const getWarrantyList = async (isExport) => {
        let filterObj = filterValues;
        filterObj["elementsNo"] = tablePageSize;
        filterObj["pageNo"] = isExport ? null : pageNumber;
        filterObj["sortColumn"] = sorterColumn;
        filterObj["ascOrdering"] = sorterType;
        filterObj["userID"] = currentUser.id;

        if (isCat(currentUser)) {
            filterObj.appaltoCode = currentUser.appaltoCode;
        }

        setLoadingWarrantyList(isExport ? false : true);
        setLoadingExport(isExport ? true : false);

        await WarrantyServices.getWarrantyList(filterObj)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value;

                    if (isExport) {
                        generateExcelFile(respData.warrantyDataList);
                    }
                    else {
                        let record = respData.warrantyDataList.find(function (element) {
                            return element.recordNo != null;
                        });

                        setWarrantyList(respData.warrantyDataList);
                        setWarrantyList(respData.warrantyDataList);
                        setTotalCountRecords(record ? record.recordNo : "")
                        setLoadingWarrantyList(false);
                    }
                }
                else {
                    setLoadingWarrantyList(false);
                    message.error(notifyMessages.errorAccured);
                }
            }).catch(error => {
                setLoadingWarrantyList(false);
                message.error(notifyMessages.errorAccured);
            });
    }


    const onPageSorterChange = (page, filters, sorter) => {
        if (page) {
            if (sorter && sorter.field) {
                setSorterColumn(sorter.field);
                setPageNumber(page.current);

                if (sorter.order === "descend") {
                    setSorterType(false);
                }
                if (sorter.order === "ascend") {
                    setSorterType(true);
                }
            }
            else {
                setPageNumber(page.current);
                setSorterColumn("");
                setSorterType(false);
            }
        }
    };

    const filterData = (values) => {
        setFilterValues(values);
        setPageNumber(1);
    }

    const clearFilters = () => {
        setPageNumber(1);
        setFilterValues(filtersInitValues);
    }

    const warrantyColumns = [
        {
            width: "5px",
            render(text, record) {
                return {
                    props: {
                        className: statusColorIndicator(record.processOrder)
                    }
                };
            }
        },
        {
            title: 'Nr',
            dataIndex: 'number',
            sorter: (a, b) => {
                a = a.number || 0;
                b = b.number || 0;
                return a - b;
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Nr. Protocollo',
            dataIndex: 'protocolNumber',
            sorter: (a, b) => {
                a = a.protocolNumber || '';
                b = b.protocolNumber || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Codice Adesione',
            dataIndex: 'confirmationCode',
            sorter: (a, b) => {
                a = a.confirmationCode || '';
                b = b.confirmationCode || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Tipo Contratto',
            dataIndex: 'warrantyTypeName',
            sorter: (a, b) => {
                a = a.warrantyTypeName || '';
                b = b.warrantyTypeName || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Contraente',
            dataIndex: 'contractorName',
            sorter: (a, b) => {
                a = a.contractorName || '';
                b = b.contractorName || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
            render: (text, record) => {
                if (record.contractorID) {
                    return (
                        <Link to={{ pathname: `/client/id/${record.contractorID}` }}>{record.contractorName} {record.contractorSurname}</Link>
                    )
                }
                else {
                    return (
                        <Text>{record.contractorName} {record.contractorSurname}</Text>
                    )
                }

            }
        },
        {
            title: 'Beneficiario',
            dataIndex: 'beneficentName',
            sorter: (a, b) => {
                a = a.beneficentName || '';
                b = b.beneficentName || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
            render: (text, record) => {
                if (record.beneficentID) {
                    return (
                        <Link to={{ pathname: `/client/id/${record.beneficentID}` }}>{record.beneficentName} {record.beneficentSurname}</Link>
                    )
                }
                else {
                    return (
                        <Text>{record.beneficentName} {record.beneficentSurname}</Text>
                    )
                }

            }
        },
        {
            title: 'Stato Garanzia',
            dataIndex: 'warrantyStatusDescription',
            sorter: (a, b) => {
                a = a.warrantyStatusDescription || '';
                b = b.warrantyStatusDescription || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Codice Promozionale',
            dataIndex: 'progressiveCode',
            sorter: (a, b) => {
                a = a.progressiveCode || '';
                b = b.progressiveCode || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
            render: (text, record) => {
                if (record.azPromoID) {
                    return (
                        <Link to={{ pathname: `/promotion/id/${record.azPromoID}` }}>{record.progressiveCode}</Link>
                    )
                }
                else {
                    return (
                        <Text>{record.progressiveCode}</Text>
                    )
                }

            }
        },
        {
            title: 'Data Acquisto',
            dataIndex: 'productPurchaseDate',
            render: (text, record) => (text ? dayjs(text).format(dateFormat) : ""),
            sorter: (a, b) => {
                a = a.productPurchaseDate || '';
                b = b.productPurchaseDate || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Data Adesione',
            dataIndex: 'warrantyDate',
            render: (text, record) => (text ? dayjs(text).format(dateFormat) : ""),
            sorter: (a, b) => {
                a = a.warrantyDate || '';
                b = b.warrantyDate || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Data di Scadenza',
            dataIndex: 'expirationDate',
            render: (text, record) => (text ? dayjs(text).format(dateFormat) : ""),
            sorter: (a, b) => {
                a = a.expirationDate || '';
                b = b.expirationDate || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            key: 'action',
            width: "30px",
            render: (text, record) => (
                <Link to={{ pathname: `/warranty/id/${record.id}` }}><EditFilled /></Link>
            ),
        },
        {
            key: 'action',
            width: "30px",
            render: (text, record) => (
                <Tooltip placement="top" title={
                    <div>
                      <p><strong>Codice Appalto:</strong> {record.appaltoCode}</p>
                      <p><strong>Ragione Sociale:</strong> {record.companyName}</p>
                      <p><strong>Tipo Adesione:</strong> {record.unionTypeName}</p>
                    </div>
                  }>
                    <InfoCircleOutlined />
                </Tooltip>
            ),
        },
    ];

    const generateExcelFile = (warrantyData) => {
        let formatedData = [];

        if (warrantyData && warrantyData.length > 0) {

            warrantyData.map(item => {
                formatedData.push({
                    number: item.number,
                    protocolNumber: item.protocolNumber,
                    confirmationCode: item.confirmationCode,
                    warrantyTypeName: item.warrantyTypeName,
                    appaltoCode: item.appaltoCode,
                    companyName: item.companyName,
                    contractorName: (item.contractorName || "") + (item.contractorSurname ? ' ' + item.contractorSurname : ""),
                    beneficentName: (item.beneficentName || "") + (item.beneficentSurname ? ' ' + item.beneficentSurname : ""),
                    warrantyStatusDescription: item.warrantyStatusDescription,
                    unionTypeName: item.unionTypeName,
                    ProgressiveCode: item.progressiveCode,
                    purchaseDate: item.productPurchaseDate ? moment(item.productPurchaseDate).format(dateFormat) : "",
                    creationDate: item.warrantyDate ? moment(item.warrantyDate).format(dateFormat) : "",
                    expirationDate: item.expirationDate ? moment(item.expirationDate).format(dateFormat) : ""
                })
            });

            const fileName = `ESPORTAZIONE-GARANZIE-${moment().format(dateFormat)}`;
            let headers = [['Nr', 'No. Protocollo', 'Codice Adesione', 'Tipo Contratto', 'Codice Appalto', 'Ragione Sociale', 'Contraente', 'Beneficiario', 'Stato Garanzia', 'Tipo Adesione', 'Codice Promozionale', 'Data Acquisto', 'Data Adesione', 'Data di Scadenza']];
            const ws = XLSX.utils.json_to_sheet([]);
            XLSX.utils.sheet_add_json(ws, formatedData, { origin: 'A2', skipHeader: true })
            XLSX.utils.sheet_add_aoa(ws, headers);
            const wb = { Sheets: { 'Esportazione Garanzie': ws }, SheetNames: ['Esportazione Garanzie'] };

            let wsColsWidthFormatter = [
                { wpx: 60 }, // column width
                { wpx: 80 }, // column width
                { wpx: 80 }, // column width
                { wpx: 150 }, // column width
                { wpx: 80 }, // column width
                { wpx: 120 }, // column width
                { wpx: 120 }, // column width
                { wpx: 170 }, // column width
                { wpx: 150 }, // column width
                { wpx: 100 }, // column width
                { wpx: 120 }, // column width
                { wpx: 100 }, // column width
                { wpx: 100 }, // column width
                { wpx: 100 }, // column width
            ];
            ws['!cols'] = wsColsWidthFormatter;

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: excelfileType });
            FileSaver.saveAs(data, fileName + excelfileExtension);
            setLoadingExport(false);

        }
        else {
            message.warning(notifyMessages.noDataFound);
            setLoadingExport(false);
        }
    }

    const onExportWarrantyList = () => {
        getWarrantyList(true);
    }

    return (
        <>

            <Row justify="center" align="top" gutter={[16, 16]}>

                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <WarrantyFilters onFilterData={filterData} onResetData={clearFilters} filterValues={filterValues} filtersInitValues={filtersInitValues} />
                </Col>

                <Col className="gutter-row justifyRight" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Button size='small' type='primary' onClick={() => navigate('/warranty/new')}> <PlusOutlined /> Nuova Garanzia </Button>
                </Col>

                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>

                    <Table
                        size='small'
                        columns={warrantyColumns}
                        dataSource={warrantyList}
                        rowKey={(obj) => obj.id}
                        loading={loadingWarrantyList}
                        onChange={(pagination, filters, sorter) => onPageSorterChange(pagination, filters, sorter)}
                        pagination={{
                            pageSize: tablePageSize,
                            showSizeChanger: false,
                            total: totalCountRecords ? totalCountRecords : "",
                            current: pageNumber
                        }}
                    />

                </Col>

                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Button onClick={() => onExportWarrantyList()} loading={loadingExport} type="dashed" icon={<ExportOutlined />}>Esporta garanzie</Button>
                </Col>

            </Row>

        </>
    );
}
export default WarrantyList;