
import React, { useEffect, useState } from 'react';
import { Tabs, message } from 'antd';
import { useParams } from 'react-router';
import PageHeader from '../../../shared/components/pageHeader';
import { notifyMessages } from '../../../shared/utils/errorMesages';
import WarrantyServices from '../../../services/warrantyServices';
import WarrantyDetails from './warrantyDetails';
import SimpleLoader from '../../../shared/components/simpleLoader/simpleLoader';


const Warranty = ({ }) => {

    const urlParams = useParams();
    let warrantyId = urlParams.id;
    const [selectedWarranty, setSelectedWarranty] = useState(null);

    useEffect(() => {
        getWarrantyDetails();
    }, []);

    const getWarrantyDetails = async () => {
        await WarrantyServices.getWarrantyById(warrantyId)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value;
                    setSelectedWarranty(respData.warrantyData);
                }
                else {
                    message.error(notifyMessages.errorAccured);
                }
            }).catch(error => {
                message.error(notifyMessages.errorAccured);
            });
    }


    const tabsItems = [
        {
            key: '1',
            label: "Dettagli",
            children: <WarrantyDetails warrantyId={warrantyId} selectedWarranty={selectedWarranty} />,
        }        
    ];


    return (
        <div className='clientsWrappers'>
            <PageHeader title="Dettagli Garanzia" />
            {!selectedWarranty && (<SimpleLoader />)}

            {selectedWarranty && (
            <WarrantyDetails warrantyId={warrantyId} selectedWarranty={selectedWarranty} />
            )}
        </div>
    );
}
export default Warranty;