import PageHeader from '../../shared/components/pageHeader';
import { Col, Row } from 'antd';
import PromotionsList from './promotionsList';
import './promotions.scss'

const PromotionsIndex = () => {

    return (
        <div className='promotionsWrappers'>
            <PageHeader title="Promozioni" />
            <Row>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <PromotionsList /> 
                </Col>
            </Row>
        </div>
    );
}
export default PromotionsIndex;