import React from 'react';
import { Modal, Row, Col, Button, Typography, Space, Card, Divider } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import { dateFormat } from '../../shared/utils/constants';
import { manageProductProductionDate } from '../../shared/functions/sharedFunctions';

const { Text } = Typography;

const ProductsDetails = ({isModalOpen, handleCancel, data}) => {
    return (
        <>
            <Modal
            title="Dettagli"
            className='productDetails'
                open={isModalOpen}
                width={1000}
                onCancel={handleCancel}
                destroyOnClose={true}
                footer={[
                    <Button key="close" onClick={handleCancel}>Chiudi</Button>
                ]}
            >

                <Card  title={"Prodotto: " + `${data.description}`} size='small'>
                    <Row gutter={{ lg: 24 }}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Space direction='horizontal'>
                                <Text >PNC: </Text>
                                <Text strong>{data.pnc}</Text>
                            </Space>
                            <Divider />

                            <Space direction='horizontal'>
                                <Text >PNC Valid: </Text>
                                <Text strong>{data.validPNC ? <CheckCircleOutlined style={{ color: "#4da93d" }} /> : <CloseCircleOutlined style={{ color: "#777876" }} /> }</Text>
                            </Space>
                            <Divider />

                            <Space direction='horizontal'>
                                <Text >PNC soggetto a garanzia: </Text>
                                <Text strong>{data.pncInWarranty ? <CheckCircleOutlined style={{ color: "#4da93d" }} /> : <CloseCircleOutlined style={{ color: "#777876" }} /> }</Text>
                            </Space>
                            <Divider />

                            <Space direction='horizontal'>
                                <Text >Modello: </Text>
                                <Text strong>{data.model}</Text>
                            </Space>
                            <Divider />

                            <Space direction='horizontal'>
                                <Text >Marchio: </Text>
                                <Text strong>{data.brand}</Text>
                            </Space>
                            <Divider />

                            <Space direction='horizontal'>
                                <Text >Codice Marchio: </Text>
                                <Text strong>{data.marketCode}</Text>
                            </Space>
                            <Divider />

                            <Space direction='horizontal'>
                                <Text >Descrizione: </Text>
                                <Text strong>{data.description}</Text>
                            </Space>
                            <Divider />
                        </Col>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>

                            <Space direction='horizontal'>
                                <Text >Settore: </Text>
                                <Text strong>{data.sectorCode}</Text>
                            </Space>
                            <Divider />

                            <Space direction='horizontal'>
                                <Text >Data di inizio produzione: </Text>
                                {/* <Text strong>{data.productionStartDate ? moment(data.productionStartDate).format(dateFormat) : ""}</Text> */}
                                <Text strong>{data.productionStartDate ? manageProductProductionDate(data.productionStartDate) : ""}</Text>
                            </Space>
                            <Divider />

                            <Space direction='horizontal'>
                                <Text >Data di fine produzione: </Text>
                                {/* <Text strong>{data.productionEndDate ? moment(data.productionEndDate).format(dateFormat) : ""}</Text> */}
                                <Text strong>{data.productionEndDate ? manageProductProductionDate(data.productionEndDate) : ""}</Text>
                            </Space>
                            <Divider />

                            <Space direction='horizontal'>
                                <Text >Prezzo Singolo: </Text>
                                <Text strong>{data.singlePrice}</Text>
                            </Space>
                            <Divider />

                            <Space direction='horizontal'>
                                <Text >Prezzo Fisso: </Text>
                                <Text strong>{data.setPrice}</Text>
                            </Space>
                            <Divider />

                            <Space direction='horizontal'>
                                <Text >Flag BG: </Text>
                                <Text strong>{data.flagBG}</Text>
                            </Space>
                            <Divider />

                            <Space direction='horizontal'>
                                <Text >Subgroup Code: </Text>
                                <Text strong>{data.subgroupCode}</Text>
                            </Space>
                            <Divider />

                        </Col>
                    </Row>
                </Card>
            </Modal>
        </>
    );
};

export default ProductsDetails;