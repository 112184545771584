import { Button, Col, Collapse, Input, Row, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import ProductServices from '../../services/productServices';

const ProductsFilters = ({ onFilterData, onResetData, filterValues, filtersInitValues }) => {

    const [form] = Form.useForm();

    const [brandList, setBrandList] = useState([]);
    const [marketByBrandList, setMarketsByBrandList] = useState([]);

    useEffect(() => {
        getBrandList();
        getMarketsByBrand();
    }, []);

    const getBrandList = async () => {
        await ProductServices.getProductBrands()
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value.brandList;
                    const brandObjects = respData.map((brand) => ({ value: brand.id, label: (brand.code + (brand.description ? " - " + brand.description : "")) }));
                    setBrandList(brandObjects);
                }
                else {
                }
            }).catch(error => {
            });
    }

    const getMarketsByBrand = async (brandID) => {
        form.setFieldsValue({ marketID: null });
        filterValues.marketID = null;
        await ProductServices.getMarketsByBrand(brandID)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value.marketByBrandList;
                    const marketsByBrandObjects = respData.map((marketsByBrand) => ({ value: marketsByBrand.marketID, label: marketsByBrand.marketCode }));
                    setMarketsByBrandList(marketsByBrandObjects);
                }
                else {
                }
            }).catch(error => {
            });
    }

    const onClearFilter = () => {
        form.setFieldsValue(filtersInitValues);
        onResetData();
        getBrandList();
        getMarketsByBrand();
    }

    const onFilter = (values) => {
        onFilterData(values);
    };

    const FilterContent = () => {
        return (
            <div className='productsFilters'>
                <Row gutter={[16, 16]} className='rowmb0'>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Form
                            form={form}
                            name="filters"
                            layout="vertical"
                            onFinish={onFilter}
                            autoComplete="off"
                        >
                            <Row gutter={{ lg: 24 }} className='rowmb0'>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Descrizione"
                                        name="description"
                                        initialValue={filterValues.description}
                                    >
                                        <Input placeholder="Descrizione " />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="PNC"
                                        name="pnc"
                                        initialValue={filterValues.pnc}
                                    >
                                        <Input placeholder="PNC" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Marchio"
                                        name="brandID"
                                        initialValue={filterValues.brandID}
                                    >
                                        <Select placeholder="Marchio" allowClear options={brandList} onChange={(value) => getMarketsByBrand(value)} />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Codice Marchio"
                                        name="marketID"
                                        initialValue={filterValues.marketID}
                                    >
                                        <Select placeholder="Codice Marchio" allowClear options={marketByBrandList} />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row filterButtons" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <Form.Item>
                                        <Button className='mr10' type="primary" htmlType="submit">Filtra</Button>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button onClick={() => onClearFilter()}>Rimuovi</Button>
                                    </Form.Item>

                                </Col>
                            </Row>
                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <>
            <Collapse
                size='small'
                items={[
                    {
                        key: '1',
                        label: 'Filtri',
                        children: <FilterContent />
                    },
                ]}
            />
        </>
    );
}
export default ProductsFilters;