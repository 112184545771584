
const deployment = {
 api: "",
 client: ""
};

if(process.env.REACT_APP_ENV === "development"){
    deployment.api = process.env.REACT_APP_API_URL;
    deployment.client = process.env.REACT_APP_CLIENT_URL;
}

else if(process.env.REACT_APP_ENV === "developmentenv"){
    deployment.api = process.env.REACT_APP_API_URL;
    deployment.client = process.env.REACT_APP_CLIENT_URL;
}

else if(process.env.REACT_APP_ENV === "production"){
    deployment.api = process.env.REACT_APP_API_URL;
    deployment.client = process.env.REACT_APP_CLIENT_URL;
}

else if(process.env.REACT_APP_ENV === "test"){
    deployment.api = process.env.REACT_APP_API_URL;
    deployment.client = process.env.REACT_APP_CLIENT_URL;
}

else {
    deployment.api = 'https://localhost:44390/api/';
    deployment.client = 'http://localhost:3000';
}


export default deployment;