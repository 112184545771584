export const excelfileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
export const excelfileExtension = '.xlsx';

export const tablePageSize = 15;

export const dateFormat = 'DD/MM/YYYY';

export const paymentTypeValues = [
    { value: 'G (gratuita)', label: 'G (Gratuita)' },
    { value: 'P (Pagamento)', label: 'P (Pagamento)' },
    { value: 'M (Promozionale)', label: 'M (Promozionale)' },
];

export const paymentTypesConsts = {
  promozionale: "M (Promozionale)",
  gratuita: "G (gratuita)",
  pagamento: "P (Pagamento)"
}

export const warrantyDuration = [
    { value: 1, label: '1' },
    { value: 2, label: '2'},
    { value: 3, label: '3' },
];

export const chartsColorPalete = ["#6abf6a", "#0e72a6", "#ec1c6d", "#ffd81a", "#19a3c3", "#a05ad5", "#d79139", "#db3a9b", "#84aabd", "#73cf98", "#bae6ff", "#d4bbff"]

export const chartDownloadOption = {
    enabled: true,
    menuItemDefinitions: {
      // Custom definition
      viewFullscreen: {text: "Fullscreen"},
      downloadJPEG: {text: "Download JPG"},
      downloadPDF: {text: "Download PDF"},

    },
    buttons: {
      contextButton: {
        menuItems: ["viewFullscreen", "downloadJPEG", "downloadPDF", { text: "Download XLSX", onclick() { this.downloadXLS(); }
          }
        ]
      }
    }
  };

  export const tipoContratto = {
    singolo: "Singolo prodotto",
    promozionale: "Promozionale",
    setAeg: "SET/AEG"
  }

  export const tipoAdesione = {
    set: "Set",
    singolo: "Singolo"
  }

  export const roleCode = {
    admin: "Admin",
    cat: "Cat",
    km: "Km",
    it: "It",
    user: "User",
    sales: "Sales"
  }

  export const marchioCode = {
    zanussi: "Z (ZANUSSI)",
    aeg: "A (AEG)",
    zoppas: "P (ZOPPAS)",
    eElectrolux: "E (ELECTROLUX)",
    rex: "R (REX)",
    xElectrolux: "X (ELECTROLUX)"
  }

  export const installationTypes = [
    {value: "FS", label: "FS"},
    {value: "BI", label: "BI"},
  ]