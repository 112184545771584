import React, { useState, useEffect } from 'react';
import './unauthorized.scss';
import { Card, Row, Col, Typography, Button } from 'antd';
import notFoundImg from '../../../../assets/notFound.png';
import { LogoutOutlined } from '@ant-design/icons';
import { useMsal } from '@azure/msal-react';

const { Paragraph, Title } = Typography;

const Unauthorized = (props) => {

    const { instance } = useMsal();

    const handelSignOut = () => {
        instance.logoutRedirect()
    }

    useEffect(() => {
        // let timeleft = 15;
        // let downloadTimer = setInterval(function () {
        //     if (timeleft <= 0) {
        //         clearInterval(downloadTimer);
        //         handelSignOut();
        //     }
        //     document.getElementById("timer").innerHTML = "Verrai disconnesso automaticamente in... " + timeleft + " secondi";
        //     timeleft -= 1;
        // }, 1000);
    }, []);


    return (
        <div className="notAuth">

            <Card>
                <Row gutter={{ lg: 24 }}>
                    <Col className="gutter-row textNF" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                        <Title level={3}>NON AUTORIZZATO</Title>
                        <Paragraph>
                            Non sei autorizzato ad accedere alla pagina che stai cercando.
                        </Paragraph>
                        {/* <Paragraph id="timer"></Paragraph> */}
                        <Button onClick={() => handelSignOut()} type="dashed" icon={<LogoutOutlined />}>Sign Out</Button>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 12 }}>
                        <img src={notFoundImg} alt="Page not found" />
                    </Col>
                </Row>
            </Card>
        </div>
    );
}
export default Unauthorized;