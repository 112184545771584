import { Button, Col, Collapse, Input, Row, Form, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { isCat } from '../../configs/authRoleFunctions';

const TechniciansFilters = ({ onFilterData, onResetData, filterValues, filtersInitValues }) => {

    const [form] = Form.useForm();
    const currentUser = useSelector(state => state.currentUser.loggedinUserData);

    const onClearFilter = () => {
        form.setFieldsValue(filtersInitValues);
        onResetData();
    }

    const onFilter = (values) => {
        onFilterData(values);
    };

    const FilterContent = () => {
        return (
            <div className='techniciansFilters'>
                <Row gutter={[16, 16]} className='rowmb0'>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>

                        <Form
                            form={form}
                            name="filters"
                            layout="vertical"
                            onFinish={onFilter}
                            autoComplete="off"
                        >

                            <Row gutter={{ lg: 24 }} className='rowmb0'>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Nome"
                                        name="name"
                                        initialValue={filterValues.name}
                                    >
                                        <Input placeholder="Nome" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Cognome"
                                        name="surname"
                                        initialValue={filterValues.surname}
                                    >
                                        <Input placeholder="Cognome" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Codice"
                                        name="code"
                                        initialValue={filterValues.code}
                                    >
                                        <Input placeholder="Codice" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Codice Appalto"
                                        name="appaltoCode"
                                        initialValue={isCat(currentUser) ? currentUser.appaltoCode : null}
                                    >
                                        <Input placeholder="Codice Appalto" disabled={isCat(currentUser)}/>
                                    </Form.Item>
                                </Col>



                                <Col className="gutter-row filterButtons" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <Form.Item>
                                        <Button className='mr10' type="primary" htmlType="submit">Filtra</Button>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button onClick={() => onClearFilter()}>Rimuovi</Button>
                                    </Form.Item>

                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <>
            <Collapse
                size='small'
                items={[
                    {
                        key: '1',
                        label: 'Filtri',
                        children: <FilterContent />
                    },
                ]}
            />
        </>
    );
}
export default TechniciansFilters;