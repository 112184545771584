import { Empty, Card, Row, Col, Button, Divider, Space, Tooltip, DatePicker } from 'antd';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { FilterOutlined, MinusSquareOutlined } from '@ant-design/icons';
import { chartDownloadOption, chartsColorPalete, dateFormat } from '../../shared/utils/constants';
import SimpleLoader from '../../shared/components/simpleLoader/simpleLoader';
import DashboardServices from '../../services/dashboardServices';
import { useSelector } from 'react-redux';
import { handleDateConvertDayJs } from '../../shared/functions/sharedFunctions';

const { RangePicker } = DatePicker;

const warrantyStatusChartConfigs = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>'
    },
    credits: {
        enabled: false
    },
    exporting: chartDownloadOption,
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            colors: chartsColorPalete,
            dataLabels: {
                enabled: true,
                format: '{point.percentage:.1f} %',
            },
            showInLegend: true
        }
    },
    series: [{
        name: "",
        data: []
    }]
}

const promotionStatusChartConfigs = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie'
    },
    title: {
        text: ''
    },
    tooltip: {
        pointFormat: '<b>{point.percentage:.1f}%</b>'
    },
    credits: {
        enabled: false
    },
    exporting: chartDownloadOption,
    plotOptions: {
        pie: {
            allowPointSelect: true,
            cursor: 'pointer',
            colors: chartsColorPalete,
            dataLabels: {
                enabled: true,
                format: '{point.percentage:.1f} %',
            },
            showInLegend: true
        }
    },
    series: [{
        name: "",
        data: []
    }]
}

const DashboardCharts = () => {

    const currentUser = useSelector(state => state.currentUser.loggedinUserData);

    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);

    const [warrantyStatusChartData, setWarrantyStatusChartData] = useState(warrantyStatusChartConfigs);
    const [warrantyStatusChartHasData, setWarrantyStatusChartHasData] = useState(null);
    const [loadingWarrantyStatusChartData, setLoadingWarrantyStatusChartData] = useState(false);
    
    const [promotionStatusChartData, setPromotionStatusChartData] = useState(promotionStatusChartConfigs);
    const [promotionStatusChartHasData, setPromotionStatusChartHasData] = useState(null);
    const [loadingPromotionStatusChartData, setLoadingPromotionStatusChartData] = useState(false);

    useEffect(() => {
        getWarrantyStatusChartData();
        getPromotionStatusChartData();
    }, []);

    const getWarrantyStatusChartData = async (startDate, endDate) => {
        setLoadingWarrantyStatusChartData(true);
        await DashboardServices.getDashboardWarrantyStatus(startDate, endDate, currentUser.id)
        .then(response => response.data)
        .then(resp => {
            if (resp.success) {              
              let respData = resp.responseObject.value;
              setWarrantyStatusChartHasData(respData.statusList); 
              setLoadingWarrantyStatusChartData(false);

              const seriesData = respData.statusList.map((item) => ({
                name: item.statusName,
                y: item.totalWarranties,
            }));

            setWarrantyStatusChartData({
                ...warrantyStatusChartData,
                series: [{ name: "", data: seriesData }],
            });
        } else {
            setWarrantyStatusChartHasData(null)
            setLoadingWarrantyStatusChartData(false)
        }
        }).catch(error => {
          setLoadingWarrantyStatusChartData(false)
        });
    };
    

    const getPromotionStatusChartData = async (startDate, endDate) => {
        setLoadingPromotionStatusChartData(true);
        await DashboardServices.getDashboardPromotionStatus(startDate, endDate, currentUser.id)
        .then(response => response.data)
        .then(resp => {
            if (resp.success) {              
              let respData = resp.responseObject.value;
              setPromotionStatusChartHasData({
                usedProgressiveCodes: respData.usedProgressiveCodes,
                freeProgressiveCodes: respData.freeProgressiveCodes
              }); 
              setLoadingPromotionStatusChartData(false);

              const seriesData = [
                { name: "Codici progressivi usati", y: respData.usedProgressiveCodes },
                { name: "Codici progressivi disponibili", y: respData.freeProgressiveCodes }
              ];
    
              setPromotionStatusChartData({
                ...promotionStatusChartData,
                series: [{ name: "", data: seriesData }],
              });
            } else {
                setPromotionStatusChartHasData(null);
                setLoadingPromotionStatusChartData(false)
            }
        }).catch(error => {
            setLoadingPromotionStatusChartData(false)
        });
    };
    
    
    const handleFilter = () => {
        if (startDate && endDate) {
            getWarrantyStatusChartData(startDate, endDate);
            getPromotionStatusChartData(startDate, endDate);
        } else {
            console.error("Select both dates.");
        }
    };

    const onClearFilter = () => {
        setStartDate(null);
        setEndDate(null);
        
        getWarrantyStatusChartData();
        getPromotionStatusChartData();
    };


    return (
        <>
            <Card className='mt10' size='small' title="DASHBOARD STATO GARANZIE & DISPONIBILITA CODICI PROGRESSIVI" >
                <Row gutter={{ lg: 24 }} className="mb15 payment-filters">
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Row gutter={{ lg: 24 }} className="row-margin">
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Space direction='horizontal'>
                                    <RangePicker
                                        size='small'
                                        format={dateFormat}
                                        allowClear='true'
                                        onChange={(dates) => {
                                            if (dates && dates.length === 2) {
                                                let startdate = handleDateConvertDayJs(dates[0].$d);
                                                let endDate = handleDateConvertDayJs(dates[1].$d);
                                                setStartDate(startdate);
                                                setEndDate(endDate);     
                                            } else {
                                                setStartDate(null);
                                                setEndDate(null);
                                            }
                                        }}
                                    />
                                    <Tooltip title="Filtra">
                                        <Button className="ml10" size="small" type="dashed" icon={<FilterOutlined />} onClick={handleFilter} />
                                    </Tooltip>
                                    <Tooltip title="Cancella filtri">
                                        <Button size="small" type="dashed" icon={<MinusSquareOutlined />} onClick={onClearFilter}/> 
                                    </Tooltip>
                                </Space>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Divider />

                <Row gutter={{ lg: 24 }} className="mt10">
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <Card size='small' title="STATO GARANZIE" >

                            {loadingWarrantyStatusChartData && <SimpleLoader />}
                            
                            {(warrantyStatusChartHasData) &&
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={warrantyStatusChartData}
                                />
                            }
                            {(!warrantyStatusChartHasData && !loadingWarrantyStatusChartData) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </Card>
                    </Col>

                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 12 }} lg={{ span: 12 }}>
                        <Card size='small' title="DISPONIBILITA CODICI PROGRESSIVI" >

                            {loadingPromotionStatusChartData && <SimpleLoader />}
                            
                            {(promotionStatusChartHasData) &&
                            
                                <HighchartsReact
                                    highcharts={Highcharts}
                                    options={promotionStatusChartData}
                                />
                            }
                            {(!promotionStatusChartHasData && !loadingPromotionStatusChartData) && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
                        </Card>
                    </Col>
                </Row>
            </Card>
        </>
    );
}

export default DashboardCharts;

