import { createSlice } from '@reduxjs/toolkit'
import GeneralServices from '../../services/generalServices';

const initialState = { 
    loggedinUserData: null    
};


const currentUserSlice = createSlice({
    name: 'currentUser',
    initialState,
    reducers: {
      currentUserLoaded(state, action) {  
        state.loggedinUserData =  action.payload;   
      }    
    },
  })

  export const {currentUserLoaded } = currentUserSlice.actions
  
  export default currentUserSlice.reducer;

  export const fetchCurrentUser = () => async (dispatch) => {    
        await GeneralServices.getCurrentUserData()
      .then(response => response.data)
      .then(resp => {   
        if (resp.success) {                    
          const userData = resp.responseObject.value.userDetails;     
          dispatch(currentUserLoaded(userData));            
        }
        else {}

      }).catch(error => { });
  }

