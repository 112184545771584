import { configureStore } from "@reduxjs/toolkit";
import currentUserReducer from "./slices/currentUserSlice";
import rolesListReducer from './slices/rolesSlice';
import tecnicListReducer from './slices/tecnicsSlice';
import warrantyTypeListReducer from './slices/warrantyTypeSlice';
import warrantyStatusListReducer from './slices/warrantyStatusSlice';
import unionTypeReducer from './slices/unionTypeSlice';
import PromotionReducer from './slices/PromotionSlice';
import paymentTypeListReducer from './slices/paymentTypeSlice';

const store = configureStore({
  reducer : {
      currentUser: currentUserReducer,
      rolesList: rolesListReducer,
      tecnicList: tecnicListReducer,
      warrantyTypeList: warrantyTypeListReducer,
      warrantyStatusList: warrantyStatusListReducer,
      unionTypeList: unionTypeReducer,
      promotionList: PromotionReducer,
      paymentTypeList: paymentTypeListReducer
  }
})

export default store;