
import React, { useState, useEffect } from 'react';
import PageHeader from '../../shared/components/pageHeader';
import { Row, Col } from 'antd';
import WarrantyList from './warrantyList';
import './warranty.scss'

const WarrantyIndex = () => {



    useEffect(() => {
    }, []);


    return (
        <div className='warrantyWrappers'>
             <PageHeader title="Garanzie" />

             <Row>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                   <WarrantyList />
                </Col>

            </Row>
            </div>
    );
}
export default WarrantyIndex;