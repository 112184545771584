import React from 'react';
import { Spin } from 'antd';
import "./loader2.scss"
import { LoadingOutlined } from '@ant-design/icons';

export const SimpleLoader = (props) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

  return (
      <div className="spinner2" align="center">
        <Spin indicator={antIcon} />
        <p>Loading...</p>
      </div>
    
  );
};


  export default SimpleLoader