import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Select, Button, Divider, DatePicker, Space, Tooltip, Typography, notification, InputNumber, message, Switch } from 'antd';
import { notifyMessages, requiredMessages } from '../../shared/utils/errorMesages';
import { useNavigate } from 'react-router-dom';
import PromotionServices from '../../services/promotionServices';
import { ArrowLeftOutlined, InfoCircleOutlined, SaveOutlined } from '@ant-design/icons';
import PageHeader from '../../shared/components/pageHeader';
import { dateFormat, installationTypes, tablePageSize, tipoAdesione } from '../../shared/utils/constants';
import ProductServices from '../../services/productServices'
import { useSelector } from 'react-redux';
import ClientsServices from '../../services/clientServices';
import dayjs from 'dayjs';
import { handleDateConvertDayJs } from '../../shared/functions/sharedFunctions';
import SectorServices from '../../services/sectorServices';
const { Title } = Typography;

const NewPromotion = () => {

    const navigate = useNavigate();
    const [api, contextHolder] = notification.useNotification();


    const openNotification = () => {
        {
            api.warning({
                message: `Base Code è obbligatorio `,
                description: 'Compilare tutti i campi richiesti',
                placement: "topRight",
            })
        }
    };

    const [newPromotionForm] = Form.useForm();
    const [fieldChanged, setFieldChanged] = useState(false);
    const [requiredImportoTotale, setRequiredImportoTotale] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [productList, setProductList] = useState([]);
    const [sectorsList, setSectorsList] = useState([]);
    const [brandList, setBrandList] = useState([]);
    const unionTypeList = useSelector(state => state.unionTypeList.unionTypeList);
    const [clientsList, setClientsList] = useState(null);
    const [loadingClientsList, setLoadingClientsList] = useState(false);

    const [unionTypeBoxValue, setUnionTypeBoxValue] = useState(null);
    const [warrantyTypeBoxValue, setWarrantyTypeBoxValue] = useState("M");
    const [brandBoxValue, setBrandBoxValue] = useState(null);
    const [userInputBoxValue, setUserInputBoxValue] = useState(null);
    const [yearBoxValue, setYearBoxValue] = useState();

    const [fixedBrandId, setFixedBrandId] = useState(null);
    const [fixesdSectorId, setFixesdSectorId] = useState(null);

    const [lastActionCode, setLastActionCode] = useState(null); 
    const [fullYear, setFullYear] = useState(null);
    const [actionCodeDisabled, setActionCodeDisabled] = useState(true);

    const [disableBrand, setDisableBrand] = useState(true);

    const unionTypePicklist = unionTypeList.reduce((res, item) => {
        if (item.type === tipoAdesione.set) {
            res.push({ value: item.id, label: item.type });
        }
        return res;
    }, []);

    useEffect(() => { 
        if(fixesdSectorId){
            getProductList();       
        }

    }, [fixesdSectorId])

    useEffect(() => { 
        getSectorsList();    
        getProductBrands();       
        getContractors();        
    }, []);

    const getProductBrands = async () => {
        let filterObj = {}
        filterObj["usedInWarranty"] = true;
        await ProductServices.getProductBrands(filterObj)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value.brandList;
                    const brandObjects = respData.map((brand) => ({ value: brand.id, label: (brand.code + (brand.description ? " - " + brand.description : "")) }));
                    setBrandList(brandObjects);
                }
                else {
                }
            }).catch(error => {
            });
    };
    

    const getProductList = async () => {
        let filterObj = {
            brandID: fixedBrandId,
            sectorID: fixesdSectorId
        };

        filterObj["pageNo"] = 0;
        filterObj["sortColumn"] = "";
        filterObj["ascOrdering"] = true;
        filterObj["elementsNo"] = tablePageSize;

        await ProductServices.getProductList(filterObj)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value.productList;
                    const productObjects = respData.map((product) => ({ value: product.id, label: product.pnc }));
                    setProductList(productObjects);
                }
                else {
                }
            }).catch(error => {
            });
    }

    const getSectorsList = async () => {
        let filterObj = { sectorID: "", description: "" };

        await SectorServices.getSectorsList(filterObj)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value.sectors;

                    let fixedSector = respData.filter(obj => {
                        return obj.sectorCode === "98";
                    });

                    if (fixedSector && fixedSector.length > 0) {
                        setFixesdSectorId(fixedSector[0].id)
                    }

                    const sectorsObj = respData.map((sector) => ({ value: sector.id, label: (sector.description + (sector.sectorCode ? " (" + sector.sectorCode + ")" : "")) }));
                    setSectorsList(sectorsObj);
                }
                else {
                }
            }).catch(error => {
            });
    }

    const getContractors = async () => {
        let filterObj = { name: "", surname: "", fiscalCode: "", customerCode: null };

        setLoadingClientsList(true);
        await ClientsServices.getContractorsList(filterObj)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value.contractorList;
                    const clientsPiclist = respData.map((item) => ({
                        value: item.id,
                        label: (
                            item.contractorName || ""
                            + " "
                            + item.contractorSurname || ""
                            + (item.contractorFiscalCode ? " - " + (item.contractorFiscalCode) : "")
                        )
                    }));

                    setClientsList(clientsPiclist);
                    setLoadingClientsList(false);
                }
                else {
                    setLoadingClientsList(false);
                }
            }).catch(error => {
                setLoadingClientsList(false);
            });
    }

    const getLastActionCode = async (year) => {
        await PromotionServices.getLastActionCode(year)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    const respData = resp.responseObject.value; 

                    setUserInputBoxValue(respData.lastActionCode);
                    newPromotionForm.setFieldsValue({
                        actionCode: respData.lastActionCode
                    });
                } else {
                    message.error(notifyMessages.errorAccured);
                }
            })
            .catch(error => {
                message.error(notifyMessages.errorAccured);
            });
    };
    
    const checkActionCodeExist = async (actionCode) => {
        await PromotionServices.verifyActionCode(actionCode, fullYear)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    const respData = resp.responseObject.value; 
                    
                    if(respData.isFree){
                        setUserInputBoxValue(actionCode);
                    }
                    else{
                        message.error("Questo codice azione non è disponibile");
                    }

    
                } else {
                    message.error(notifyMessages.errorAccured);
                }
            })
            .catch(error => {
                message.error(notifyMessages.errorAccured);
            });
    };     

    const checkPromotionCodeValidity = () => {
        if (unionTypeBoxValue && warrantyTypeBoxValue && brandBoxValue && userInputBoxValue && yearBoxValue) {
            return true;
        }
        else {
            return false;
        }
    }

    const generatePromotionCode = () => {
        let promotionCode = `${unionTypeBoxValue}${warrantyTypeBoxValue}${brandBoxValue}${userInputBoxValue}${yearBoxValue}`;
        return promotionCode.replace(/\s/g, '');
    }

    const createPromotionData = async (values) => {

        if (checkPromotionCodeValidity()) {
            values["promotionalCode"] = generatePromotionCode();
            values.startDate = handleDateConvertDayJs(values.startDate);
            values.endDate = handleDateConvertDayJs(values.endDate);
            const refPeriod = dayjs(values.referencePeriod).format("YYYY");
            values.referencePeriod = parseInt(refPeriod);

            setLoadingSave(true);
            await PromotionServices.createPromotion(values)
                .then(response => response.data)
                .then(resp => {
                    if (resp.success) {
                        setLoadingSave(false);
                        message.success(notifyMessages.addSuccess);
                        navigate("/promotions")
                    }
                    else {
                        setLoadingSave(false);
                        message.error(notifyMessages.errorAccured);
                    }
                }).catch(error => {
                    setLoadingSave(false);
                    message.error(notifyMessages.errorAccured);
                });
        }
        else {
            openNotification()
        }

    }

    const onUnionTypeSelect = (value, option) => {
        if (option && option.label) {

            if (option.label === tipoAdesione.singolo) {
                setUnionTypeBoxValue("S");
            }
            if (option.label === tipoAdesione.set) {
                setUnionTypeBoxValue("T");
            }
        }

    }

    const onBrandSelect = (value, option) => {
        if (option && option.label) {
            const firstCharacterBrand = option.label.charAt(0);
            setBrandBoxValue(firstCharacterBrand);
        }
    }

    const onInstallationTypeSelect = (value, option) => {
        if (option) {
            setDisableBrand(false);
        }
    }

    const onCodiceAzioneChange = (e) => { 
        let inputValue = e.target.value;
        if(inputValue){
        checkActionCodeExist(inputValue)
        }
    }

    const onYearChange = async (value) => {
        if (value) {
            let selectedYear = dayjs(value.$d).format("YY");
            setYearBoxValue(selectedYear);
    
            let fullYear = dayjs(value.$d).format("YYYY");
            setFullYear(fullYear);

            setActionCodeDisabled(false);    
            await getLastActionCode(fullYear);
        }
    };

    const onPasteEvent = e => {
        if (!/[0-9]/.test(e.key)) {
            e.preventDefault();
        }
    };

    const onBillableChecked = (value) => {
        setRequiredImportoTotale(value);
    }

    return (
        <>
            {contextHolder}

            <PageHeader title="Crea nuova promozione" />

            <Row gutter={{ lg: 24 }}>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Form
                        id='newPromotion'
                        form={newPromotionForm}
                        layout="vertical"
                        onFinish={createPromotionData}
                        autoComplete="off"
                        onValuesChange={() => {
                            setFieldChanged(true)
                        }}
                    >

                        <Row gutter={{ lg: 24 }}>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Anno di riferimento"
                                    name="referencePeriod"
                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                >
                                    <DatePicker style={{ width: "100%" }} placeholder='Anno di riferimento' allowClear={false} format={"YYYY"} onChange={(value) => onYearChange(value)} picker="year" />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Codice azione"
                                    name="actionCode"
                                    rules={[
                                        { required: true, message: requiredMessages.requiredField },
                                        //  { min: 2, type: "number", message: 'Codice azione deve contenere almeno 2 caratteri' },
                                         {
                                            message: 'Codice azione deve contenere almeno 2 caratteri',
                                            validator: (_, value) => {
                                              if (value.toString().length < 2) {
                                                return Promise.reject('Codice azione deve contenere almeno 2 caratteri');                                               
                                              } else {
                                                return Promise.resolve();
                                              }
                                             }
                                           },
                                           {
                                            pattern: /^[0-9]*$/,
                                            message: "Solo numeri",
                                          }
                                           
                                    ]}
                                >
                                    <Input
                                        style={{width:"100%"}}                                       
                                        placeholder="Codice azione"
                                        onChange={(e) => onCodiceAzioneChange(e)}
                                        // onKeyDown={handleKeyDown}
                                        // onKeyUp={handleKeyDown}
                                        disabled={actionCodeDisabled}
                                        onPaste={onPasteEvent}
                                        maxLength={2}
                                        min={0}
                                        max={99}
                                    />
                                </Form.Item>
                            </Col>


                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Tipo Adesione"
                                    name="unionTypeID"
                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                >
                                    <Select
                                        placeholder="Tipo Adesione"
                                        allowClear={false}
                                        options={unionTypePicklist}
                                        onSelect={(value, option) => onUnionTypeSelect(value, option)}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <Form.Item
                                    label="Tipo d'installazione"
                                    name="inputType"
                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                >
                                    <Select placeholder="Tipo d'installazione" allowClear={false} options={installationTypes} onSelect={(value, option) => onInstallationTypeSelect(value, option)} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <Form.Item
                                    label="Marchio"
                                    name="brandID"
                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                >
                                    <Select placeholder="Marchio" disabled={disableBrand} allowClear={false} options={brandList} onSelect={(value, option) => onBrandSelect(value, option)} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <Form.Item
                                    label="Data Inizio"
                                    name="startDate"
                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                >
                                    <DatePicker style={{ width: "100%" }} format={dateFormat} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                <Form.Item
                                    label="Data Fine"
                                    name="endDate"
                                    rules={[
                                        { required: true, message: requiredMessages.requiredField },
                                        ({ getFieldValue }) => ({
                                            validator(_, value) {
                                                if (!value || !getFieldValue('startDate') || value.isAfter(getFieldValue('startDate'))) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject(new Error('La data di fine deve essere successiva alla data di inizio!'));
                                            },
                                        }),
                                    ]}
                                >
                                    <DatePicker style={{ width: "100%" }} format={dateFormat} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Cliente"
                                    name="customerId"
                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                >
                                    <Select
                                        placeholder="Cliente"
                                        allowClear
                                        loading={loadingClientsList}
                                        options={clientsList}
                                        showSearch
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <Form.Item
                                    label="Azione da Fatturare"
                                    name="billable"
                                    valuePropName="checked"
                                    initialValue={false}
                                >
                                    <Switch checkedChildren="Si" unCheckedChildren="No" onChange={(checked) => onBillableChecked(checked)} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <Form.Item
                                    label="Service Material"
                                    name="productID"

                                >
                                    <Select
                                        showSearch
                                        placeholder="Service Material"
                                        allowClear
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {productList.map((product) => (
                                            <Select.Option key={product.value} value={product.value} label={product.label}>
                                                {product.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                <Form.Item
                                    label="Settore"
                                    name="sectorID"
                                >
                                    <Select
                                        showSearch
                                        allowClear
                                        placeholder="Settore"
                                        optionFilterProp="label"
                                        filterOption={(input, option) =>
                                            option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0
                                        }
                                    >
                                        {sectorsList.map((product) => (
                                            <Select.Option key={product.value} value={product.value} label={product.label}>
                                                {product.label}
                                            </Select.Option>
                                        ))}
                                    </Select>
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Form.Item
                                    label="Importo totale"
                                    name="totalAmount"
                                    tooltip="Min: 0.01  |  Max: 99999.99"          
                                    rules={[
                                        { required: requiredImportoTotale ? true : false, message: requiredMessages.requiredField },
                                        {
                                            pattern: /^(\d{1,5}|\d{0,5}\.\d{1,2})$/,
                                            message: "Il valore deve contenere fino a 5 interi e 2 decimali "                                            
                                        }                                                                     
                                    ]}
                                >
                                    <InputNumber
                                        style={{ width: "100%" }}
                                        placeholder="Importo totale"      
                                        min={0.01}                                                                       
                                        precision={2}                                       
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Form.Item
                                    label="Totale Codici progressivi"
                                    name="totalProgressiveCodes"
                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                >
                                    <InputNumber style={{ width: "100%" }} min={0} placeholder="Totale Codici progressivi" />
                                </Form.Item>
                            </Col>

                        </Row>

                        <Divider orientation="left">Codice promozionale</Divider>

                        <Row gutter={{ lg: 24 }}>
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }} style={{ display: "flex", justifyContent: "space-between" }}>
                                <Space direction="vertical" size="middle">
                                    <Space.Compact size="large" className='promoCodeBox'>
                                        <Input
                                            className='mr10'
                                            style={{ background: "#f4cdcd" }}
                                            maxLength={1}
                                            disabled
                                            value={unionTypeBoxValue}
                                            prefix={<Tooltip title="Tipo di unione"><InfoCircleOutlined /></Tooltip>}
                                        />

                                        <Input
                                            className='mr10'
                                            style={{ background: "#e5f9db" }}
                                            maxLength={1}
                                            disabled
                                            value={warrantyTypeBoxValue}
                                            prefix={<Tooltip title="Azione da Fatturare"><InfoCircleOutlined /></Tooltip>}
                                        />

                                        <Input
                                            className='mr10'
                                            style={{ background: "#cbdcf7" }}
                                            maxLength={1}
                                            disabled
                                            value={brandBoxValue}
                                            prefix={<Tooltip title="Marchio"><InfoCircleOutlined /></Tooltip>}
                                        />

                                        <Input
                                            className='mr10'
                                            style={{ background: "#ffffff" }}
                                            disabled
                                            value={userInputBoxValue}
                                            prefix={<Tooltip title="Codice azione"><InfoCircleOutlined /></Tooltip>}
                                        />


                                        <Input
                                            maxLength={2}
                                            style={{ background: "#cbdcf7" }}
                                            disabled
                                            value={yearBoxValue}
                                            prefix={<Tooltip title="Anno"><InfoCircleOutlined /></Tooltip>}
                                        />
                                    </Space.Compact>
                                </Space>

                                <Space direction="horizontal" size="middle" className='promoCodeDisplay'>
                                    <Title level={2}>{unionTypeBoxValue ? unionTypeBoxValue : "_"} </Title>
                                    <Title level={2}>{warrantyTypeBoxValue ? warrantyTypeBoxValue : "_"} </Title>
                                    <Title level={2}>{brandBoxValue ? brandBoxValue : "_"} </Title>
                                    <Title level={2}>{userInputBoxValue ? userInputBoxValue : "_"} </Title>
                                    <Title level={2}>{yearBoxValue ? yearBoxValue : "_"} </Title>
                                </Space>
                            </Col>
                        </Row>


                        <Divider />
                        <Row gutter={{ lg: 24 }}>
                            <Col className="gutter-row justifySpaceBetween" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Button size='small' icon={<ArrowLeftOutlined />} onClick={() => { navigate("/promotions"); }}> Indietro </Button>
                                <Form.Item>
                                    <Button type='primary' disabled={!fieldChanged} loading={loadingSave} htmlType='submit' icon={<SaveOutlined />}> Salva </Button>
                                </Form.Item>
                            </Col>
                        </Row>

                    </Form>

                </Col>
            </Row>

        </>
    );
}
export default NewPromotion;
