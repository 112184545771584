import PageHeader from '../../shared/components/pageHeader';
import { Col, Row } from 'antd';
import TechniciansList from './techniciansList';
import './technicians.scss'

const TechniciansIndex = () => {

    return (
        <div className='techniciansWrappers'>
            <PageHeader title="Lista Tecnici " />
            <Row>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <TechniciansList /> 
                </Col>
            </Row>
        </div>
    );
}
export default TechniciansIndex;