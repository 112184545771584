import { trackPromise } from 'react-promise-tracker';
import { acquireToken } from '../configs/authentication/MsGraphApiCall';
import axiosInstance from '../configs/axiosConfigs/interceptors';

const DashboardServices = {
    
    getDashboardStatistics: function (userID) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'GET',
                url: `Dashboard/GetDashboardStatisticalData`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                },
                params: {
                    userID: userID
                },
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    getDashboardWarrantyStatus: function (startDate, endDate, userID) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'GET',
                url: `Dashboard/GetDashboardWarrantiesByStatus`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                },
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    userID: userID
                },
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    getDashboardPromotionStatus: function (startDate, endDate, userID) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'GET',
                url: `Dashboard/GetDashboardPromotionsByStatus`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                },
                params: {
                    startDate: startDate,
                    endDate: endDate,
                    userID: userID
                },
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },
}

export default DashboardServices;