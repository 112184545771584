import { trackPromise } from 'react-promise-tracker';
import { acquireToken } from '../configs/authentication/MsGraphApiCall';
import axiosInstance from '../configs/axiosConfigs/interceptors';


const WarrantyServices = {

    getWarrantyList: function (filters) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'GET',
                url: `Warrenties/GetWarrantiesList`,
                params: filters,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    updateWarranty: function (payload) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'PUT',
                url: `Warrenties/UpdateWarranty`,
                data: payload,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    resendConfirmationEmailForWarranty: function (warrantyId) {
        const data = acquireToken().then((tokenResponse) => {
            let queryParams = `?warrantyID=${warrantyId}`;
            const optionsVal = {
                method: 'PUT',
                url: `Warrenties/ResendConfirmationEmail${queryParams}`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },


    getWarrantyById: function (warrantyId) {
        const data = acquireToken().then((tokenResponse) => {
            let queryParams = `?warrantyId=${warrantyId}`
            const optionsVal = {
                method: 'GET',
                url: `Warrenties/GetWarranty${queryParams}`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    createWarranty: function (payload) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'POST',
                url: `Warrenties/AddWarranty`,
                data: payload,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },


    getContraenteData: function (clientId) {
        const data = acquireToken().then((tokenResponse) => {
            let queryParams = `?ClientId=${clientId}`
            const optionsVal = {
                method: 'PUT',
                url: `User/XXXX${queryParams}`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    getWarrantyType: function () {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'GET',
                url: `Warrenties/GetWarrantyTypes`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    getWarrantyPaymentType: function () {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'GET',
                url: `PaymentTypes/GetPaymentTypes`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    getWarrantyStatus: function () {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'GET',
                url: `Warrenties/GetWarrantyStatuses`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    getWarrantyNumber: function () {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'GET',
                url: `Warrenties/GetWarrantyNumber`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    warrantyTypeConfig: function (warrantyTypeId) {
        const data = acquireToken().then((tokenResponse) => {
            let queryParams = `?WarrantyTypeId=${warrantyTypeId}`
            const optionsVal = {
                method: 'GET',
                url: `Warrenties/GetWarrantyTypesConfiguration${queryParams}`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    // warrantyTypeConfig: function (warrantyTypeId) {
    //     let queryParams = `?WarrantyTypeId=${warrantyTypeId}`
    //     const optionsVal = {
    //         method: 'GET',
    //         url: `Warrenties/GetWarrantyTypesConfiguration${queryParams}`
    //     };
    //     const apiResponse = axiosInstance(optionsVal);
    //     return apiResponse;
    // },

    confirmCustomerEmail: function (customerId) {
        let queryParams = `?CustomerID=${customerId}`
        const optionsVal = {
            method: 'POST',
            url: `Email/ConfirmEmail${queryParams}`
        };
        const apiResponse = axiosInstance(optionsVal);
        return apiResponse;
    },

};

export default WarrantyServices;
