import { createSlice } from '@reduxjs/toolkit'
import GeneralServices from '../../services/generalServices';
import WarrantyServices from '../../services/warrantyServices';


const initialState = { 
  warrantyTypeList: []
};

const  warrantyTypeSlice = createSlice({
    name: 'warrantyType',
    initialState,
    reducers: {
      warrantyTypeLoaded(state, action) {       
        state.warrantyTypeList =  action.payload; 
      },
    },
  })

  export const { warrantyTypeLoaded } =  warrantyTypeSlice.actions;
  
  export default  warrantyTypeSlice.reducer;


  export const fetchWarrantyTypeList = () => async (dispatch) => {

    await WarrantyServices.getWarrantyType(true)
      .then(response => response.data)
      .then(resp => {        
        if (resp.success) {                    
          const warrantyTypeData = resp.responseObject.value.warrantyTypeList;
          dispatch(warrantyTypeLoaded(warrantyTypeData));          
        }
        else {          
      }
      }).catch(error => {
      });
  }