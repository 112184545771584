import PageHeader from '../../shared/components/pageHeader';
import { Col, Row } from 'antd';
import ProductsList from './productsList';
import './products.scss'

const ProductsIndex = () => {

    return (
        <div className='productsWrappers'>
            <PageHeader title="Prodotti" />
            <Row>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <ProductsList />
                </Col>
            </Row>
        </div>
    );
}
export default ProductsIndex;