import { createSlice } from '@reduxjs/toolkit'
import WarrantyServices from '../../services/warrantyServices';


const initialState = { 
  paymentTypeList: []
};

const  paymentTypeSlice = createSlice({
    name: 'paymentType',
    initialState,
    reducers: {
      paymentTypeLoaded(state, action) {       
        state.paymentTypeList =  action.payload; 
      },
    },
  })

  export const { paymentTypeLoaded } =  paymentTypeSlice.actions;
  
  export default  paymentTypeSlice.reducer;


  export const fetchPaymentTypeList = () => async (dispatch) => {

    await WarrantyServices.getWarrantyPaymentType()
      .then(response => response.data)
      .then(resp => {        
        if (resp.success) {                    
          const paymentTypeData = resp.responseObject.value.paymentTypes;
          dispatch(paymentTypeLoaded(paymentTypeData));          
        }
        else {          
      }
      }).catch(error => {
      });
  }