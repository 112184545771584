import React, { useState, useEffect } from 'react';
import { Table, message, Row, Col, Button,  Modal, Input, Form } from 'antd';
import { notifyMessages } from '../../shared/utils/errorMesages';
import PromotionsFilters from './promotionsFilters';
import PromotionServices from '../../services/promotionServices';
import { dateFormat, excelfileExtension, excelfileType, tablePageSize } from '../../shared/utils/constants';
import { CloseCircleOutlined, EyeOutlined } from '@ant-design/icons';
import moment from 'moment';
import { CheckCircleOutlined } from '@ant-design/icons';
import { ExportOutlined, PlusOutlined, SendOutlined } from '@ant-design/icons';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router';
import { useSelector } from 'react-redux';
import { isCat } from '../../configs/authRoleFunctions';

const PromotionsList = () => {

  let filtersInitValues = {
    customerID: [],
    active: true,
    pnc: '',
    startDate: null,
    endDate: null,
    billable: true,
    promotionalCode: '',
    sentC4C: null,
    ascOrdering: true,
    filterbystartdate: true,
    filterbyenddate: false,

  };

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [promotionsList, setPromotionsList] = useState(null);
  const [loadingPromotionsList, setLoadingPromotionsList] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [filterValues, setFilterValues] = useState(filtersInitValues);
  const [totalCountRecords, setTotalCountRecords] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [sorterColumn, setSorterColumn] = useState("");
  const [sorterType, setSorterType] = useState(true);
  const [currentPromotionId, setCurrentPromotionId] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [loadingEmailSend, setLoadingEmailSend] = useState(false);
  const [emailInput, setEmailInput] = useState('');
  const currentUser = useSelector(state => state.currentUser.loggedinUserData);

  useEffect(() => {
    getPromotionsListData(false);
  }, [pageNumber, filterValues, sorterColumn, sorterType]);

  
  const getPromotionsListData = async (isExport) => {
    let filterObj = filterValues;
    
    filterObj["pageNo"] = isExport ? 0 : pageNumber;
    filterObj["elementsNo"] = tablePageSize;
    filterObj["sortColumn"] = sorterColumn;
    filterObj["ascOrdering"] = sorterType;

    if (filterValues.billable) {
      filterObj["billable"] = filterValues.billable;
    }

    if (filterValues.filterbystartdate) {
      filterObj["filterbystartdate"] = filterValues.filterbystartdate;
    }

    setLoadingPromotionsList(isExport ? false : true);
    setLoadingExport(isExport ? true : false);

    await PromotionServices.getPromotionList(filterObj)
      .then(response => response.data)
      .then(resp => {
        if (resp.success) {
          let respData = resp.responseObject.value
          if (isExport) {
            generateExcelFile(respData.promotionList);
          }
          else {
            let record = respData.promotionList.find(function (element) {
              return element.recordNo != null;
            });

            setPromotionsList(respData.promotionList);
            setTotalCountRecords(record ? record.recordNo : "");
            setLoadingPromotionsList(false);
          }
        }
        else {
            setLoadingPromotionsList(false);
          message.error(notifyMessages.errorAccured);
        }
      }).catch(error => {
        setLoadingPromotionsList(false);
        message.error(notifyMessages.errorAccured);
      });
  }

  const onPageSorterChange = (page, filters, sorter) => {
    if (page) {
      if (sorter && sorter.field) {
          setSorterColumn(sorter.field);
          setPageNumber(page.current);

          if (sorter.order === "descend") {
              setSorterType(false);
          }
          if (sorter.order === "ascend") {
              setSorterType(true);
          }
      }
      else {
          setPageNumber(page.current);
          setSorterColumn("");
          setSorterType(true);
      }
    }
  }

  const filterData = (values) => {    
    setFilterValues(values);
    setPageNumber(1);
  };

  const clearFilters = () => {
    setPageNumber(1);
    setFilterValues(filtersInitValues);
  };

  const routeChange = () => {
    let path = '/promotions/new';
    navigate(path);
  }


  const promotionsColumns = [
    {
      title: 'Data Inizio',
      dataIndex: 'startDate',
      render: (text, record) => (text ? moment(text).format(dateFormat) : ""),
      sorter: (a, b) => {
        a = a.startDate || '';
        b = b.startDate || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],
    },
    {
      title: 'Data Fine',
      dataIndex: 'endDate',
      render: (text, record) => (text ? moment(text).format(dateFormat) : ""),
      sorter: (a, b) => {
        a = a.endDate || '';
        b = b.endDate || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],     
    },
    {
      title: 'Azione da Fatturare',
      dataIndex: 'billable',
      sorter: (a, b) => {
        a = a.billable ? 1 : 0;
        b = b.billable ? 1 : 0;
        return a - b;
      },
      render: (active) => (
        active ? <CheckCircleOutlined style={{ color: "#4da93d" }} /> : <CloseCircleOutlined style={{ color: "#777876" }}/>
      ),
      showSorterTooltip: [false],     
    },
    {
      title: 'Importo',
      dataIndex: 'totalAmount',
      sorter: (a, b) => {
        a = String(a.totalAmount) || '';
        b = String(b.totalAmount) || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],
    },
    {
        title: 'Service Material',
        dataIndex: 'pnc',
        sorter: (a, b) => {
          a = a.pnc || '';
          b = b.pnc || '';
          return a.localeCompare(b)
        },
        showSorterTooltip: [false],
      },
    {
      title: 'Cliente ',
        dataIndex: 'customerName',       
        sorter: (a, b) => {
        const nameA = `${a.customerName} ${a.customerSurname}`;
        const nameB = `${b.customerName} ${b.customerSurname}`;
        return nameA.localeCompare(nameB);
        },
        render: (text, record) => {
          const fullName = `${record.customerName || ''} ${record.customerSurname || ''}`;
          return fullName;
        },
      showSorterTooltip: [false],
    },
    {
      title: 'Anno di riferimento',
      dataIndex: 'referencePeriod',
      sorter: (a, b) => {
        a = String(a.referencePeriod) || '';
        b = String(b.referencePeriod) || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],
    },
    {
      title: 'Codice azione',
      dataIndex: 'actionCode',
      sorter: (a, b) => {
        a = a.actionCode || 0;
        b = b.actionCode || 0;
        return a - b;
    },
      showSorterTooltip: [false],
    },
    {
      title: 'Codice promozionale',
      dataIndex: 'promotionalCode',
      sorter: (a, b) => {
        a = a.promotionalCode || '';
        b = b.promotionalCode || '';
        return a.localeCompare(b)
      },
      showSorterTooltip: [false],
    },
    {
        title: 'Stato ',
        dataIndex: 'active',
        sorter: (a, b) => {
          a = a.active ? 1 : 0;
          b = b.active ? 1 : 0;
          return a - b;
        },
        render: (active) => (
          active ? <CheckCircleOutlined style={{ color: "#4da93d" }} /> : <CloseCircleOutlined style={{ color: "#777876" }}/>
        ),
        showSorterTooltip: [false],
      },
    {
        key: 'action',
        width: "30px",
        render: (text, record) => (
          <Link to={{ pathname: `/promotion/id/${record.id}` }}><EyeOutlined  /></Link>
        ),
    },
    {
      key: 'action',
      width: "30px",
      render: (text, record) => ( 
        (!isCat(currentUser) && (
          <a onClick={() => showEmailModal(record)}>
            <SendOutlined />
          </a>
        ))         
      ),
    }
    
  ];

  const sendPromotionCodes = async (promotionId, email) => {
    setLoadingEmailSend(true);
    try {
      const response = await PromotionServices.sendPromotionCodes(promotionId, email);
      if (response.data.success) {
        message.success('Email inviato con successo!');
        setLoadingEmailSend(false);
      } else {
        message.error(notifyMessages.errorAccured);
        setLoadingEmailSend(false);
      }
      setIsModalVisible(false); 
    } catch (error) {
      message.error(notifyMessages.errorAccured);
      setIsModalVisible(false);
      setLoadingEmailSend(false);
    }
  };

  const showEmailModal = (record) => {
    setEmailInput(record.customerEmail); 
    form.setFieldsValue({
      email: record.customerEmail ? record.customerEmail : null,
  });
    setCurrentPromotionId(record.id);
    setIsModalVisible(true);
  };

  const handleEmailModalCancel = () => {
    setIsModalVisible(false);
  };
  
  
  const generateExcelFile = (promotionsData) => {
    let formatedData = [];

    if (promotionsData && promotionsData.length > 0) {

        promotionsData.map(item => {
        formatedData.push({
            startDate: moment(item.startDate).format(dateFormat),
            endDate: moment(item.endDate).format(dateFormat),
            billable: item.billable,
            totalAmount: item.totalAmount,
            pnc: item.pnc,
            customerName: `${item.customerName ? item.customerName : ''} ${item.customerSurname ? item.customerSurname : ''}`,
            referencePeriod: item.referencePeriod,
            actionCode: item.actionCode,
            promotionalCode: item.promotionalCode,
            active: item.active
        })
      });

      const fileName = `ESPORTAZIONE-PROMOZIONI-${moment().format(dateFormat)}`;
      let headers = [['Data Inizio', 'Data Fine', 'Azione da Fatturare', 'Importo', 'Service Material ', 'Cliente', 'Anno di riferimento', 'Codice azione', 'Codice promozionale', 'Stato']];
      const ws = XLSX.utils.json_to_sheet([]);
      XLSX.utils.sheet_add_json(ws, formatedData, { origin: 'A2', skipHeader: true })
      XLSX.utils.sheet_add_aoa(ws, headers);
      const wb = { Sheets: { 'Esportazione Promozioni': ws }, SheetNames: ['Esportazione Promozioni'] };

        let wsColsWidthFormatter = [
            { wpx: 120 }, // column width
            { wpx: 100 }, // column width
            { wpx: 100 }, // column width
            { wpx: 100 }, // column width
            { wpx: 120 }, // column width
            { wpx: 120 }, // column width
            { wpx: 100 }, // column width
            { wpx: 130 }, // column width
            { wpx: 130 }, // column width
            { wpx: 100 }, // column width
        ];
        ws['!cols'] = wsColsWidthFormatter;

      const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
      const data = new Blob([excelBuffer], { type: excelfileType });
      FileSaver.saveAs(data, fileName + excelfileExtension);
      setLoadingExport(false);

    }
    else {
      message.warning(notifyMessages.noDataFound);
      setLoadingExport(false);
    }
  }

  const onExportPromotionsList = () => {
    getPromotionsListData(true);
  }

  const handleOk = () => {    
    form.validateFields(['email'])
    .then(() => {
      sendPromotionCodes(currentPromotionId, emailInput);
    })
    .catch((info) => {
      console.log('Validate Failed:', info);
    });
  };

  return (
    <>
      <Modal
          title="Inviare l'e-mail"
          open={isModalVisible}
          onCancel={handleEmailModalCancel}
          footer={[
            <Button key="back" onClick={handleEmailModalCancel}>
              Annulla
            </Button>,
            <Button key="submit" type="primary" loading={loadingEmailSend} onClick={handleOk}>
              Invia
            </Button>
          ]}
        >
          <p>Sei sicuro di voler inviare un'e-mail?</p>
          <Form
            id='newEmail'
            layout="vertical"
            form={form}
            autoComplete="off"
          >
            <Form.Item
                name="email"
                rules={[
                { required: true, message: "L'e-mail è obbligatoria" },
                { type: 'email', message: "L'email non è valida" }
              ]}
            >
          <Input
            placeholder="Inserisci l'indirizzo email del cliente"
            onChange={(e) => setEmailInput(e.target.value)}
          />
        </Form.Item>
        </Form>
      </Modal>

      <Row justify="center" gutter={[16, 16]}>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <PromotionsFilters
            onFilterData={filterData}
            onResetData={clearFilters}
            filterValues={filterValues}
            filtersInitValues={filtersInitValues}
          />
        </Col>

        {!isCat(currentUser) && (
          <Col className="gutter-row justifyRight" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Button size='small' type='primary' onClick={() => { routeChange() }}> <PlusOutlined /> Aggiungi nuovo</Button>
          </Col>
        )}

        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <Table
            size='small'
            columns={promotionsColumns}
            dataSource={promotionsList}
            rowKey={(obj) => obj.id}
            loading={loadingPromotionsList}
            onChange={(pagination, filters, sorter) => onPageSorterChange(pagination, filters, sorter)}
            pagination={{
              pageSize: tablePageSize,
              showSizeChanger: false,
              total: totalCountRecords || '',
              current: pageNumber
            }}
          />
        </Col>

        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <Button onClick={() => onExportPromotionsList()} loading={loadingExport} type="dashed" icon={<ExportOutlined />}>Esporta promozioni</Button>
        </Col>
      </Row>
    </>
  );
};

export default PromotionsList;
