import React from 'react';
import { Route, Routes } from 'react-router-dom';
import AppLayout from '../components/layout/appLayout';
import NF404 from "../shared/components/errorPages/404/404";
import Unauthorized from "../shared/components/errorPages/unauthorized/unauthorized";
import DashboardIndex from "../components/dashboard/dashboardIndex";
import UsersIndex from "../components/users/usersIndex";
import WarrantyIndex from "../components/warranty/warrantyIndex";
import ClientsIndex from "../components/clients/clientsIndex";
import ProductsIndex from "../components/products/productsIndex";
import PromotionsIndex from '../components/promotions/promotionsIndex';
import ConfigurationsIndex from "../components/configurations/configurationsIndex";
import LoginPage from "../components/loginPage/loginPage";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from '@azure/msal-react';
import ViewEditUser from '../components/users/viewEditUser';
import NewUser from '../components/users/newUser';
import NewWarranty from '../components/warranty/newWaranty';
import Client from '../components/clients/clientDetails/client';
import Warranty from '../components/warranty/warranty/warranty';
import Promotion from '../components/promotions/promotionDetails/promotion';
import NewPromotion from '../components/promotions/newPromotion';
import EmailConfirmationPage from '../shared/components/errorPages/emailConfirmation/emailConfirmation';
import TechniciansIndex from '../components/technicians/techniciansIndex';

const AuthWrappedRoutes = ({ ...props }) => {


    const randomGenKey = () => {
        var result = [];
        var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        var charactersLength = characters.length;
        for (var i = 0; i < 20; i++) {
            result.push(characters.charAt(Math.floor(Math.random() *
                charactersLength)));
        }
        return result.join('');
    }


    const ProtectedRoute = ({ children, roles }) => {
        return (
            <>
                <AuthenticatedTemplate>
                    {children}
                </AuthenticatedTemplate>

                <UnauthenticatedTemplate>
                    <LoginPage />
                </UnauthenticatedTemplate>
            </>
        )
    };

    // const ProtectedByRole = ({ children, roles }) => {
    //     const currentLogedUser = currentUser;

    //     if (currentLogedUser && Object.keys(currentLogedUser).length !== 0) {

    //         if (!currentUser || !auth.isAuthenticated) {

    //             if (roles && roles.indexOf(currentLogedUser.lastSelectedRoleCode) === -1) {
    //                 // role not authorised so redirect to home page
    //                 return <Navigate to={{ pathname: '/' }} />
    //             }

    //         }

    //         // has role so return component
    //         return children;
    //     }
    // };


    return (
        <Routes>

            <Route path="/unauthorized" element={<Unauthorized />} />
            <Route path="/confirmemail/:id" element={<EmailConfirmationPage />} />


            <Route path="/" element={<ProtectedRoute><AppLayout />  </ProtectedRoute>}>
                <Route path="/login" element={<LoginPage />} />

                <Route path="/" element={<DashboardIndex />} />
                <Route path="/dashboard" element={<DashboardIndex />} />

                {/* users routes */}
                <Route path="/users" element={<UsersIndex />} />
                <Route path="/user/new" element={<NewUser />} />
                <Route path="/user/id/:id" element={<ViewEditUser key={randomGenKey} />} />

                {/* warranty routes */}
                <Route path="/warranties" element={<WarrantyIndex />} />
                <Route path="/warranty/new" element={<NewWarranty />} />
                <Route path="/warranty/id/:id" element={<Warranty key={randomGenKey} />} />

                {/* clients routes */}
                <Route path="/clients" element={<ClientsIndex />} />
                <Route path="/client/id/:id" element={<Client key={randomGenKey} />} />

                {/* products routes */}
                <Route path="/products" element={<ProductsIndex />} />

                {/* configurations routes */}
                <Route path="/configs" element={<ConfigurationsIndex />} />

                {/* promotions routes */}
                <Route path="/promotions" element={<PromotionsIndex />} />
                <Route path="/promotions/new" element={<NewPromotion />} /> 
                <Route path="/promotion/id/:id" element={<Promotion key={randomGenKey} />} />

                {/* technicians routes */}
                <Route path="/technicians" element={<TechniciansIndex />} />

                <Route path="*" element={<NF404 />} />
            </Route>

        </Routes>

    );
}

export default AuthWrappedRoutes;