import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Select, message, Button, Switch } from 'antd';
import { notifyMessages, requiredMessages } from '../../shared/utils/errorMesages';
import { useSelector } from 'react-redux';
import { Link, useNavigate, useParams } from 'react-router-dom';
import UserServices from '../../services/usersServices';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import PageHeader from '../../shared/components/pageHeader';
import { appaltoCodeRolesGroup } from '../../configs/authRoleFunctions';
import { getAppaltoCode } from '../../shared/functions/sharedFunctions';


const ViewEditUser = () => {

    const [form] = Form.useForm();
    const urlParams = useParams();
    const [fieldChanged, setFieldChanged] = useState(false);
    const [selectedUserData, setSelectedUserData] = useState(null);
    const [loadingSave, setLoadingSave] = useState(false);
    const [disabledAppaltoCode, setDisabledAppaltoCode] = useState(false);

    const navigate = useNavigate();

    const roleList = useSelector(state => state.rolesList.rolesList);

    const rolesPicklist = roleList.reduce((res, item) => {
        res.push({ value: item.id, label: item.name });
        return res;
    }, []);


    useEffect(() => {
        getUserByid();
    }, []);

    const getUserByid = async () => {
        await UserServices.getUserById(urlParams.id)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value;
                    setSelectedUserData(respData.user);
                }
                else {
                    message.error(notifyMessages.errorAccured);
                }
            }).catch(error => {
                message.error(notifyMessages.errorAccured);
            });
    }

    const updateUserData = async (values) => {
        values["ID"] = urlParams.id;
        setLoadingSave(true);

        await UserServices.updateUser(values)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    setLoadingSave(false);
                    message.success(notifyMessages.updateSuccess);
                    navigate("/users")
                }
                else {                    
                    if(resp.errorDetail && resp.errorDetail.errorMessage && resp.errorDetail.errorMessage !== "" ){
                        message.error("Questo codice appalto non esiste");
                    }
                    else{
                        setLoadingSave(false);
                        message.error(notifyMessages.errorAccured);
                    }
                    
                }
            }).catch(error => {
                setLoadingSave(false);
                message.error(notifyMessages.errorAccured);
            });
    }

    const roleSelectAppaltoCode = async (roleid) => {
        let appaltoCodeObj = await getAppaltoCode(roleid);

        if (appaltoCodeObj) {
            if (appaltoCodeObj.appaltoCode != 0) {
                setDisabledAppaltoCode(true);
                form.setFieldsValue({
                    appaltoCode: appaltoCodeObj.appaltoCode,
                });
            } else {
                setDisabledAppaltoCode(false);
                form.setFieldsValue({
                    appaltoCode: null,
                });
            }
        }
        else {
            message.error(notifyMessages.errorAccured);
        }
    }

    const onRoleSelect = (option) => {
        const selectedRole = roleList.find((role) => role.id === option);
        if (selectedRole) {
            let hasRights = appaltoCodeRolesGroup(selectedRole.roleCode);
            if (hasRights) {
                roleSelectAppaltoCode(selectedRole.id);
            }
            else {
                setDisabledAppaltoCode(false);
                form.setFieldsValue({
                    appaltoCode: null,
                });
            }
        }
    }

    const handleAppaltoCodeBlur = (e) => {
        let input = e.target.value;

        input = input.padStart(5, '0');
        form.setFieldsValue({
            appaltoCode: input,
        });
    };

    const handleAppaltoCodeInput = (e) => {
        let input = e.target.value;

        if (input.length > 5) {
            input = input.slice(0, 5);
        }
        e.target.value = input;
    };

    return (
        <>
            {selectedUserData && (
                <div>
                    <PageHeader title={"Utente: " + `${selectedUserData.name}` + " " + `${selectedUserData.surname}`} />
                    <Row gutter={{ lg: 24 }}>
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>

                            <Form
                                id='editClient'
                                layout="vertical"
                                form={form}
                                onFinish={updateUserData}
                                autoComplete="off"
                                onValuesChange={() => {
                                    setFieldChanged(true)
                                }}
                            >

                                <Row gutter={{ lg: 24 }}>
                                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                        <Form.Item
                                            label="Nome"
                                            name="name"
                                            initialValue={selectedUserData.name}
                                            rules={[{ required: true, message: requiredMessages.requiredField }]}
                                        >
                                            <Input placeholder="Nome" />
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                        <Form.Item
                                            label="Cognome"
                                            name="surname"
                                            initialValue={selectedUserData.surname}
                                            rules={[{ required: true, message: requiredMessages.requiredField }]}
                                        >
                                            <Input placeholder="Cognome" />
                                        </Form.Item>
                                    </Col>
                                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                        <Form.Item
                                            label="Ragione Sociale"
                                            name="userName"
                                            initialValue={selectedUserData.username}
                                            rules={[{ required: true, message: requiredMessages.requiredField }]}
                                        >
                                            <Input placeholder="Ragione Sociale" />
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                        <Form.Item
                                            label="Email"
                                            name="email"
                                            initialValue={selectedUserData.email}
                                        >
                                            <Input placeholder="Email" disabled />
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                        <Form.Item
                                            label="Role"
                                            name="roleID"
                                            initialValue={selectedUserData.roleID}
                                            rules={[{ required: true, message: requiredMessages.requiredField }]}
                                        >
                                            <Select
                                                placeholder="Ruolo"
                                                allowClear={false}
                                                options={rolesPicklist}
                                                onChange={onRoleSelect}
                                            />
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                        <Form.Item
                                            label="Codice Appalto"
                                            name="appaltoCode"
                                            initialValue={selectedUserData.appaltoCode}
                                            rules={[{ required: true, message: requiredMessages.requiredField }]}
                                        >
                                             <Input placeholder="Codice Appalto" type="number" onBlur={handleAppaltoCodeBlur} onInput={handleAppaltoCodeInput} disabled={disabledAppaltoCode} />
                                        </Form.Item>
                                    </Col>

                                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                        <Form.Item
                                            label="Abilitato"
                                            name="enabled"
                                            initialValue={selectedUserData.enabled}
                                            valuePropName="checked"
                                        >
                                            <Switch />
                                        </Form.Item>
                                    </Col>


                                    <Col className="gutter-row justifySpaceBetween" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                        <Button size='small' icon={<ArrowLeftOutlined />} onClick={() => { navigate("/users"); }}> Indietro </Button>

                                        <Form.Item>
                                            <Button disabled={!fieldChanged} type='primary' loading={loadingSave} htmlType='submit' icon={<SaveOutlined />}> Salva </Button>
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Form>

                        </Col>
                    </Row>
                </div>
            )}
        </>
    );
}
export default ViewEditUser;