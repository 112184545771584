
import React from 'react';
import { Button, Row, Typography, Card } from 'antd';
import { useMsal } from '@azure/msal-react';
import logo from "../../assets/elx.jpeg";
import { LoginOutlined } from '@ant-design/icons';
import moment from "moment";
import Layout from 'antd/es/layout/layout';
import { loginRequest } from '../../configs/authentication/msalConfigs';

const { Meta } = Card;
const { Title } = Typography;

const LoginPage = ({ }) => {

    const { instance } = useMsal();
    const currentYear = moment().year();

    const handelSignIn = () => {
        instance.loginRedirect({
            loginRequest
        })
    }
    return (
        <Layout>
            <div className='loginPage'>
                <Row justify="center">
                    <Card cover={<img src={logo} width={"100"} alt="LOGO" />}
                        actions={[<p>  © {currentYear} Electrolux</p>]}
                    >
                        <Meta
                            title={<Title level={3} style={{ whiteSpace: "break-spaces" }}>Electrolux Warranty Portal</Title>}
                            description={<Button type="primary" block onClick={() => handelSignIn()}>Access Portal <LoginOutlined /></Button>}
                        />
                    </Card>
                </Row>
            </div>
        </Layout>
    );
}
export default LoginPage;