
import React, { useState, useEffect } from 'react';
import { Table, message, Row, Col, Button } from 'antd';
import { notifyMessages } from '../../shared/utils/errorMesages';
import UserServices from '../../services/usersServices';
import UsersFilters from './usersFilters';
import { tablePageSize } from '../../shared/utils/constants';
import { EditFilled, PlusOutlined, DownloadOutlined, EyeOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { generateSorterRule } from '../../shared/functions/sharedFunctions';
import { dateFormat, excelfileExtension, excelfileType} from '../../shared/utils/constants';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import moment from 'moment';



const UsersList = (props) => {

    let filtersInitValues = {
        enabled: true,
        name: "",
        surname: "",
        email: "",
        roleID: null,
        appaltoCode: "",
    }

    const navigate = useNavigate();
    const [usersList, setUsersList] = useState(null);
    const [loadingUsersList, setLoadingUsersList] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [filterValues, setFilterValues] = useState(filtersInitValues);
    const [totalCountRecords, setTotalCountRecords] = useState(null);
    const [sorterColumn, setSorterColumn] = useState("");
    const [sorterType, setSorterType] = useState(true);
    const [loadingExport, setLoadingExport] = useState(false);

    useEffect(() => {
        getUserList(false);
    }, [pageNumber, filterValues, sorterColumn, sorterType]);

    const getUserList = async (isExport) => {
        let filterObj = filterValues;
        filterObj["pageNo"] = isExport ? null : pageNumber;
        filterObj["sortColumn"] = sorterColumn;
        filterObj["elementsNo"] = tablePageSize;
        filterObj["ascOrdering"] = sorterType;

        setLoadingUsersList(isExport ? false : true);
        setLoadingExport(isExport ? true : false);
        await UserServices.getUsersList(filterObj)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value;
                    if(isExport){                        
                        generateExcelFile(respData.userModelResponses);
                    }
                    else{
                        let record = respData.userModelResponses.find(function (element) {
                            return element.recordNo != null;
                        });
                        setUsersList(respData.userModelResponses);
                        setTotalCountRecords(record ? record.recordNo : "");
                        setLoadingUsersList(false);
                    }                   
                }
                else {
                    setLoadingUsersList(false);
                    message.error(notifyMessages.errorAccured);
                }
            }).catch(error => {
                setLoadingUsersList(false);
                message.error(notifyMessages.errorAccured);
            });
    }

    const exportUsersDataList = async () => {
        getUserList(true);
    }

    const onPageSorterChange = (page, filters, sorter) => {
        if (page) {
            if (sorter && sorter.field) {
                setSorterColumn(sorter.field);
                setPageNumber(page.current);

                if (sorter.order === "descend") {
                    setSorterType(false);
                }
                if (sorter.order === "ascend") {
                    setSorterType(true);
                }
            }
            else {
                setPageNumber(page.current);
                setSorterColumn("");
                setSorterType(true);
            }
        }
    };

    const filterData = (values) => {
        setFilterValues(values);
        setPageNumber(1);
    }

    const clearFilters = () => {
        setPageNumber(1);
        setFilterValues(filtersInitValues);
    }

    const routeChange = () => {
        let path = '/user/new';
        navigate(path);
    }

    const userColumns = [
        {
            title: 'Nome',
            dataIndex: 'name',
            sorter: (a, b) => {
                a = a.name || '';
                b = b.name || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Cognome',
            dataIndex: 'surname',
            sorter: (a, b) => {
                a = a.surname || '';
                b = b.surname || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: (a, b) => {
                a = a.email || '';
                b = b.email || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Ragione Sociale',
            dataIndex: 'username',
            sorter: (a, b) => {
                a = a.username || '';
                b = b.username || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Codice Appalto',
            dataIndex: 'appaltoCode',
            sorter: (a, b) => {
                a = a.appaltoCode || '';
                b = b.appaltoCode || '';
                return a.itemIndex - b.itemIndex;
            },
            showSorterTooltip: [false],
        },
        {
            title: 'Ruolo',
            dataIndex: 'role',
            sorter: (a, b) => {
                a = a.role || '';
                b = b.role || '';
                return a.localeCompare(b)
            },
            showSorterTooltip: [false],
        },
        
        {
            key: 'action',
            width: "30px",
            render: (text, record) => (
                <Link to={{ pathname: `/user/id/${record.id}` }}><EyeOutlined  /></Link>
            ),
        },
    ];


    const generateExcelFile = (usersData) => {
        let formatedData = [];

        if (usersData && usersData.length > 0) {
          
            usersData.map(item => {
                formatedData.push({
                    name: item.name,
                    surname: item.surname,
                    email: item.email,
                    username: item.username,
                    appaltoCode: item.appaltoCode,
                    role: item.role,
                })
            });

            const fileName = `ESPORTAZIONE-USER-${moment().format(dateFormat)}`;
            let headers = [['Nome', 'Cognome', 'Email', 'Username', 'Appalto Code', 'Ruolo']];
            const ws = XLSX.utils.json_to_sheet([]);
            XLSX.utils.sheet_add_json(ws, formatedData, { origin: 'A2', skipHeader: true })
            XLSX.utils.sheet_add_aoa(ws, headers);
            const wb = { Sheets: { 'Esportazione User': ws }, SheetNames: ['Esportazione User'] };

            let wsColsWidthFormatter = [
                { wpx: 120 }, // column width
                { wpx: 120 }, // column width
                { wpx: 150 }, // column width
                { wpx: 120 }, // column width
                { wpx: 100 }, // column width
                { wpx: 100 }, // column width
            ];
            ws['!cols'] = wsColsWidthFormatter;

            const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
            const data = new Blob([excelBuffer], { type: excelfileType });
            FileSaver.saveAs(data, fileName + excelfileExtension);
            setLoadingExport(false);

        }
        else {
            message.warning(notifyMessages.noDataFound);
            setLoadingExport(false);
        }
    }

    return (
        <>

            <Row justify="center" align="top" gutter={[16, 16]}>

                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <UsersFilters onFilterData={filterData} onResetData={clearFilters} filterValues={filterValues} filtersInitValues={filtersInitValues} />
                </Col>

                <Col className="gutter-row justifyRight" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Button size='small' type='primary' onClick={() => { routeChange() }}> <PlusOutlined /> Aggiungi nuovo</Button>
                </Col>

                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>

                    <Table
                        size='small'
                        columns={userColumns}
                        dataSource={usersList}
                        rowKey={(obj) => obj.id}
                        loading={loadingUsersList}
                        onChange={(pagination, filters, sorter) => onPageSorterChange(pagination, filters, sorter)}
                        pagination={{
                            pageSize: tablePageSize,
                            showSizeChanger: false,
                            total: totalCountRecords ? totalCountRecords : "",
                            current: pageNumber
                        }}
                    />

                </Col>

                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Button onClick={() => exportUsersDataList()} loading={loadingExport} type="dashed" icon={<DownloadOutlined />}>Esporta clienti</Button>
                </Col>

            </Row>

        </>
    );
}
export default UsersList;