import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom'
import { FloatButton, Layout, Skeleton } from 'antd';
import AppFooter from "./footer/footer";
import './layout.scss';
import AppHeader from './header/header';
import AppMenu from './menu/menu';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import LoginPage from '../loginPage/loginPage';
import { InteractionRequiredAuthError, InteractionStatus } from '@azure/msal-browser';
import { callMsGraphUserData } from '../../configs/authentication/MsGraphApiCall';
import { loginRequest } from '../../configs/authentication/msalConfigs';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import store from '../../redux/store';
import { fetchCurrentUser } from '../../redux/slices/currentUserSlice';
import { fetchRolesList } from '../../redux/slices/rolesSlice';
import { fetchTecnicList } from '../../redux/slices/tecnicsSlice';
import { fetchWarrantyTypeList } from '../../redux/slices/warrantyTypeSlice';
import { fetchWarrantyStatusList } from '../../redux/slices/warrantyStatusSlice';
import { fetchUnionTypeList } from '../../redux/slices/unionTypeSlice';
import { fetchPromotionList } from '../../redux/slices/PromotionSlice';
import { fetchPaymentTypeList } from '../../redux/slices/paymentTypeSlice';

const { Content } = Layout;

const AppLayout = ({ }) => {

    const isAuthenticated = useIsAuthenticated();
    const currentUser = useSelector(state => state.currentUser.loggedinUserData);
    const location = useLocation();
    const navigate = useNavigate();
    const { instance, inProgress } = useMsal();
    const [userGraphData, setUserGraphData] = useState(null);

    // fetch redux state props
    useEffect(() => {
        if (isAuthenticated) {
            store.dispatch(fetchCurrentUser());
            store.dispatch(fetchRolesList());
            store.dispatch(fetchWarrantyTypeList());
            store.dispatch(fetchWarrantyStatusList());
            store.dispatch(fetchUnionTypeList());
            store.dispatch(fetchPaymentTypeList());                   
        }
    }, []);

    useEffect(() => {
        if (isAuthenticated && location && location.pathname.toLocaleLowerCase() === '/login') {
            navigate('/');
        }
    }, []);


    useEffect(() => {
        if (!userGraphData && inProgress === InteractionStatus.None) {
            callMsGraphUserData().then(
                response => setUserGraphData(response)
            ).catch((e) => {
                if (e instanceof InteractionRequiredAuthError) {
                    instance.acquireTokenRedirect({
                        ...loginRequest,
                        account: instance.getActiveAccount()
                    });
                }
            });
        }
    }, [inProgress, userGraphData, instance]);


    useEffect(() => {
        if (currentUser) {           
            store.dispatch(fetchTecnicList(currentUser.appaltoCode));        
        }
    }, [currentUser]);

    console.log("userGraphData", userGraphData)
    console.log("currentUser", currentUser)

    return (

        <Layout className='appLayout' style={{ minHeight: '100vh' }}>

            {(userGraphData ) && (
                <>
                    <AppHeader isAuthenticated={isAuthenticated} userGraphData={userGraphData} />

                    {(isAuthenticated && currentUser) && (
                        <Layout>
                            <AppMenu />
                            <Layout style={{ padding: '0 10px 0px' }}>
                                <Content className="site-layout-background" style={{ padding: 10, margin: 0, minHeight: 280 }}>
                                    <Outlet />
                                </Content>
                                <AppFooter />
                            </Layout>
                        </Layout>
                    )}

                    {(isAuthenticated && !currentUser) && (
                           <>           
                           <Skeleton.Button active={true} size={"default"} shape={"square"} block={"circle"} />
                           <p style={{ textAlign: "center" }}>  <LoadingOutlined /> Loading...</p>
                           </>
                    )}

                    {!isAuthenticated && (
                        <LoginPage isAuthenticated={isAuthenticated} />
                    )}
                </>
            )}

            {/* {(!userGraphData || !currentUser) && ( */}

            {(!isAuthenticated) && (
                <>              
                <Skeleton.Button active={true} size={"default"} shape={"square"} block={"circle"} />
                <p style={{ textAlign: "center" }}>  <LoadingOutlined /> Loading...</p>
                </>
            )}

            <FloatButton.BackTop />
        </Layout>
    );
}
export default AppLayout;

