import React, { useState, useEffect } from 'react';
import { Form, Card, Col, Row, Statistic } from 'antd';
import { BookOutlined, PercentageOutlined, ContainerOutlined, LoadingOutlined } from '@ant-design/icons';
import DashboardServices from '../../services/dashboardServices';
import { useSelector } from 'react-redux';

const DashboardStatistics = () => {

    const [form] = Form.useForm();
    const [statisticsData, setStatisticsData] = useState(null);
    const [loadingStatisticsData, setLoadingStatisticsData] = useState(false);

    const currentUser = useSelector(state => state.currentUser.loggedinUserData);

  useEffect(() => {
    getDashboardStatistics();
  }, []);


  const getDashboardStatistics = async () => {
    setLoadingStatisticsData(true)
    await DashboardServices.getDashboardStatistics(currentUser.id)
        .then(response => response.data)
        .then(resp => {
            if (resp.success) {              
              let respData = resp.responseObject.value;
              setStatisticsData(respData.getDashboardStatisticalData); 
              setLoadingStatisticsData(false)
            }
            else {
              setLoadingStatisticsData(false)
            }
        }).catch(error => {
          setLoadingStatisticsData(false)
        });
}


    console.log("statisticsData", statisticsData)
    return (
      <Row gutter={{ lg: 24 }}>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
          <Form layout="horizontal" className='statisticsForm' form={form}>
            <Row gutter={{ lg: 24 }}>
              <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                <Row gutter={{ lg: 24 }}>
                  <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                    <Form.Item>
                      <Card bordered={false} style={{ borderTop: '3px solid #3319D4' }}>
                        <Row align="middle">
                          <Col span={6} style={{ textAlign: 'center' }}>
                            <ContainerOutlined style={{ fontSize: '40px', color: '#3319D4' }} />
                          </Col>
                          <Col span={18} style={{ textAlign: 'center' }}>
                            <div>Garanzie in scadenza nei prossimi 30 gg </div>

                            {loadingStatisticsData && (
                              <LoadingOutlined />
                            )}

                            {!loadingStatisticsData && (
                              <Statistic value={statisticsData?.expiringWarranties } />
                            )}
                           
                          </Col>
                        </Row>
                      </Card>
                    </Form.Item>
                  </Col>
  
                  <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                    <Form.Item>
                      <Card bordered={false} style={{ borderTop: '3px solid #285B19'}}>
                        <Row align="middle">
                          <Col span={6} style={{ textAlign: 'center' }}>
                            <PercentageOutlined style={{ fontSize: '40px', color: '#285B19' }} />
                          </Col>
                          <Col span={18} style={{ textAlign: 'center' }}>
                            <div>Promozioni in scadenza nei prossimi 30 gg</div>
                            {loadingStatisticsData && (
                              <LoadingOutlined />
                            )}

                            {!loadingStatisticsData && (
                              <Statistic value={statisticsData?.expiringPromotions } />
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                    <Form.Item>
                      <Card bordered={false} style={{ borderTop: '3px solid #215EFF'}}>
                        <Row align="middle">
                          <Col span={6} style={{ textAlign: 'center' }}>
                            <BookOutlined style={{ fontSize: '40px' , color: '#215EFF'}} />
                          </Col>
                          <Col span={18} style={{ textAlign: 'center' }}>
                            <div>Garanzie in attesa conferma email nei ultimi 30 gg</div>
                            {loadingStatisticsData && (
                              <LoadingOutlined />
                            )}

                            {!loadingStatisticsData && (
                              <Statistic value={statisticsData?.pendingApprovalWarranties } />
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </Form.Item>
                  </Col>

                  <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 6 }}>
                    <Form.Item>
                      <Card bordered={false} style={{ borderTop: '3px solid #429929'}}>
                        <Row align="middle">
                          <Col span={6} style={{ textAlign: 'center' }}>
                            <BookOutlined style={{ fontSize: '40px', color: '#429929' }} />
                          </Col>
                          <Col span={18} style={{ textAlign: 'center' }}>
                            <div>Garanzie con email confermata nei ultimi 30 gg</div>
                            {loadingStatisticsData && (
                              <LoadingOutlined />
                            )}

                            {!loadingStatisticsData && (
                              <Statistic value={statisticsData?.confirmedWarranties } />
                            )}
                          </Col>
                        </Row>
                      </Card>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </Form>
        </Col>
      </Row>
    );
  };

export default DashboardStatistics;
