import { createSlice } from '@reduxjs/toolkit'
import UnionTypeServices from '../../services/unionTypeServices';


const initialState = { 
    unionTypeList: []
};

const  UnionTypeSlice = createSlice({
    name: 'unionType',
    initialState,
    reducers: {
        unionTypeLoaded(state, action) {       
          state.unionTypeList =  action.payload; 
      },
    },
  })

  export const { unionTypeLoaded } =  UnionTypeSlice.actions;
  
  export default  UnionTypeSlice.reducer;


  export const fetchUnionTypeList = () => async (dispatch) => {

    await UnionTypeServices.getUnionType(true)
      .then(response => response.data)
      .then(resp => {        
        if (resp.success) {   
          const unionTypeData = resp.responseObject.value.unionTypesList;
          dispatch(unionTypeLoaded(unionTypeData));         
        }
        else {          
      }
      }).catch(error => {
      });
  }