import { roleCode  } from "../shared/utils/constants";

export const checkIfRoleHasRights =  (userRole, groupRoles) => {    
    let userHasRights = groupRoles.includes(userRole);    
    if(userHasRights){return true;}
    else {return false;}}

// Group 1 roles: Admin
// Rights: ( )
export const group1Rights = (curentUserData) => {    
    let groupRoles = ["Admin"]
    if (curentUserData) {        
       return checkIfRoleHasRights(curentUserData.roleCode, groupRoles);     
    }
}

export const isAdmin = (curentUserData) => {    
    let groupRoles = ["Admin"]
    if (curentUserData) {
        return checkIfRoleHasRights(curentUserData.roleCode, groupRoles);            
    }
}

export const isCat = (curentUserData) => {    
    let groupRoles = ["Cat"]
    if (curentUserData) {
        return checkIfRoleHasRights(curentUserData.roleCode, groupRoles);            
    }
}

export const isKm = (curentUserData) => {    
    let groupRoles = ["Km"]
    if (curentUserData) {
        return checkIfRoleHasRights(curentUserData.roleCode, groupRoles);            
    }
}

export const isIt = (curentUserData) => {    
    let groupRoles = ["It"]
    if (curentUserData) {
        return checkIfRoleHasRights(curentUserData.roleCode, groupRoles);            
    }
}

export const isUser = (curentUserData) => {    
    let groupRoles = ["User"]
    if (curentUserData) {
        return checkIfRoleHasRights(curentUserData.roleCode, groupRoles);            
    }
}

export const isSales = (curentUserData) => {    
    let groupRoles = ["Sales"]
    if (curentUserData) {
        return checkIfRoleHasRights(curentUserData.roleCode, groupRoles);            
    }
}


export const appaltoCodeRolesGroup = (roleCodeType) => {    
    let groupRoles = [roleCode.admin, roleCode.it, roleCode.sales]
    if (roleCodeType) {        
       return checkIfRoleHasRights(roleCodeType, groupRoles);     
    }
}