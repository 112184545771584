import deployment from '../deploymentConfigs';
import confDev from './configurations-dev.json';
import confTest from './configurations-test.json';
import confProd from './configurations-prod.json';

let configurations;
const clientUrl = deployment.client;

if(process.env.REACT_APP_ENV === "developmentenv" || process.env.REACT_APP_ENV === "development" ){
    configurations = confDev;
}

if(process.env.REACT_APP_ENV === "test"){
    configurations = confTest;
}

if(process.env.REACT_APP_ENV === "production"){
    configurations = confProd;
}

// Config object to be passed to Msal on creation
export const msalConfig = {
    auth: {
        clientId:  configurations.clientId,
        tenantId: configurations.tenantId,
        authority: configurations.authority,
        postLogoutRedirectUri: clientUrl,
        redirectUri: clientUrl,
    },
    cache: {
        cacheLocation: "localStorage",
        storeAuthStateInCookie: false
    },
    system: {
        allowNativeBroker: false, // Disables WAM Broker
    },
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest = {
    scopes: ["openid", "profile", "user.read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com/v1.0/me"
};