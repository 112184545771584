import React, { useEffect, useState } from 'react';
import { Card, Row, Col, Typography, Result, Layout, message, Spin, Space } from 'antd';
import { notifyMessages } from '../../../utils/errorMesages';
import { useParams } from 'react-router';
import WarrantyServices from '../../../../services/warrantyServices';
import { LoadingOutlined } from '@ant-design/icons';

const { Title } = Typography;
const { Content } = Layout;

const EmailConfirmationPage = () => {

    const urlParams = useParams();
    let customerID = urlParams.id;

    const [loadingConfirmation, setLoadingConfirmation] = useState(false);
    const [emailConfirmed, setEmailConfirmed] = useState(null);

    useEffect(() => {
        confirmEmail(customerID)
    }, []);

    const confirmationSuccess = () => {
        return (
            <Result
                status="success"
                title="Successfully Confirmed"
                subTitle="Email confirmed"
                extra={[
                ]}
            />
        )
    }

    const confirmationFailed = () => {
        return (
            <Result
                status="warning"
                title="Something went wrong"
                extra={[
                ]}
            />
        )
    }

    const confirmEmail = async (cusId) => {

        setLoadingConfirmation(true);
        await WarrantyServices.confirmCustomerEmail(cusId)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    setLoadingConfirmation(false);
                    setEmailConfirmed(confirmationSuccess);
                }
                else {
                    setLoadingConfirmation(false);
                    message.error(notifyMessages.errorAccured);
                    setEmailConfirmed(confirmationFailed);
                }
            }).catch(error => {
                setLoadingConfirmation(false);
                message.error(notifyMessages.errorAccured);
                setEmailConfirmed(confirmationFailed);
            });
    }

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );


    return (
        <Layout className='appLayout' style={{ height: "100%" }}>
            <Row gutter={{ lg: 24 }} align="middle">
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Card style={{ width: "100%" }}>
                        <Content className="site-layout-background" style={{ textAlign: "center" }} >
                            {loadingConfirmation && (
                                <Space direction='vertical'>
                                    <Spin indicator={antIcon} />
                                    <Title level={5}>Loading confirmation...</Title>
                                </Space>
                            )}
                            {emailConfirmed}
                        </Content>
                    </Card>
                </Col>
            </Row>
        </Layout>

    );
}
export default EmailConfirmationPage;