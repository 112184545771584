import { createSlice } from '@reduxjs/toolkit'
import GeneralServices from '../../services/generalServices';
import { useSelector } from 'react-redux';

const initialState = { 
  tecnicList: []
};



const  tecnicListSlice = createSlice({
    name: 'tecnicList',
    initialState,
    reducers: {
      tecnicListLoaded(state, action) {       
        state.tecnicList =  action.payload; 
      },
    },
  })

  export const { tecnicListLoaded } =  tecnicListSlice.actions;
  
  export default  tecnicListSlice.reducer;


  export const fetchTecnicList = (appaltoCode) => async (dispatch) => {
    await GeneralServices.getTecnicList(appaltoCode)
      .then(response => response.data)
      .then(resp => {        
        if (resp.success) {                    
          const rolesData = resp.responseObject.value.technicianList;
          dispatch(tecnicListLoaded(rolesData));          
        }
        else {                 
      }
      }).catch(error => {    
      });
  }