import { trackPromise } from 'react-promise-tracker';
import axiosInstance from '../configs/axiosConfigs/interceptors';
import { acquireToken, acquireToken2, acquireTokentest } from '../configs/authentication/MsGraphApiCall';


const GeneralServices = {
  
    getCurrentUserData: function () {       
        const data = acquireToken().then((tokenResponse) => {   
            const optionsVal = {
                method: 'GET',
                url: `General/GetUserDetails`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };            
            const apiResponse = trackPromise(axiosInstance(optionsVal));
            return apiResponse;
        });
        return data;
    },    

    getRolesList: function (isEnabled) {       
        const data = acquireToken().then((tokenResponse) => {  
            let queryParams = `?isEnabled=${isEnabled}` 
            const optionsVal = {
                method: 'GET',
                url: `Roles/GetRoles${queryParams}`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };            
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },  

    getTecnicList: function (appaltoCode) {       
        const data = acquireToken().then((tokenResponse) => {  
            let queryParams = `?AppaltoCode=${appaltoCode}` 
            const optionsVal = {
                method: 'GET',
                url: `Technician/GetTechnicianList${queryParams}`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };            
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },

    getAllTechnicsList: function (filters) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'GET',
                url: `Technician/GetAllTechniciansList`,
                params: filters,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },   

    getUserMenuList: function (roleId) {
        const data = acquireToken().then((tokenResponse) => {
            let queryParams = `?RoleID=${roleId}`
            const optionsVal = {
                method: 'GET',
                url: `Roles/GetRoleMenus${queryParams}`,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    }, 

};

export default GeneralServices;
