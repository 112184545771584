import { createSlice } from '@reduxjs/toolkit'
import GeneralServices from '../../services/generalServices';


const initialState = { 
  rolesList: []
};

const  rolesListSlice = createSlice({
    name: 'rolesList',
    initialState,
    reducers: {
      rolesListLoaded(state, action) {       
        state.rolesList =  action.payload; 
      },
    },
  })

  export const { rolesListLoaded } =  rolesListSlice.actions;
  
  export default  rolesListSlice.reducer;


  export const fetchRolesList = () => async (dispatch) => {
    await GeneralServices.getRolesList(true)
      .then(response => response.data)
      .then(resp => {        
        if (resp.success) {                    
          const rolesData = resp.responseObject.value.rolesList;
          dispatch(rolesListLoaded(rolesData));          
        }
        else {          
      }
      }).catch(error => {
      });
  }