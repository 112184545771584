
import React, { useEffect, useMemo, useState } from 'react';
import { Tabs, message } from 'antd';
import { useParams } from 'react-router';
import ClientDetails from './clientDetails';
import PageHeader from '../../../shared/components/pageHeader';
import ClientsServices from '../../../services/clientServices';
import { notifyMessages } from '../../../shared/utils/errorMesages';
import ClientWarranties from './clientWarranties';

const Client = () => {

    const urlParams = useParams();
    let clientId = urlParams.id;
    const [selectedClient, setSelectedClient] = useState(null);
    const [activeTab, setActiveTab] = useState('1');
    
    useEffect(() => {
        getClientDetails();
    }, []);

    const getClientDetails = async () => {     
        await ClientsServices.getClientById(clientId)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value;
                    setSelectedClient(respData.customerDetails);
                }
                else {
                    message.error(notifyMessages.errorAccured);
                }
            }).catch(error => {
                message.error(notifyMessages.errorAccured);
            });
    }

    const tableParams = useMemo(()=>{
        switch(activeTab){
            case "2":
                return {contractorId:clientId}
            case "3":
                return {beneficentID:clientId}
            default:
                console.log("Client.params: Case not managed")
                return {};
        }}
    ,[activeTab])

    const tabsItems = useMemo(()=>[
        {
            key: '1',
            label: "Dettagli",
            children: <ClientDetails clientId={clientId} selectedClient={selectedClient} />,
        },
        {
            key: '2',
            label: "Garanzie come Contraente",
            children:  <ClientWarranties isActive={activeTab==="2"} tableParams={tableParams}  />,
        },
        {
            key: '3',
            label: "Garanzie come Beneficiario",
            children: <ClientWarranties isActive={activeTab==="3"} tableParams={tableParams} />,
        }
    ],[clientId,selectedClient,tableParams,activeTab]);


    const handleTabChange = (key) => {
        setActiveTab(key);
      }


      return (
        <div className='clientsWrappers'>
            <PageHeader title="Dettagli Cliente" />
            <Tabs defaultActiveKey="1" onChange={handleTabChange} items={tabsItems} />
        </div>
    );
    
}
export default Client;