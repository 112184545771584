import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Select, message, Button, Alert } from 'antd';
import { notifyMessages, requiredMessages } from '../../shared/utils/errorMesages';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import UserServices from '../../services/usersServices';
import { ArrowLeftOutlined, SaveOutlined } from '@ant-design/icons';
import PageHeader from '../../shared/components/pageHeader';
import { appaltoCodeRolesGroup } from '../../configs/authRoleFunctions';
import { getAppaltoCode } from '../../shared/functions/sharedFunctions';


const NewUser = () => {

    const [form] = Form.useForm();
    const [fieldChanged, setFieldChanged] = useState(false);
    const [loadingSave, setLoadingSave] = useState(false);
    const [disabledAppaltoCode, setDisabledAppaltoCode] = useState(false);
    const navigate = useNavigate();
    const [customApiError, setCustomApiError] = useState({});

    const roleList = useSelector(state => state.rolesList.rolesList);

    const rolesPicklist = roleList.reduce((res, item) => {
        res.push({ value: item.id, label: item.name });
        return res;
    }, []);


    useEffect(() => {
    }, []);


    const roleSelectAppaltoCode = async (roleid) => {
        let appaltoCodeObj = await getAppaltoCode(roleid);

        if (appaltoCodeObj) {
            if (appaltoCodeObj.appaltoCode !== 0) {
                setDisabledAppaltoCode(true);
                form.setFieldsValue({
                    appaltoCode: appaltoCodeObj.appaltoCode,
                });
            } else {
                setDisabledAppaltoCode(false);
                form.setFieldsValue({
                    appaltoCode: null,
                });
            }
        }
        else {
            message.error(notifyMessages.errorAccured);
        }
    }

    const onRoleSelect = (option) => {
        const selectedRole = roleList.find((role) => role.id === option);      
        if (selectedRole) {
            let hasRights = appaltoCodeRolesGroup(selectedRole.roleCode);
            if (hasRights) {
                roleSelectAppaltoCode(selectedRole.id);
            }
            else {
                setDisabledAppaltoCode(false);
                form.setFieldsValue({
                    appaltoCode: null,
                });
            }
        }
    }

    const createUserData = async (values) => {      
        setLoadingSave(true);
        await UserServices.createUser(values)
            .then(response => response.data)
            .then(resp => {                
                if (resp.success) {                    
                    setLoadingSave(false);
                    message.success(notifyMessages.addSuccess);
                    navigate("/users");
                }
                else {
                    setLoadingSave(false);
                    message.error(notifyMessages.errorAccured);                    
                    if(resp.errorDetail && resp.errorDetail.errorMessage &&  resp.errorDetail.errorMessage !== ""){
                        setCustomApiError(resp.errorDetail);
                    }                    
                }
            }).catch(error => {
                setLoadingSave(false);
                message.error(notifyMessages.errorAccured);                
            });
    }

    const handleAppaltoCodeBlur = (e) => {
        let input = e.target.value;

        input = input.padStart(5, '0');
        form.setFieldsValue({
            appaltoCode: input,
        });
    };

    const handleAppaltoCodeInput = (e) => {
        let input = e.target.value;
        if (input.length > 5) {
            input = input.slice(0, 5);
        }
        e.target.value = input;
    };


    return (
        <>
            <PageHeader title="Crea nuovo utente" />

            <Row gutter={{ lg: 24 }}>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>

                    <Form
                        id='newUser'
                        layout="vertical"
                        form={form}
                        onFinish={createUserData}
                        autoComplete="off"
                        onValuesChange={() => {
                            setFieldChanged(true)
                        }}
                    >

                        <Row gutter={{ lg: 24 }}>
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Nome"
                                    name="name"
                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                >
                                    <Input placeholder="Nome" />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Cognome"
                                    name="surname"
                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                >
                                    <Input placeholder="Cognome" />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Ragione Sociale"
                                    name="username"
                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                >
                                    <Input placeholder="Username" />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Email"
                                    name="email"
                                    rules={[{
                                        required: true, message: requiredMessages.requiredField,
                                        type: "email", message: 'E-mail non valido'
                                    }]}
                                >
                                    <Input placeholder="Email" />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Role"
                                    name="roleID"
                                >
                                    <Select
                                        placeholder="Ruolo"
                                        allowClear={false}
                                        options={rolesPicklist}
                                        onChange={onRoleSelect}
                                    />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Form.Item
                                    label="Codice Appalto"
                                    name="appaltoCode"
                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                >
                                    <Input placeholder="Codice Appalto" type="number" onInput={handleAppaltoCodeInput} onBlur={handleAppaltoCodeBlur} disabled={disabledAppaltoCode} />
                                </Form.Item>
                            </Col>

                            <Col className="gutter-row justifySpaceBetween" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Button size='small' icon={<ArrowLeftOutlined />} onClick={() => { navigate("/users"); }}> Indietro </Button>

                                <Form.Item>
                                    <Button type='primary' disabled={!fieldChanged} loading={loadingSave} htmlType='submit' icon={<SaveOutlined />}> Salva </Button>
                                </Form.Item>
                            </Col>

                        </Row>
                    </Form>

                </Col>
            </Row>


            {(customApiError && customApiError.errorMessage && customApiError.errorMessage !== "") && (
                <Row gutter={{ lg: 24 }}>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                        <Alert
                            message={customApiError.errorMessage}
                            type="error"
                            closable
                            showIcon
                            onClose={() => setCustomApiError({})}
                        />
                    </Col>
                </Row>
            )}

        </>
    );
}
export default NewUser;
