import { trackPromise } from 'react-promise-tracker';
import axiosInstance from '../configs/axiosConfigs/interceptors';
import { acquireToken } from '../configs/authentication/MsGraphApiCall';


const UserServices = {
  
    getUsersList: function (filters) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'GET',
                url: `User/GetUserList`,
                params: filters,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },   

    updateUser: function (payload) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'PUT',
                url: `User/UpdateUser`,
                data: payload, 
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    },   


    getUserById: function (userId) {
        const data = acquireToken().then((tokenResponse) => {
            let queryParams = `?UserId=${userId}`
            const optionsVal = {
                method: 'GET',
                url: `User/GetUserByID${queryParams}`,              
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    }, 

    createUser: function (payload) {
        const data = acquireToken().then((tokenResponse) => {
            const optionsVal = {
                method: 'POST',
                url: `User/AddUser`,
                data: payload,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    }, 

    getInternalApplatoCode: function (roleID) {
        const data = acquireToken().then((tokenResponse) => {
            let queryParams = `?roleID=${roleID}` 
            const optionsVal = {
                method: 'GET',
                url: `User/GetInternalAppaltoCode${queryParams}`, 
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    }, 


};

export default UserServices;
