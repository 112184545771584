
import { Button, Col, Collapse, Input, Row, Form, Switch } from 'antd';
import React from 'react';


const ClientsFilters = ({ onFilterData, onResetData, filterValues, filtersInitValues }) => {

    const [form] = Form.useForm();

    const onClearFilter = () => {
        form.setFieldsValue(filtersInitValues);
        onResetData();
    }

    const onFilter = (values) => {
        onFilterData(values);
    };

    const FilterContent = () => {
        return (
            <div className='clientFilters'>
                <Row gutter={[16, 16]} className='rowmb0'>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>

                        <Form
                            form={form}
                            name="filters"
                            layout="vertical"
                            onFinish={onFilter}
                            autoComplete="off"
                        >

                            <Row gutter={{ lg: 24 }} className='rowmb0'>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Form.Item
                                        label="Nome"
                                        name="name"
                                        initialValue={filterValues.name}
                                    >
                                        <Input placeholder="Nome" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Form.Item
                                        label="Cognome"
                                        name="surname"
                                        initialValue={filterValues.surname}
                                    >
                                        <Input placeholder="Cognome" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Form.Item
                                        label="Codice Fiscale"
                                        name="fiscalCode"
                                        initialValue={filterValues.fiscalCode}
                                    >
                                        <Input placeholder="Codice Fiscale" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        initialValue={filterValues.email}
                                    >
                                        <Input placeholder="Email" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Form.Item
                                        label="Codice Cliente"
                                        name="customerCode"
                                        initialValue={filterValues.customerCode}
                                    >
                                        <Input placeholder="Codice Cliente" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Form.Item
                                        label="Regione"
                                        name="region"
                                        initialValue={filterValues.region}
                                    >
                                        <Input placeholder="Regione" />
                                    </Form.Item>
                                </Col>


                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Form.Item
                                        label="Abilitato"
                                        name="enabled"
                                        valuePropName="checked"
                                        initialValue={filterValues.enabled}
                                    >
                                        <Switch />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row filterButtons" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <Form.Item>
                                        <Button className='mr10' type="primary" htmlType="submit">Filtra</Button>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button onClick={() => onClearFilter()}>Rimuovi</Button>
                                    </Form.Item>

                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <>
            <Collapse
                size='small'
                items={[
                    {
                        key: '1',
                        label: 'Filtri',
                        children: <FilterContent />
                    },
                ]}
            />
        </>
    );
}
export default ClientsFilters;