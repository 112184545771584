import { Button, Col, Collapse, Input, Row, Form, Switch, Select } from 'antd';
import React from 'react';
import { useSelector } from 'react-redux';


const UsersFilters = ({ onFilterData, onResetData, filterValues, filtersInitValues }) => {

    const [form] = Form.useForm();
    const roleList = useSelector(state => state.rolesList.rolesList);

    const rolesPicklist = roleList.reduce((res, item) => {
        res.push({ value: item.id, label: item.name });
        return res;
    }, []);


    const onClearFilter = () => {
        form.setFieldsValue(filtersInitValues);
        onResetData();
    }

    const onFilter = (values) => {
        onFilterData(values);
    };

    const FilterContent = () => {
        return (
            <div className='userFilters'>
                <Row gutter={[16, 16]} className='rowmb0'>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>

                        <Form
                            form={form}
                            name="filters"
                            layout="vertical"
                            onFinish={onFilter}
                            autoComplete="off"
                        >

                            <Row gutter={[16, 16]} className='rowmb0'>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Nome"
                                        name="name"
                                        initialValue={filterValues.name}
                                    >
                                        <Input placeholder="Nome" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Cognome"
                                        name="surname"
                                        initialValue={filterValues.surname}
                                    >
                                        <Input placeholder="Cognome" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        initialValue={filterValues.email}
                                    >
                                        <Input placeholder="Email" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Codice Appalto"
                                        name="appaltoCode"
                                        initialValue={filterValues.appaltoCode}
                                    >
                                        <Input placeholder="Codice Appalto" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Ruolo"
                                        name="roleID"
                                        initialValue={filterValues.roleID}
                                    >
                                        <Select
                                            placeholder="Ruolo"
                                            allowClear={true}
                                            options={rolesPicklist}
                                        />
                                    </Form.Item>
                                </Col>
                                
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 3 }}>
                                    <Form.Item
                                        label="Abilitato"
                                        name="enabled"
                                        valuePropName="checked"
                                        initialValue={filterValues.enabled}
                                    >
                                        <Switch />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row filterButtons" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <Form.Item>
                                        <Button className='mr10' type="primary" htmlType="submit">Filtra</Button>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button onClick={() => onClearFilter()}>Rimuovi</Button>
                                    </Form.Item>

                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <>
            <Collapse
                size='small'
                items={[
                    {
                        key: '1',
                        label: 'Filtri',
                        children: <FilterContent />
                    },
                ]}
            />
        </>
    );
}
export default UsersFilters;