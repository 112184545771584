import { Button, Col, Collapse, Input, Row, Form, Switch, Select, DatePicker, message } from 'antd';
import React, { useEffect, useState } from 'react';
import { notifyMessages } from '../../shared/utils/errorMesages';
import { useSelector } from 'react-redux';
import { dateFormat } from '../../shared/utils/constants';
import { handleDateConvertDayJs } from '../../shared/functions/sharedFunctions';
import PromotionServices from '../../services/promotionServices';
import { tablePageSize } from '../../shared/utils/constants';
import { useParams } from 'react-router';
import { isCat } from '../../configs/authRoleFunctions';

const WarrantyFilters = ({ onFilterData, onResetData, filterValues, filtersInitValues }) => {

    const [form] = Form.useForm();
    const { RangePicker } = DatePicker;
    const warrantyStatusList = useSelector(state => state.warrantyStatusList.warrantyStatusList);
    const warrantyTypeList = useSelector(state => state.warrantyTypeList.warrantyTypeList);
    const [progresiveCodesList, setProgresiveCodesList] = useState(null);
    const [beneficentPrivacy, setBeneficentPrivacy] = useState(filterValues.beneficentPrivacy);
    const [sentC4C, setSentC4C] = useState(filterValues.sentC4C);
    const currentUser = useSelector(state => state.currentUser.loggedinUserData);

    const warrantyTypePicklist = warrantyTypeList.reduce((res, item) => {
        res.push({ value: item.id, label: item.name });
        return res;
    }, []);

    const defaultWarrantyStatusList = warrantyStatusList.filter((obj) => {
        return obj.name != "Nuovo";
    })

    const warrantyStatusPicklist = defaultWarrantyStatusList.reduce((res, item) => {
        res.push({ value: item.id, label: item.name });
        return res;
    }, []);

    const getProgressiveCodes = async () => {
        await PromotionServices.getProgressiveCodes("", 0, 15, "", false)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value;
                    setProgresiveCodesList(respData.progressiveCodesList);
                    const promotionData = resp.responseObject.value.progressiveCodesList;
                    const progresiveCodesPickList = promotionData.reduce((res, item) => {
                        res.push({ value: item.id, label: item.finalCode });
                        return res;
                    }, []);

                    setProgresiveCodesList(progresiveCodesPickList)
                }
                else {
                    message.error(notifyMessages.errorAccured);
                }
            }).catch(error => {
                message.error(notifyMessages.errorAccured);
            });
    }

    useEffect(() => {
        getProgressiveCodes();
    }, []);


    const onClearFilter = () => {
        form.setFieldsValue(filtersInitValues);
        onResetData();
    }

    const onBeneficentPrivacyChange = (checked) => {
        setBeneficentPrivacy(checked);
    };

    const onSentC4CChange = (checked) => {
        setSentC4C(checked);
    };

    const onFilter = (values) => {  
        if(values.purchaseStartDate){
            let purchaseDatePicker = values.purchaseStartDate;
            values["purchaseStartDate"] = handleDateConvertDayJs(purchaseDatePicker[0].$d);
            values["purchaseEndDate"] = handleDateConvertDayJs(purchaseDatePicker[1].$d);
        }
        if(values.creationStartDate){
            let creationDatePicker = values.creationStartDate;
            values["creationStartDate"] = handleDateConvertDayJs(creationDatePicker[0].$d);
            values["creationEndDate"] = handleDateConvertDayJs(creationDatePicker[1].$d);
        }
        if(values.warrantyStartDate){
            let warrantyDatePicker = values.warrantyStartDate;
            values["warrantyStartDate"] = handleDateConvertDayJs(warrantyDatePicker[0].$d);
            values["warrantyEndDate"] = handleDateConvertDayJs(warrantyDatePicker[1].$d);
        }
        onFilterData(values);
    };

    const FilterContent = () => {
        return (
            <div className='warrantyFilters'>
                <Row gutter={[16, 16]} className='rowmb0'>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>

                        <Form
                            form={form}
                            name="filters"
                            layout="vertical"
                            onFinish={onFilter}
                            autoComplete="off"
                        >

                            <Row gutter={{ lg: 24 }} className='rowmb0'>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <Form.Item
                                        label="Stato"
                                        name="statusID"
                                        initialValue={filterValues.statusID}
                                    >
                                        <Select
                                            placeholder="Stato"
                                            allowClear={true}
                                            options={warrantyStatusPicklist}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <Form.Item
                                        label="Codice Appalto"
                                        name="appaltoCode"
                                        initialValue={isCat(currentUser) ? currentUser.appaltoCode : null}
                                    >
                                        <Input placeholder="Appalto Code" disabled={isCat(currentUser)} />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <Form.Item
                                        label="Ragione Sociale"
                                        name="companyName"
                                        initialValue={filterValues.companyName}
                                    >
                                        <Input placeholder="Ragione Sociale" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <Form.Item
                                        label="Tipo garanzia"
                                        name="warrantyTypeID"
                                        initialValue={filterValues.warrantyTypeID}
                                    >
                                        <Select
                                            placeholder="Tipo garanzia"
                                            allowClear={true}
                                            options={warrantyTypePicklist}
                                        />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Promozione"
                                        name="progressiveCodeID"
                                        initialValue={filterValues.progressiveCodeID}
                                    >
                                        <Select
                                            showSearch
                                            placeholder="Cerca una promozione"
                                            allowClear
                                            optionFilterProp="children"
                                            filterOption={(input, option) =>
                                                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                            }
                                            options={progresiveCodesList}
                                        >
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={{ lg: 24 }} className='rowmb0'>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Form.Item
                                        label="Data Acquisto"
                                        name="purchaseStartDate"
                                    >
                                        <RangePicker style={{ width: "100%" }} format={dateFormat} allowClear />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Form.Item
                                        label="Data Creazione"
                                        name="creationStartDate"
                                    >
                                        <RangePicker style={{ width: "100%" }} format={dateFormat} allowClear />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                    <Form.Item
                                        label="Data Adesione"
                                        name="warrantyStartDate"
                                    >
                                        <RangePicker style={{ width: "100%" }} format={dateFormat} allowClear />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <Form.Item
                                        label="Contraente"
                                        name="contractorName"
                                        initialValue={filterValues.contractorName}
                                    >
                                        <Input placeholder="Contraente" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Form.Item
                                        label="Numero di Protocollo"
                                        name="protocolNumber"
                                        initialValue={filterValues.protocolNumber}
                                    >
                                        <Input placeholder="Numero di Protocollo" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 5 }} lg={{ span: 5 }}>
                                    <Form.Item
                                        label="Codice Adesione"
                                        name="confirmationCode"
                                        initialValue={filterValues.confirmationCode}
                                    >
                                        <Input placeholder="Codice Adesione" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Privacy Beneficiario"
                                        name="beneficentPrivacy"
                                        valuePropName="checked"
                                        initialValue={filterValues.beneficentPrivacy}
                                    >
                                        <Switch onChange={onBeneficentPrivacyChange} />
                                    </Form.Item>
                                </Col>


                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Inviato a C4C"
                                        name="sentC4C"
                                        valuePropName="checked"
                                        initialValue={filterValues.sentC4C}
                                    >
                                        <Switch onChange={onSentC4CChange} />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row filterButtons" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <Form.Item>
                                        <Button className='mr10' type="primary" htmlType="submit">Filtra</Button>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button onClick={() => onClearFilter()}>Rimuovi</Button>
                                    </Form.Item>

                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <>
            <Collapse
                size='small'
                items={[
                    {
                        key: '1',
                        label: 'Filtri',
                        children: <FilterContent />
                    },
                ]}
            />
        </>
    );
}
export default WarrantyFilters;