import { createSlice } from '@reduxjs/toolkit'
import UnionTypeServices from '../../services/unionTypeServices';
import PromotionServices from '../../services/promotionServices';


const initialState = { 
    promotionList: []
};

const  PromotionSlice = createSlice({
    name: 'Promotion',
    initialState,
    reducers: {
        promotionLoaded(state, action) {       
          state.promotionList =  action.payload; 
      },
    },
  })

  export const { promotionLoaded } =  PromotionSlice.actions;
  
  export default  PromotionSlice.reducer;


  export const fetchPromotionList = (selectedDate) => async (dispatch) => {

    await PromotionServices.getNonActivePromotion(selectedDate)
      .then(response => response.data)
      .then(resp => {        
        if (resp.success) {   
          const promotionData = resp.responseObject.value.progressiveCodesList;
          dispatch(promotionLoaded(promotionData));         
        }
        else {          
      }
      }).catch(error => {
      });
  }