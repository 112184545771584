import { trackPromise } from 'react-promise-tracker';
import { acquireToken } from '../configs/authentication/MsGraphApiCall';
import axiosInstance from '../configs/axiosConfigs/interceptors';


const UnionTypeServices = {
  
    getUnionType: function () {
        const data = acquireToken().then((tokenResponse) => {        
            const optionsVal = {
                method: 'GET',
                url: `UnionType/GetUnionTypes`,                        
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    }, 


};

export default UnionTypeServices;
