import React, { useEffect, useState } from 'react';
import { Tabs, message, Button, Table, Form, Input, Row, Col, Divider, Typography, Space } from 'antd';
import PromotionDetails from './promotionDetails';
import PromotionServices from '../../../services/promotionServices';
import { notifyMessages } from '../../../shared/utils/errorMesages';
import { useParams } from 'react-router';
import { CheckCircleOutlined, CloseCircleOutlined, ExportOutlined } from '@ant-design/icons';
import SimpleLoader from '../../../shared/components/simpleLoader/simpleLoader';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { dateFormat, excelfileExtension, excelfileType, tablePageSize } from '../../../shared/utils/constants';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { isCat } from '../../../configs/authRoleFunctions';

const { Title, Text } = Typography;
const { Search } = Input;

const Promotion = () => {

    const { id } = useParams();
    const [selectedPromotion, setSelectedPromotion] = useState(null);
    const [numberOfPromotions, setNumberOfPromotions] = useState(null);
    const [progressiveCodes, setProgressiveCodes] = useState([]);
    const [searchValue, setSearchValue] = useState('');
    const [loadingProgresiveCodesList, setLoadingProgresiveCodesList] = useState(false);
    const [loadingGenerateProgresiveCodes, setLoadingGenerateProgresiveCodes] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [sorterColumn, setSorterColumn] = useState("");
    const [sorterType, setSorterType] = useState(true);
    const [totalCountRecords, setTotalCountRecords] = useState(null);
    const [loadingExport, setLoadingExport] = useState(false);
    const currentUser = useSelector(state => state.currentUser.loggedinUserData);

    useEffect(() => {
        getPromotionDetails();
        if (progressiveCodes.length === 0) {
            getProgressiveCodes(false);
        }
    }, [id]);

    useEffect(() => {
        getProgressiveCodes(false);
    }, [pageNumber, sorterColumn, sorterType]);

    const getPromotionDetails = async () => {
        try {
            const response = await PromotionServices.getPromotionById(id);
            const resp = response.data;
            if (resp.success) {
                const respData = resp.responseObject.value;
                setSelectedPromotion(respData.promotionDetails);
                setNumberOfPromotions(respData.promotionDetails.totalProgressiveCodes);
            } else {
                message.error(notifyMessages.errorAccured);
            }
        } catch (error) {
            message.error(notifyMessages.errorAccured);
        }
    };

    const generateProgressiveCodes = async () => {
        if (numberOfPromotions < selectedPromotion.totalProgressiveCodes) {
            message.error("Numero di promozioni da generare puo essere solo maggiore = al valore precedente");
            return;
        }

        try {
            setLoadingGenerateProgresiveCodes(true);
            const apiResponse = await PromotionServices.generateProgressiveCodes(id, numberOfPromotions)
            if (apiResponse.data.success) {
                setLoadingGenerateProgresiveCodes(false);
                message.success(notifyMessages.addSuccess);
                getProgressiveCodes(false);
                const updatedPromotion = { ...selectedPromotion };
                updatedPromotion.totalProgressiveCodes = numberOfPromotions;
                setSelectedPromotion(updatedPromotion);

            } else {
                message.error(notifyMessages.errorAccured);
                setLoadingGenerateProgresiveCodes(false);
            }
        } catch (error) {
            message.error(notifyMessages.errorAccured);
            setLoadingGenerateProgresiveCodes(false);
        }
    };

    const onPageSorterChange = (page, filters, sorter) => {
        if (page) {
            if (sorter && sorter.field) {
                setSorterColumn(sorter.field);
                setPageNumber(page.current);

                if (sorter.order === "descend") {
                    setSorterType(false);
                }
                if (sorter.order === "ascend") {
                    setSorterType(true);
                }
            }
            else {
                setPageNumber(page.current);
                setSorterColumn("");
                setSorterType(true);
            }
        }
    };

    const getProgressiveCodes = async (isExport) => {

        setLoadingProgresiveCodesList(isExport ? false : true);
        setLoadingExport(isExport ? true : false);
        try {
            let pgNumber = isExport ? 0 : pageNumber;

            const resp = await PromotionServices.getProgressiveCodes(id, pgNumber, tablePageSize, sorterColumn, sorterType);
            const generatedCodes = resp.data.responseObject.value.progressiveCodesList;

            if (isExport) {
                generateExcelFile(generatedCodes);
            }
            else {
                setProgressiveCodes(generatedCodes);
                setLoadingProgresiveCodesList(false);
                let record = generatedCodes.find(function (element) {
                    return element.recordNo != null;
                });
                setTotalCountRecords(record ? record.recordNo : "")
            }


        } catch (error) {
            message.error(notifyMessages.errorAccured);
            setLoadingProgresiveCodesList(false);
            setLoadingExport(false);
        }
    };

    const exportProgressiveCodes = async () => {
        getProgressiveCodes(true);
    }


    const generateExcelFile = async (data) => {
        try {
            const progressiveCodesData = data;

            if (progressiveCodesData.length > 0) {
                const formatedData = progressiveCodesData.map((item) => ({
                    progressiveCode: item.finalCode,
                    sentC4C: item.sentC4C ? 'Sì' : 'No',
                    usedInWarranties: item.usedInWarranties ? 'Sì' : 'No',
                }));

                const fileName = `ESPORTAZIONE-CODICI-PROGRESSIVI-${moment().format(dateFormat)}`;
                let headers = [['Progressive Code', 'Inviato a C4C', 'Utilizzato nelle garanzie']];
                const ws = XLSX.utils.json_to_sheet([]);
                XLSX.utils.sheet_add_json(ws, formatedData, { origin: 'A2', skipHeader: true });
                XLSX.utils.sheet_add_aoa(ws, headers);
                const wb = { Sheets: { 'Esportazione Codici Progressivi': ws }, SheetNames: ['Esportazione Codici Progressivi'] };

                let wsColsWidthFormatter = [
                    { wpx: 120 }, // column width
                    { wpx: 100 }, // column width
                    { wpx: 120 }, // column width
                    { wpx: 100 }, // column width
                ];
                ws['!cols'] = wsColsWidthFormatter;

                const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
                const data = new Blob([excelBuffer], { type: excelfileType });
                FileSaver.saveAs(data, fileName + excelfileExtension);
                setLoadingExport(false);
            } else {
                message.warning(notifyMessages.noDataFound);
                setLoadingExport(false);
            }
        } catch (error) {
            message.error(notifyMessages.errorAccured);
            setLoadingExport(false);
        }
    };

    const handleSearchInputChange = (value) => {
        setSearchValue(value);
    };

    const filteredProgressiveCodes = progressiveCodes.filter((code) =>
        code.finalCode.includes(searchValue)
    );

    const columns = [
        {
            title: (
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <span>Progressive Code</span>
                    <div style={{ marginLeft: 'auto', paddingRight: '8px' }}>
                        <Search
                            placeholder="Cerca Codici Progressivi"
                            onSearch={handleSearchInputChange}
                            style={{ width: '300px' }}
                            allowClear
                        />
                    </div>
                </div>
            ),
            dataIndex: 'finalCode',
            width: '70%'
        },
        {
            title: 'Inviato a C4C',
            dataIndex: 'sentC4C',
            align: 'center',
            sorter: (a, b) => {
                a = a.sentC4C ? 1 : 0;
                b = b.sentC4C ? 1 : 0;
                return a - b;
            },
            render: (sentC4C) => (
                sentC4C ? <CheckCircleOutlined style={{ color: "#4da93d" }} /> : <CloseCircleOutlined style={{ color: "#777876" }} />
            ),
            showSorterTooltip: [false],
        },
        {
            title: 'Garanzia usata',
            dataIndex: 'warrantyID',  
            align: 'center',       
            showSorterTooltip: [false],
            render: (text, record) => {
                if (record.warrantyID) {
                    return (
                        <Link to={{ pathname: `/warranty/id/${record.warrantyID}` }}>Garanzie Correlata</Link>
                    )
                }
                else {
                    return (
                        <Text></Text>
                    )
                }
            }
        },
        {
            title: 'Utilizzato nelle garanzie',
            dataIndex: 'usedInWarranties',
            align: 'center',
            sorter: (a, b) => {
                a = a.usedInWarranties ? 1 : 0;
                b = b.usedInWarranties ? 1 : 0;
                return a - b;
            },
            render: (usedInWarranties) => (
                usedInWarranties ? <CheckCircleOutlined style={{ color: "#4da93d" }} /> : <CloseCircleOutlined style={{ color: "#777876" }} />
            ),
            showSorterTooltip: [false],
        },
    ];

    const tabItems = [
        {
            key: '1',
            label: 'Dettagli',
            children:
                <>
                    {!selectedPromotion && (
                        <SimpleLoader />
                    )}

                    {selectedPromotion && (
                        <>
                            <Row gutter={[16, 16]}>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <PromotionDetails promotionId={id} selectedPromotion={selectedPromotion} />
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <Divider orientation="left">Genera Codici Progressivi</Divider>
                                    <Form layout="inline">
                                        <Form.Item label="Numero di promozioni da generare">
                                            <Input
                                                type="number"
                                                value={numberOfPromotions}
                                                onChange={(e) => setNumberOfPromotions(parseInt(e.target.value))}
                                                disabled={isCat(currentUser)}
                                            />
                                        </Form.Item>
                                        {!isCat(currentUser) && (
                                            <Button style={{ position: 'absolute', right: '10px' }} loading={loadingGenerateProgresiveCodes} onClick={generateProgressiveCodes} type="primary" htmlType="submit">Genera Codici Progressivi</Button>
                                        )}
                                    </Form>
                                    <Divider />
                                </Col>
                            </Row>

                            <Row gutter={[16, 16]}>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 42 }}>
                                    <Divider orientation="left">Lista Codici Progressivi</Divider>
                                    <Table
                                        size='small'
                                        dataSource={filteredProgressiveCodes}
                                        columns={columns}
                                        loading={loadingProgresiveCodesList}
                                        rowKey={(obj) => obj.finalCode}
                                        pagination={{
                                            pageSize: tablePageSize,
                                            showSizeChanger: false,
                                            total: totalCountRecords ? totalCountRecords : "",
                                            current: pageNumber
                                        }}
                                        onChange={(pagination, filters, sorter) => onPageSorterChange(pagination, filters, sorter)}
                                    />

                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <Button loading={loadingExport} onClick={() => exportProgressiveCodes()} type="dashed" icon={<ExportOutlined />}>Esporta Codici Progressivi</Button>
                                </Col>
                            </Row>
                        </>
                    )}
                </>
        },
    ];


    return (
        <div className='promotionsWrapper'>
            <Row className='pageHeader'>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                    <Space direction='horizontal'>
                        <Title className='pageTitle' level={4}>Dettagli Promozioni: </Title >
                        <Title className='pageTitle' level={3} style={{ color: "#074791" }}>{selectedPromotion ? selectedPromotion.promotionalCode : ""}</Title>
                    </Space>
                </Col>
            </Row>
            <Tabs defaultActiveKey="1" items={tabItems} ></Tabs>
        </div>
    );
};

export default Promotion;
