
import React, { useEffect, useState } from 'react';
import { Row, Col, Typography, Space, Divider, Card, Select, Form, Input, Switch, DatePicker, message, Button, Table, Tooltip } from 'antd';
import { useSelector } from 'react-redux';
import { dateFormat, warrantyDuration } from '../../../shared/utils/constants';
import { ArrowLeftOutlined, SaveOutlined, SendOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { notifyMessages, requiredMessages, warrantiesInfoMessages } from '../../../shared/utils/errorMesages';
import dayjs from 'dayjs';
import WarrantyServices from '../../../services/warrantyServices'
import { handleDateConvertDayJs } from '../../../shared/functions/sharedFunctions';

const { Text } = Typography;


const WarrantyDetails = ({ warrantyId, selectedWarranty }) => {

    const [form] = Form.useForm();
    const warrantyStatusList = useSelector(state => state.warrantyStatusList.warrantyStatusList);
    const warrantyTypeList = useSelector(state => state.warrantyTypeList.warrantyTypeList);
    const unionTypeList = useSelector(state => state.unionTypeList.unionTypeList);
    const nonActivePromotionsList = useSelector(state => state.promotionList.promotionList);
    const tecnicsList = useSelector(state => state.tecnicList.tecnicList);
    const paymentTypeList = useSelector(state => state.paymentTypeList.paymentTypeList);

    const [fieldChanged, setFieldChanged] = useState(false);
    const navigate = useNavigate();
    const [loadingSave, setLoadingSave] = useState(false);
    const [isEmailDisabled, setIsEmailDisabled] = useState(false);
    const [contraenteIsBeneficiary, setContraenteIsBeneficiary] = useState(false);
    const [resendEmailBtn, setResendEmailBtn] = useState(false);
    const [isEmailChanged, setEmailChanged] = useState(false);
    const [isSalvaClicked, setSalvaClicked] = useState(false);
    const [statoGaranziaValue, setStatoGaranziaValue] = useState(selectedWarranty.warrantyStatusCode);
    const emailVerificatoStatus = warrantyStatusList.find(status => status.processOrder === 3);
    const emailNonValidoStatus = warrantyStatusList.filter(status => status.processOrder === 4);

    useEffect(() => {
        setStatoGaranziaValue(selectedWarranty.warrantyStatusCode);
    }, [selectedWarranty.warrantyStatusCode]);

    useEffect(() => {
        if (emailVerificatoStatus && statoGaranziaValue === emailVerificatoStatus.id) {
            setIsEmailDisabled(true);
        }
    }, [emailVerificatoStatus, statoGaranziaValue]);

   

    useEffect(() => {
        if (statoGaranziaValue === emailNonValidoStatus[0].id) {
            if (isEmailChanged && isSalvaClicked) {
                setResendEmailBtn(true);
            } else {
                setResendEmailBtn(false);
            }
        }
    }, [emailNonValidoStatus, isEmailChanged, isSalvaClicked, selectedWarranty]);

    const tecnicsPicklist = tecnicsList.reduce((res, item) => {
        res.push({ value: item.id, label: (item.name + " " + item.surname) });
        return res;

    }, []);

    const warrantyTypePicklist = warrantyTypeList.reduce((res, item) => {
        res.push({ value: item.id, label: item.name });
        return res;
    }, []);

    const warrantyStatusPicklist = warrantyStatusList.reduce((res, item) => {
        res.push({ value: item.id, label: item.name });
        return res;
    }, []);

    const nonActivePromotionsPicklist = nonActivePromotionsList.reduce((res, item) => {
        res.push({ value: item.id, label: item.progressiveCode });
        return res;
    }, []);

    const unionTypePicklist = unionTypeList.reduce((res, item) => {
        res.push({ value: item.id, label: item.type });
        return res;
    }, []);

    const paymentTypePicklist = paymentTypeList.reduce((res, item) => {
        res.push({ value: item.id, label: item.name });
        return res;
    }, []);

    const filteredWarrantyStatuses = warrantyStatusList.filter(status => status.processOrder === 2 || status.processOrder === 4);

    const productColumns = [
        {
            title: 'PNC',
            dataIndex: 'pnc',
            render: (text) => <a>{text}</a>,
        },
        {
            title: 'Marchio',
            dataIndex: 'brandName',
        },
        {
            title: 'Descrizione',
            dataIndex: 'description',
        },
        {
            title: 'Numero seriale',
            dataIndex: 'serialNumber',
        },
        {
            title: 'Settore',
            dataIndex: 'sectorCode',
        },
    ];

    const updateWarrantyData = async (values) => {
        setLoadingSave(true);
        await WarrantyServices.updateWarranty(values)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    setLoadingSave(true);
                    message.success(notifyMessages.updateSuccess);
                    setSalvaClicked(true);
                    if (statoGaranziaValue !== emailNonValidoStatus[0].id) {
                        navigate("/warranties");
                    } else if(statoGaranziaValue === emailNonValidoStatus[0].id && !isEmailChanged) {
                        navigate("/warranties");
                    }
                }
                else {
                    setLoadingSave(false);
                    message.error(notifyMessages.errorAccured);
                }
            }).catch(error => {
                setLoadingSave(false);
                message.error(notifyMessages.errorAccured);
            }).finally(() => {
                setLoadingSave(false);
            });
    }

    const resendConfirmationEmail = async () => {
        setLoadingSave(true);
        await WarrantyServices.resendConfirmationEmailForWarranty(selectedWarranty.id)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    message.success(notifyMessages.emailSendSuccess);
                    navigate("/warranties");
                }
                else {
                    message.error(notifyMessages.errorAccured);
                }
            }).catch(error => {
                message.error(notifyMessages.errorAccured);
            });
    }

    const onCheck = (checked) => {
        setContraenteIsBeneficiary(checked);

        if (checked) {
            const beneficentFields = form.getFieldsValue([
                'beneficentID',
                'beneficentName',
                'beneficentSurname',
                'beneficentFiscalCode',
                'beneficentEmail',
                'beneficentRegion',
                'beneficentCAP',
                'beneficentPhone',
                'beneficentAddress',
                'beneficentCity'
            ]);
            form.setFieldsValue({
                contractorID: beneficentFields.beneficentID || '',
                contractorName: beneficentFields.beneficentName || '',
                contractorSurname: beneficentFields.beneficentSurname || '',
                contractorFiscalCode: beneficentFields.beneficentFiscalCode || '',
                contractorEmail: beneficentFields.beneficentEmail || '',
                contractorRegion: beneficentFields.beneficentRegion || '',
                contractorPostalCode: beneficentFields.beneficentCAP || '',
                contractorPhone: beneficentFields.beneficentPhone || '',
                contractorAddress: beneficentFields.beneficentAddress || '',
                contractorCity: beneficentFields.beneficentCity || '',
            });
        }
        else {
            resetContarenteFields();
        }
    }

    const resetContarenteFields = () => {
        form.setFieldsValue({
            contractorID: null,
            contractorName: '',
            contractorSurname: '',
            contractorFiscalCode: '',
            contractorEmail: '',
            contractorRegion: '',
            contractorPostalCode: '',
            contractorPhone: '',
            contractorAddress: '',
            contractorCity: ''
        });
    }

    const onPurchaseDateSelect = (selectedDate) => {
        if (selectedDate) {
            const warrantyDate = form.getFieldValue('warrantyDate');
            if (selectedDate > warrantyDate) {
                form.setFieldsValue({
                    warrantyDate: null,
                });
            }
        }
    }

    const handleCAPInput = (e) => {
        let input = e.target.value;
        if (input.length > 5) {
            input = input.slice(0, 5);
        }
        e.target.value = input;
    };

    const validateCAPLength = (_, value) => {
        if (value.trim().length !== 5) {
            return Promise.reject(new Error(warrantiesInfoMessages.postalCodeLength));
        }
        return Promise.resolve();
    };

    const disabledDataAdesione = (current) => {
        const purchaseDateStr = form.getFieldValue('purchaseDate');
        const purchaseDate = new Date(purchaseDateStr);
        return current && current < purchaseDate;
    };

    return (
        <div className='warrantyDetails'>

            <>
                <Row gutter={{ lg: 24 }}>

                    <Form
                        id='editWarranty'
                        layout="vertical"
                        form={form}
                        onFinish={(values) => updateWarrantyData(values)}
                        autoComplete="off"
                        onValuesChange={(changedValues) => {
                            setFieldChanged(true);
                            if ('beneficentEmail' in changedValues) {
                                setEmailChanged(true);
                            }
                        }}
                    >

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 24 }} lg={{ span: 24 }}>



                            <Row gutter={{ lg: 24 }}>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <Card size='small' title="DETTAGLI">

                                        <Row gutter={{ lg: 24 }}>
                                            <Form.Item
                                                label="ID"
                                                name="warrantyID"
                                                initialValue={warrantyId}
                                                hidden
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                <Form.Item
                                                    label="Data Acquisto"
                                                    name="purchaseDate"
                                                    initialValue={selectedWarranty.purchaseDate ? dayjs(selectedWarranty.purchaseDate) : ""}
                                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                                >
                                                    <DatePicker style={{ width: "100%" }} format={dateFormat} disabled={selectedWarranty.sentToC4C} onChange={(date) => onPurchaseDateSelect(date)} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                <Form.Item
                                                    label="Data Adesione"
                                                    name="warrantyDate"
                                                    initialValue={selectedWarranty.warrantyDate ? dayjs(selectedWarranty.warrantyDate) : ""}
                                                    rules={[{ required: true, message: requiredMessages.requiredField },
                                                    ]}
                                                >
                                                    <DatePicker style={{ width: "100%" }} format={dateFormat} disabled={selectedWarranty.sentToC4C} disabledDate={disabledDataAdesione} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                <Form.Item
                                                    label="Tipo Contratto"
                                                    name="warrantyTypeCode"
                                                    initialValue={selectedWarranty.warrantyTypeCode}
                                                >
                                                    <Select
                                                        placeholder="Tipo Contratto"
                                                        allowClear={false}
                                                        options={warrantyTypePicklist}
                                                        disabled
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                <Form.Item
                                                    label="Codice Appalto"
                                                    name="appaltoCode"
                                                    initialValue={selectedWarranty.appaltoCode}
                                                >
                                                    <Input placeholder="Appalto" disabled={true} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                <Form.Item
                                                    label="Ragione Sociale"
                                                    name="companyName"
                                                    initialValue={selectedWarranty.companyName}
                                                >
                                                    <Input placeholder="Ragione Sociale" disabled={true} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                <Form.Item
                                                    label="Durata"
                                                    name="duration"
                                                    initialValue={selectedWarranty.warrantyDuration}
                                                >
                                                    <Select
                                                        placeholder="Durata"
                                                        allowClear={false}
                                                        options={warrantyDuration}
                                                        disabled
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row gutter={{ lg: 24 }}>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                <Form.Item
                                                    label="Tipo Adesione"
                                                    name="unionTypeCode"
                                                    initialValue={selectedWarranty.unionTypeCode}
                                                >
                                                    <Select
                                                        placeholder="Tipo Adesione"
                                                        allowClear={false}
                                                        options={unionTypePicklist}
                                                        disabled
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                <Form.Item
                                                    label="Tipo Pagamento"
                                                    name="paymentType"
                                                    // initialValue={selectedWarranty.paymentType}
                                                    initialValue={selectedWarranty.paymentTypeID}
                                                >
                                                    <Select
                                                        placeholder="Tipo Pagamento"
                                                        allowClear={false}
                                                        options={paymentTypePicklist}
                                                        disabled
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                <Form.Item
                                                    label="Campagne"
                                                    name="promotionalCode"
                                                    initialValue={selectedWarranty.promotionalCode}
                                                >
                                                    <Select
                                                        placeholder="Promozione"
                                                        allowClear={false}
                                                        options={nonActivePromotionsPicklist}
                                                        disabled
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                <Form.Item
                                                    label="Marchio"
                                                    name="brandID"
                                                >
                                                    <Select placeholder="Marchio"
                                                        allowClear
                                                        disabled
                                                        defaultValue={
                                                            selectedWarranty && selectedWarranty.brandCode && selectedWarranty.brandDescription
                                                                ? `${selectedWarranty.brandCode} (${selectedWarranty.brandDescription})`
                                                                : undefined
                                                        } />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                <Form.Item
                                                    label="Stato Garanzia"
                                                    name="warrantyStatusCode"
                                                    initialValue={selectedWarranty.warrantyStatusCode}
                                                >
                                                    <Select
                                                        placeholder="Stato"
                                                        allowClear={false}
                                                        options={warrantyStatusPicklist}
                                                        disabled={true}
                                                        onChange={(value) => {
                                                            setStatoGaranziaValue(value);
                                                        }}

                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                <Form.Item
                                                    label="No. Garanzia"
                                                    name="number"
                                                    initialValue={selectedWarranty.number}
                                                    rules={[{ required: false, message: requiredMessages.requiredField }]}
                                                >
                                                    <Input placeholder="No. Garanzia" disabled={true} />
                                                </Form.Item>

                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                                <Form.Item
                                                    label="No. Protocollo"
                                                    name="protocolNumber"
                                                    initialValue={selectedWarranty.protocolNumber}
                                                    rules={[{ required: false, message: requiredMessages.requiredField }]}
                                                >
                                                    <Input placeholder="No. Protocollo" disabled={true} />
                                                </Form.Item>

                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>
                            </Row>

                            <Row gutter={{ lg: 24 }}>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <Card size='small' title="DATI BENEFICIARIO">
                                        <Row gutter={{ lg: 24 }}>

                                            <Form.Item
                                                label="Id"
                                                name="beneficentID"
                                                initialValue={selectedWarranty.beneficient.id}
                                                hidden={true}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Nome"
                                                    name="beneficentName"
                                                    initialValue={selectedWarranty.beneficient.name}
                                                >
                                                    <Input placeholder="Nome" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Cognome"
                                                    name="beneficentSurname"
                                                    initialValue={selectedWarranty.beneficient.surname}
                                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                                >
                                                    <Input placeholder="Cognome" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Codice Fiscale"
                                                    name="beneficentFiscalCode"
                                                    initialValue={selectedWarranty.beneficient.fiscalCode}
                                                >
                                                    <Input placeholder="Codice fiscale" disabled={true} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="C.A.P"
                                                    name="beneficentCAP"
                                                    initialValue={selectedWarranty.beneficient.cap}
                                                    rules={[{ required: true, message: requiredMessages.requiredField },
                                                    { validator: validateCAPLength }
                                                    ]}
                                                >
                                                    <Input placeholder="cap" disabled={selectedWarranty.sentToC4C} onInput={handleCAPInput} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Telefono"
                                                    name="beneficentPhone"
                                                    initialValue={selectedWarranty.beneficient.phone}
                                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                                >
                                                    <Input placeholder="Telefono" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Email"
                                                    name="beneficentEmail"
                                                    initialValue={selectedWarranty.beneficient.email}
                                                    rules={[{
                                                        required: true, message: requiredMessages.requiredField,
                                                        type: "email", message: 'Email is invalid'
                                                    }]}
                                                >
                                                    <Input placeholder="email" disabled={isEmailDisabled} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Indirizzo"
                                                    name="beneficentAddress"
                                                    initialValue={selectedWarranty.beneficient.address}
                                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                                >
                                                    <Input placeholder="Indirizzo" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Località"
                                                    name="beneficentCity"
                                                    initialValue={selectedWarranty.beneficient.city}
                                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                                >
                                                    <Input placeholder="Localita" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Provincia"
                                                    name="beneficentRegion"
                                                    initialValue={selectedWarranty.beneficient.region}
                                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                                >
                                                    <Input placeholder="Provincia" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                                <Text >Privacy: </Text>
                                                <Row gutter={{ lg: 24 }}>
                                                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                                        <Form.Item
                                                            label="Email"
                                                            name="privacyEmailBeneficiary"
                                                            initialValue={selectedWarranty.beneficient.privacyBeneficient.privacyEmail}
                                                            valuePropName="checked"

                                                        >
                                                            <Switch disabled={selectedWarranty.sentToC4C} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                                        <Form.Item
                                                            label="SMS"
                                                            name="privacySmsBeneficiary"
                                                            initialValue={selectedWarranty.beneficient.privacyBeneficient.privacySMS}
                                                            valuePropName="checked"

                                                        >
                                                            <Switch disabled={selectedWarranty.sentToC4C} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                                        <Form.Item
                                                            label="Telefono"
                                                            name="privacyPhoneBeneficiary"
                                                            valuePropName="checked"
                                                            initialValue={selectedWarranty.beneficient.privacyBeneficient.privacyPhone}
                                                        >
                                                            <Switch disabled={selectedWarranty.sentToC4C} />
                                                        </Form.Item>
                                                    </Col>

                                                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                                        <Form.Item
                                                            label="Posta"
                                                            name="privacyPostBeneficiary"
                                                            valuePropName="checked"
                                                            initialValue={selectedWarranty.beneficient.privacyBeneficient.privacyPost}
                                                        >
                                                            <Switch disabled={selectedWarranty.sentToC4C} />
                                                        </Form.Item>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <Card size='small' title="DATI CONTRAENTE"
                                        extra={
                                            (selectedWarranty.beneficient.id === selectedWarranty.contractor.id &&
                                                filteredWarrantyStatuses.some(status => status.id === selectedWarranty.warrantyStatusCode)) ?
                                                <Tooltip key={"2"} placement="top" title="Usa dati del beneficiario">
                                                    <Switch checked={contraenteIsBeneficiary} onChange={(checked) => onCheck(checked)} />
                                                </Tooltip> : null
                                        }
                                    >
                                        <Row gutter={{ lg: 24 }}>
                                            <Form.Item
                                                label="Id"
                                                name="contractorID"
                                                initialValue={selectedWarranty.contractor.id}
                                                hidden={true}
                                            >
                                                <Input />
                                            </Form.Item>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Nome"
                                                    name="contractorName"
                                                    initialValue={selectedWarranty.contractor.name}
                                                >
                                                    <Input placeholder="Nome" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Cognome"
                                                    name="contractorSurname"
                                                    initialValue={selectedWarranty.contractor.surname}
                                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                                >
                                                    <Input placeholder="Cognome" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Codice Fiscale"
                                                    name="contractorFiscalCode"
                                                    initialValue={selectedWarranty.contractor.fiscalCode}
                                                >
                                                    <Input placeholder="Codice fiscale" disabled={true} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="C.A.P"
                                                    name="contractorCAP"
                                                    initialValue={selectedWarranty.contractor.cap}
                                                    rules={[{ required: true, message: requiredMessages.requiredField },
                                                    { validator: validateCAPLength }
                                                    ]}
                                                >
                                                    <Input placeholder="cap" disabled={selectedWarranty.sentToC4C} onInput={handleCAPInput} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Telefono"
                                                    name="contractorPhone"
                                                    initialValue={selectedWarranty.contractor.phone}
                                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                                >
                                                    <Input placeholder="Telefono" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Email"
                                                    name="contractorEmail"
                                                    initialValue={selectedWarranty.contractor.email}
                                                    rules={[{
                                                        required: true, message: requiredMessages.requiredField,
                                                        type: "email", message: 'Email is invalid'
                                                    }]}
                                                >
                                                    <Input placeholder="email" disabled={isEmailDisabled} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Indirizzo"
                                                    name="contractorAddress"
                                                    initialValue={selectedWarranty.contractor.address}
                                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                                >
                                                    <Input placeholder="Indirizzo" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Località"
                                                    name="contractorCity"
                                                    initialValue={selectedWarranty.contractor.city}
                                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                                >
                                                    <Input placeholder="Localita" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Provincia"
                                                    name="contractorRegion"
                                                    initialValue={selectedWarranty.contractor.region}
                                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                                >
                                                    <Input placeholder="Provincia" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>

                            <Row gutter={{ lg: 24 }}>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <Card size='small' title="RIVENDITORE">
                                        <Row gutter={{ lg: 24 }}>
                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                                <Form.Item
                                                    label="Nome"
                                                    name="resellerName"
                                                    initialValue={selectedWarranty.resellerName}
                                                >
                                                    <Input placeholder="Nome" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                                <Form.Item
                                                    label="Localita"
                                                    name="resellerLocation"
                                                    initialValue={selectedWarranty.resellerAddress}
                                                >
                                                    <Input placeholder="Localita" disabled={selectedWarranty.sentToC4C} />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                    <Card size='small' title="ADESIONE GRATUITA">

                                        <Row gutter={{ lg: 24 }}>
                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="No. Ricevuta Intervento"
                                                    name="nrRfi"
                                                    initialValue={selectedWarranty.rfiValue}
                                                >
                                                    <Input placeholder="Nr. RFI" disabled={true} />
                                                </Form.Item>
                                            </Col>


                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Data Ricevuta Intervento"
                                                    name="rfiDate"
                                                    initialValue={selectedWarranty.rfiDate ? dayjs(selectedWarranty.rfiDate) : ""}
                                                >
                                                    <DatePicker style={{ width: "100%" }} format={dateFormat} disabled={true} />
                                                </Form.Item>
                                            </Col>

                                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                                <Form.Item
                                                    label="Tecnico"
                                                    name="technicianName"
                                                    initialValue={
                                                        (selectedWarranty.technicianName && selectedWarranty.technicianSurname)
                                                            ? `${selectedWarranty.technicianName} ${selectedWarranty.technicianSurname}`
                                                            : ""
                                                    }
                                                >
                                                    <Input placeholder="Tecnico" disabled={true} />
                                                </Form.Item>
                                            </Col>

                                        </Row>

                                    </Card>
                                </Col>
                            </Row>

                            <Card size='small' title="PRODOTTI">

                                <Row gutter={{ lg: 24 }} style={{ padding: "20px 10px 20px 10px", background: "#e1eddc" }}>
                                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                        <Table
                                            columns={productColumns}
                                            dataSource={selectedWarranty.warrantyProducts}
                                            pagination={false}
                                            size='small'
                                            bordered={true}
                                            rowKey={(obj, index) => index}
                                        />
                                    </Col>
                                </Row>

                            </Card>


                            <Divider />

                            <Row gutter={{ lg: 24 }}>
                                <Col className="gutter-row justifySpaceBetween" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <Button size='small' icon={<ArrowLeftOutlined />} onClick={() => { navigate("/warranties"); }}> Indietro </Button>

                                    <Form.Item>
                                        <Space>
                                            { resendEmailBtn &&  (
                                                <Button type='primary' icon={<SendOutlined />} onClick={() => resendConfirmationEmail()}> Rinvia e-mail </Button>
                                            )}
                                            <Button type='primary' disabled={!fieldChanged} loading={loadingSave} htmlType='submit' icon={<SaveOutlined />}> Salva </Button>
                                        </Space>
                                    </Form.Item>
                                </Col>
                            </Row>

                        </Col>
                    </Form>
                </Row>

            </>



        </div>
    );
}
export default WarrantyDetails;