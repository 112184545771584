import { trackPromise } from 'react-promise-tracker';
import axiosInstance from '../configs/axiosConfigs/interceptors';
import { acquireToken } from '../configs/authentication/MsGraphApiCall';


const SectorServices = { 

    getSectorsList: function (filters) {       
        const data = acquireToken().then((tokenResponse) => {   
            const optionsVal = {
                method: 'GET',
                url: `Sector/GetSectorList`,
                params: filters,
                headers: {
                    'Authorization': `Bearer ${tokenResponse.accessToken}`,
                }
            };            
            const apiResponse = axiosInstance(optionsVal);
            return apiResponse;
        });
        return data;
    }

};

export default SectorServices;
