
import React, { useEffect } from 'react';
import { Row, Col, Typography } from 'antd';

const { Title } = Typography;

const PageHeader = ({ title }) => {

    return (
        <Row className='pageHeader'>
            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                <Title className='pageTitle' level={4}>{title}</Title >
            </Col>
        </Row>
    );
}

export default PageHeader;