import React, { useState, useEffect } from 'react';
import { Form, Input, Row, Col, Select, message, Button, Card, Switch, Divider, DatePicker, Typography, Space, Tooltip, Modal } from 'antd';
import { notifyMessages, requiredMessages, warrantiesInfoMessages } from '../../shared/utils/errorMesages';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ArrowLeftOutlined, SyncOutlined, SendOutlined, ExclamationCircleFilled } from '@ant-design/icons';
import PageHeader from '../../shared/components/pageHeader';
import WarrantyServices from '../../services/warrantyServices';
import { dateFormat, tablePageSize, tipoAdesione, warrantyDuration, tipoContratto, paymentTypesConsts } from '../../shared/utils/constants';
import ClientsServices from '../../services/clientServices';
import WarrantyProducts2 from './warrantyProducts2';
import { handleDateConvertDayJs } from '../../shared/functions/sharedFunctions';
import ProductServices from '../../services/productServices';
import PromotionServices from '../../services/promotionServices';
import { isCat } from '../../configs/authRoleFunctions';

const { Text } = Typography;



const NewWarranty = () => {

    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { confirm } = Modal;
    const tecnicsList = useSelector(state => state.tecnicList.tecnicList);
    const warrantyStatusList = useSelector(state => state.warrantyStatusList.warrantyStatusList);
    const warrantyTypeList = useSelector(state => state.warrantyTypeList.warrantyTypeList);
    const paymentTypeList = useSelector(state => state.paymentTypeList.paymentTypeList);
    const currentUser = useSelector(state => state.currentUser.loggedinUserData);
    const unionTypeList = useSelector(state => state.unionTypeList.unionTypeList);

    const [clientsList, setClientsList] = useState(null);
    const [loadingClientsList, setLoadingClientsList] = useState(false);
    const [loadingConfig, setLoadingConfig] = useState(false);

    const [brandList, setBrandList] = useState(null);
    const [warrantyDurationPickList, setWarrantyDurationPickList] = useState(warrantyDuration);
    const [loadingSave, setLoadingSave] = useState(false);
    const [selectedWarrantyType, setSelectedWarrantyType] = useState({ label: "", value: "" });
    const [loadingContraenteSearch, setLoadingContraenteSearch] = useState(false);

    const [selectedContraente, setSelectedContraente] = useState([]);
    const [selectedBeneficiario, setSelectedBeneficiario] = useState(null);
    const [contraenteIsBeneficiary, setContraenteIsBeneficiary] = useState(false);
    const [contraenteIsBeneficiarySwitchDisabled, setDontraenteIsBeneficiarySwitchDisabled] = useState(false);

    const [promozioneRequired, setPromozioneRequired] = useState(false);
    const [promozioneDisabled, setPromozioneDisabled] = useState(true);
    const [selectedProducts, setselectedProducts] = useState([]);
    const [serialNumberExist, setSerialNumberExist] = useState(false);
    const [warrantyTypeConfig, setWarrantyTypeConfig] = useState(null);

    const [selectedMarchio, setSelectedMarchio] = useState(null);
    const [selectedTipoAdesione, setSelectedTipoAdesione] = useState(null);
    const [searchProductsDisabled, setSearchProductsDisabled] = useState(true);

    const [nonActivePromotionsList, setNonActivePromotionsList] = useState(null);
    const [promotionInfo, setPromotionInfo] = useState(null);
    const [brandDisabled, setBrandDisabled] = useState(false);

    const [toggleSearch, setToggleSearch] = useState(true);
    const [salvaBtnDisabled, setSalvaBtnDisabled] = useState(true);
    const [isCodiceFiscaleRequired, setIsCodiceFiscaleRequired] = useState(false)
    const [adesioneGratuitaRequired, setAdesioneGratuitaRequired] = useState(false);
    const [hasPromotionCode, setHasPromotionCode] = useState(false);
    const [tipoAdesioneDisabled, setTipoAdesioneDisabled] = useState(false);
    const [disabledContractorForCat, setDisabledContractorForCat] = useState(false);

    const [warrantyNr, setWarrantyNr] = useState(null);

    useEffect(() => {
        getClientList();
        getBrandList();
        getWarrantyNumber();
    }, []);

    useEffect(() => {
        form.setFieldsValue({
            duration: warrantyDuration[0].value,
        });
    }, [form, warrantyDuration]);

    const singoloProdottoWarrantyType = warrantyTypeList.find(obj => {
        return obj.name === tipoContratto.singolo;
    });

    const tecnicsPicklist = tecnicsList.reduce((res, item) => {
        res.push({ value: item.id, label: (item.name + " " + item.surname) });
        return res;

    }, []);

    const warrantyTypePicklist = warrantyTypeList.reduce((res, item) => {
        res.push({ value: item.id, label: item.name });

        if (isCat(currentUser)) {
            let tipoContrattoCat = res.filter(item => item.value != singoloProdottoWarrantyType.id);
            return tipoContrattoCat;
        }
        else {
            return res;
        }
    }, []);

    const warrantyStatusPicklist = warrantyStatusList.reduce((res, item) => {
        res.push({ value: item.id, label: item.name });
        return res;
    }, []);

    const defaultWarrantyStatus = warrantyStatusList.find(obj => {
        return obj.processOrder === 1;
    });

    const unionTypePicklist = unionTypeList.reduce((res, item) => {
        res.push({ value: item.id, label: item.type });
        return res;
    }, []);

    const promozionaleWarrantyType = warrantyTypeList.find(obj => {
        return obj.name === tipoContratto.promozionale;
    });

    const tipoAdesioneSet = unionTypeList.find(obj => {
        return obj.type === tipoAdesione.set
    })

    const paymentTypePagamento = paymentTypeList.find(obj => {
        return obj.name === paymentTypesConsts.pagamento;
    });

    const paymentTypePicklist = paymentTypeList.reduce((res, item) => {
        res.push({ value: item.id, label: item.name });
        return res;
    }, []);

    const handleTipoAdesioneChange = (value) => {
        const selectedOption = unionTypeList.find(option => option.id === value);
        if (selectedOption) {
            const selectedType = selectedOption.type;
            setSelectedTipoAdesione(selectedType);
            setSearchProductsDisabled(false);
        }
        if (selectedOption.id === tipoAdesioneSet.id) {
            setAdesioneGratuitaRequired(true);
        } else {
            setAdesioneGratuitaRequired(false);
        }
    };

    const handleMarchioChange = (value) => {
        setSelectedMarchio(value);
    };

    const getWarrantyNumber = async () => {
        await WarrantyServices.getWarrantyNumber()
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value;
                    setWarrantyNr(respData.warrantyNumbers);
                }
                else {
                    message.error(notifyMessages.errorAccured);
                }
            }).catch(error => {
                message.error(notifyMessages.errorAccured);
            });
    }

    const getClientList = async () => {
        let filterObj = { enabled: true, name: "", surname: "", fiscalCode: "", email: "", region: "", customerCode: null, userID: null };
        filterObj["elementsNo"] = tablePageSize;
        filterObj["pageNo"] = 0;
        filterObj["sortColumn"] = "";
        filterObj["ascOrdering"] = true;
        filterObj["userID"] = currentUser.id

        setLoadingClientsList(true);
        await ClientsServices.getClientsList(filterObj)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value;
                    let clientsPiclist = respData.customerList.reduce((res, item) => {
                        let label = "";
                        if (item.name) {
                            label += item.name;
                        }
                        if (item.surname) {
                            if (label.length > 0) {
                                label += " ";
                            }
                            label += item.surname;
                        }
                        if (item.fiscalCode) {
                            if (label.length > 0) {
                                label += " - ";
                            }
                            label += item.fiscalCode;
                        }
                        if (label.length > 0) {
                            res.push({ value: item.id, label });
                        }
                        return res;
                    }, []);
                    setClientsList(clientsPiclist);
                    setLoadingClientsList(false);
                }
                else {
                    setLoadingClientsList(false);
                }
            }).catch(error => {
                setLoadingClientsList(false);
            });
    }

    const getBrandList = async () => {
        let filterObj = {}
        filterObj["usedInWarranty"] = true;
        await ProductServices.getProductBrands(filterObj)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value;
                    const brandPickList = respData.brandList.reduce((res, item) => {
                        const brandPickListLable = [];
                        if (item.code) {
                            brandPickListLable.push(item.code);
                        }
                        if (item.description) {
                            brandPickListLable.push(`(${item.description})`);
                        }
                        const label = brandPickListLable.join(' ');

                        if (label) {
                            res.push({ value: item.id, label });
                        }
                        return res;
                    }, []);
                    setBrandList(brandPickList);
                }
                else {
                }
            }).catch(error => {
            });
    }

    const getDatiContraente = async (clientId) => {
        setLoadingContraenteSearch(true);
        await ClientsServices.getClientById(clientId)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    setLoadingContraenteSearch(false);
                    let respData = resp.responseObject.value;
                    populateContaenteField(respData.customerDetails);
                }
                else {
                    message.error(notifyMessages.errorAccured);
                    setLoadingContraenteSearch(false);
                }
            }).catch(error => {
                message.error(notifyMessages.errorAccured);
                setLoadingContraenteSearch(false);
            });
    }

    const getDatiBeneficiario = async (clientId) => {
        setLoadingContraenteSearch(true);
        await ClientsServices.getClientById(clientId)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    setLoadingContraenteSearch(false);
                    let respData = resp.responseObject.value;
                    setSelectedBeneficiario(respData.customerDetails);
                    populateBeneficiarioField(respData.customerDetails);
                }
                else {
                    message.error(notifyMessages.errorAccured);
                    setLoadingContraenteSearch(false);
                }
            }).catch(error => {
                message.error(notifyMessages.errorAccured);
                setLoadingContraenteSearch(false);
            });
    }

    const getWarrantyTypeConfig = async (value, option) => {
        setSelectedWarrantyType(option);
        setLoadingConfig(true);

        await WarrantyServices.warrantyTypeConfig(value)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value.configurationData;
                    setLoadingConfig(false);
                    setWarrantyTypeConfig(respData);
                    onSelectWarrantyType(respData);
                }
                else {
                    message.error(notifyMessages.errorAccured);
                    setLoadingConfig(false);
                }
            }).catch(error => {
                message.error(notifyMessages.errorAccured);
                setLoadingConfig(false);
            });
    }

    const checkBeforeCreateWaranty = async (values, updateCustomerInfo, keepExsistingCustomerInfo) => {
        if (selectedProducts.length > 0) {
            let checkSerialNumberIsEmpty = selectedProducts.find(element => {
                if (element.serialNumber === null || element.serialNumber === "") {
                    return true;
                }
                else {
                    return false;
                }
            })

            if (checkSerialNumberIsEmpty) {
                message.error(warrantiesInfoMessages.serialNumberIsMandatory);
            }
            else {

                if (selectedTipoAdesione === tipoAdesione.set && selectedProducts.length < 3) {
                    message.error(warrantiesInfoMessages.productsNumberForSet);
                }

                else {
                    confirm({
                        title: 'Conferma',
                        content: warrantiesInfoMessages.confirmCreateWrranty,
                        okText: 'Si',
                        cancelText: 'No',
                        onOk() {
                            createWaranty(values, updateCustomerInfo, keepExsistingCustomerInfo);
                        },
                        onCancel() {
                            // do nothing
                        },
                    });
                }
            }
        }
        else {
            message.error(warrantiesInfoMessages.selectAtLeastOneProduct);
        }

    }


    const createWaranty = async (values, updateCustomerInfo, keepExsistingCustomerInfo) => {
        values["warrantyProducts"] = selectedProducts;
        values["sendViaMail"] = true;
        values["updateCustomerInfo"] = updateCustomerInfo;
        values["keepExsistingCustomerInfo"] = keepExsistingCustomerInfo;

        values.creationDate = handleDateConvertDayJs(values.creationDate);
        values.purchaseDate = handleDateConvertDayJs(values.purchaseDate);
        values.warrantyDate = handleDateConvertDayJs(values.warrantyDate);
        values.rfiDate = handleDateConvertDayJs(values.rfiDate);


        const selectedPaymentType = paymentTypeList.find(obj => {
            return (obj.id === values.paymentType || obj.name === values.paymentType);
        })

        values.paymentType = selectedPaymentType.name;

        setLoadingSave(true);
        await WarrantyServices.createWarranty(values)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    setLoadingSave(false);
                    message.success(notifyMessages.addSuccess);
                    navigate("/warranties")
                }
                else {
                    if (resp.errorDetail.errorCode && resp.errorDetail.errorCode === 1) {
                        showConfirm(values, resp.errorDetail.errorMessage, resp.errorDetail.errorCode);
                    }
                    else if (resp.errorDetail.errorCode && resp.errorDetail.errorCode === 2) {
                        showConfirm(values, resp.errorDetail.errorMessage, resp.errorDetail.errorCode);
                    }
                    else {
                        message.error(notifyMessages.errorAccured);
                    }

                    setLoadingSave(false);

                }
            }).catch(error => {
                setLoadingSave(false);
                message.error(notifyMessages.errorAccured);
            });

    }

    const loadSelectedProducts = (products) => {
        setselectedProducts(products);
    }


    const onCheck = (checked) => {
        setContraenteIsBeneficiary(checked);

        if (checked) {
            populateContaenteField(selectedBeneficiario);
            setToggleSearch(false);

            const beneficentFields = form.getFieldsValue([
                'beneficentID',
                'beneficentName',
                'beneficentSurname',
                'beneficentFiscalCode',
                'beneficentEmail',
                'beneficentRegion',
                'beneficentCAP',
                'beneficentPhone',
                'beneficentAddress',
                'beneficentCity'
            ]);
            form.setFieldsValue({
                contractorID: beneficentFields.beneficentID || '',
                contractorName: beneficentFields.beneficentName || '',
                contractorSurname: beneficentFields.beneficentSurname || '',
                contractorFiscalCode: beneficentFields.beneficentFiscalCode || '',
                contractorEmail: beneficentFields.beneficentEmail || '',
                contractorRegion: beneficentFields.beneficentRegion || '',
                contractorPostalCode: beneficentFields.beneficentCAP || '',
                contractorPhone: beneficentFields.beneficentPhone || '',
                contractorAddress: beneficentFields.beneficentAddress || '',
                contractorCity: beneficentFields.beneficentCity || '',
            });
        }
        else {
            resetContarenteFields();
            setToggleSearch(true);
            if (hasPromotionCode) {
                const selectedPromotionValue = form.getFieldValue('promotionalCode');
                const selectedPromotion = promotionInfo.find(
                    promotion => promotion.progressiveCodeID === selectedPromotionValue
                );
                populateContaenteField(selectedPromotion);
            }
            setSelectedContraente([]);
        }
    }

    const onSelectContaente = (option) => {
        if (option) {
            getDatiContraente(option);
            setSelectedContraente(option);
        }
    }

    const onSelectBeneficiario = (option) => {
        if (option) {
            getDatiBeneficiario(option);
            setContraenteIsBeneficiary(false);
            setToggleSearch(true);
            if (!hasPromotionCode) {
                resetContarenteFields();
            }
        }
    }

    const populateContaenteField = (data) => {
        if (data) {
            form.setFieldsValue({
                contractorID: data.id ? data.id : data.customerID ? data.customerID : null,
                contractorName: data.name,
                contractorSurname: data.surname,
                contractorFiscalCode: data.fiscalCode,
                contractorEmail: data.email,
                contractorRegion: data.region,
                contractorPostalCode: data.cap,
                contractorPhone: data.phone,
                contractorAddress: data.address,
                contractorCity: data.city
            });
        }
    }

    const populateBeneficiarioField = (data) => {
        if (data) {
            form.setFieldsValue({
                beneficentID: data.id ? data.id : null,
                beneficentName: data.name,
                beneficentSurname: data.surname,
                beneficentFiscalCode: data.fiscalCode,
                beneficentEmail: data.email,
                beneficentRegion: data.region,
                beneficentCAP: data.cap,
                beneficentPhone: data.phone,
                beneficentAddress: data.address,
                beneficentCity: data.city,
                beneficentPrivacyEmail: data.privacyData.privacyEmail,
                beneficentPrivacySMS: data.privacyData.privacySMS,
                beneficentPrivacyPhone: data.privacyData.privacyPhone,
                beneficentPrivacyPost: data.privacyData.privacyPost
            });
        }
    }

    const resetContarenteFields = () => {
        setSelectedContraente([]);
        form.setFieldsValue({
            contractorID: null,
            contractorName: '',
            contractorSurname: '',
            contractorFiscalCode: '',
            contractorEmail: '',
            contractorRegion: '',
            contractorPostalCode: '',
            contractorPhone: '',
            contractorAddress: '',
            contractorCity: ''
        });
    }

    const resetBeneficentFields = () => {
        form.setFieldsValue({
            beneficentID: null,
            beneficentName: '',
            beneficentSurname: '',
            beneficentFiscalCode: '',
            beneficentEmail: '',
            beneficentRegion: '',
            beneficentCAP: '',
            beneficentPhone: '',
            beneficentAddress: '',
            beneficentCity: '',
            beneficentPrivacyEmail: false,
            beneficentPrivacySMS: false,
            beneficentPrivacyPhone: false,
            beneficentPrivacyPost: false
        });
    }

    const handelCheckSerialNumber = (record, value) => {
        let found = selectedProducts.filter(el => el.id === record.id && el.serialNumber === value);
        let foundLength = found.length;
        if (foundLength > 0 && record.pid != found[0].pid) {
            setSerialNumberExist(true);
            setSalvaBtnDisabled(true);
            message.error(warrantiesInfoMessages.checkDuplicatedSerialNumber);
            return true
        }
        if (foundLength < 1) {
            setSerialNumberExist(false);
            setSalvaBtnDisabled(false);
            return false
        }
    }

    const showConfirm = (values, title, errorCode) => {
        let cancelButtonText = "";
        if (errorCode === 1) {
            cancelButtonText = warrantiesInfoMessages.useBeneficaryDataInWarranty;
        } else if (errorCode === 2) {
            cancelButtonText = warrantiesInfoMessages.useContractorDataInWarranty;
        }

        confirm({
            title: title,
            icon: <ExclamationCircleFilled />,
            closable: false,
            maskClosable: false,
            keyboard: false,
            width: 800,
            onOk() {
                createWaranty(values, true, false);
            },
            okText: "Aggiorna Dati",
            onCancel() {
                createWaranty(values, false, true);
            },
            cancelText: cancelButtonText,
        });
    };

    const getNonActivePromotions = async (date) => {
        await PromotionServices.getNonActivePromotion(date)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    const promotionData = resp.responseObject.value.progressiveCodesList;
                    const nonActivePromotionsPicklist = promotionData.reduce((res, item) => {
                        res.push({ value: item.progressiveCodeID, label: item.progressiveCode });
                        return res;
                    }, []);

                    setNonActivePromotionsList(nonActivePromotionsPicklist);
                    setPromotionInfo(promotionData);
                }
                else {
                }
            }).catch(error => {
            });
    }

    const onSelectPromotion = (option) => {
        if (option && option.value) {
            populateBrandField(option.value);
            setBrandDisabled(true);
            const promotionID = option.value;

            const selectedPromotion = promotionInfo.find(
                promotion => promotion.progressiveCodeID === promotionID
            );
            setHasPromotionCode(true)
            if (selectedPromotion) {
                populateContaenteField(selectedPromotion);
                setSelectedContraente([]);
                if (isCat(currentUser)) {
                    setDisabledContractorForCat(true);
                } else {
                    setDisabledContractorForCat(false);
                }
            }
        }
        else {
            populateBrandField(null);
            setHasPromotionCode(false);
            setBrandDisabled(false);
            resetContarenteFields();
            setDisabledContractorForCat(false);
        }
    }

    const populateBrandField = (selectedPromotionID) => {
        if (selectedPromotionID && promotionInfo) {
            const selectedPromotion = promotionInfo.find(
                promotion => promotion.progressiveCodeID === selectedPromotionID
            );
            if (selectedPromotion) {
                form.setFieldsValue({
                    brandID: selectedPromotion.brandID,
                });
                setSelectedMarchio(selectedPromotion.brandID);

            } else {
                form.setFieldsValue({
                    brandID: '',
                });
            }
        } else {
            form.setFieldsValue({
                brandID: '',
            });
        }
    }

    const onSelectWarrantyType = (respData) => {

        if (respData && Object.keys(respData).length > 0) {

            if (respData.warrantyTypeID === singoloProdottoWarrantyType.id) {
                let durationArray = warrantyDurationPickList.filter(item => item.value != 1);
                setWarrantyDurationPickList(durationArray);
                setBrandDisabled(false);
            }
            else {
                setWarrantyDurationPickList(warrantyDuration);
            }


            if (respData.warrantyTypeID === promozionaleWarrantyType.id) {
                setPromozioneRequired(true);
                setAdesioneGratuitaRequired(true);
                form.setFieldsValue({
                    unionTypeCode: respData.unionTypeID,
                    brandID: respData.brandID ? respData.brandID : '',
                    duration: respData.duration,
                    paymentType: respData.paymentTypeID
                });
                handleTipoAdesioneChange(respData.unionTypeID);
                setSearchProductsDisabled(false);
                setTipoAdesioneDisabled(true);
                setBrandDisabled(false);
                if (isCat(currentUser)) {
                    setDontraenteIsBeneficiarySwitchDisabled(true);
                }

                if (form.getFieldValue("purchaseDate")) {
                    setPromozioneDisabled(false);
                }
                else { 
                    setPromozioneDisabled(true); 
                }

            }
            else {
                setPromozioneRequired(false);
                setAdesioneGratuitaRequired(false);
                form.setFieldsValue({
                    unionTypeCode: respData.unionTypeID,
                    brandID: respData.brandID ? respData.brandID : '',
                    duration: respData.duration,
                    promotionalCode: '',
                    paymentType: respData.paymentTypeID

                });
                setSelectedMarchio(respData.brandID);
                resetContarenteFields();
                handleTipoAdesioneChange(respData.unionTypeID);
                setSearchProductsDisabled(false);
                setPromozioneDisabled(true);
                setTipoAdesioneDisabled(false);
                setDontraenteIsBeneficiarySwitchDisabled(false);

            }
        }
    }


    const onPurchaseDateSelect = (selectedDate) => {
        if (selectedDate) {
            const warrantyDate = form.getFieldValue('warrantyDate');
            const rfiDate = form.getFieldValue('rfiDate');
            let formatedDate = handleDateConvertDayJs(selectedDate);
            getNonActivePromotions(formatedDate);

            if (selectedWarrantyType.label !== tipoContratto.promozionale) {
                setPromozioneDisabled(true);
            }
            else {
                setPromozioneDisabled(false);
            }

            form.setFieldsValue({
                promotionalCode: '',
                brandID: '',
            });
            resetContarenteFields();

            if(selectedDate > warrantyDate) {
                form.setFieldsValue({
                    warrantyDate: null,
                });
            }

            if (selectedDate > rfiDate) {
                form.setFieldsValue({
                    rfiDate: null,
                });
            }
        }
        else {
            setPromozioneDisabled(true);
            setBrandDisabled(false);
            form.setFieldsValue({
                promotionalCode: '',
                brandID: '',
            });
            resetContarenteFields();
            getWarrantyTypeConfig(selectedWarrantyType.value, selectedWarrantyType)
        }
    }

    const onSelectPaymentType = (value) => {
        if (value === paymentTypePagamento.id) {
            setIsCodiceFiscaleRequired(true);
        } else {
            setIsCodiceFiscaleRequired(false);
        }
    }

    const handleNrRfiInput = (e) => {
        let input = e.target.value;
        if (input.length > 9) {
            input = input.slice(0, 9);
        }
        e.target.value = input;
    };

    const handleFiscalCodeInput = (e) => {
        let input = e.target.value;
        if (input.length > 16) {
            input = input.slice(0, 16);
        }
        e.target.value = input;
    };

    const validateFiscalCodeLength = (_, value) => {
        if (value && value.trim().length !== 16) {
            return Promise.reject(new Error(warrantiesInfoMessages.fisclaCodeLength));
        }
        return Promise.resolve();
    };

    const handleCAPInput = (e) => {
        let input = e.target.value;
        if (input.length > 5) {
            input = input.slice(0, 5);
        }
        e.target.value = input;
    };

    const validateCAPLength = (_, value) => {
        if (value.trim().length !== 5) {
            return Promise.reject(new Error(warrantiesInfoMessages.postalCodeLength));
        }
        return Promise.resolve();
    };

    const disabledDataAdesioneAndRicevuta = (current) => {
        const purchaseDate = form.getFieldValue('purchaseDate');
        return current && current < purchaseDate;
    };

    return (
        <>

            <PageHeader title="Crea nuovo garanzia" />

            <Row gutter={{ lg: 24 }}>
                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>

                    <Form
                        name='newWarranty'
                        className='newWarrantyForm'
                        form={form}
                        scrollToFirstError={true}
                        layout="vertical"
                        onFinish={(values) => checkBeforeCreateWaranty(values, false, false)}
                        autoComplete="off"
                        onValuesChange={() => {
                            setSalvaBtnDisabled(false);
                        }}
                    >


                        <Row gutter={{ lg: 24 }}>
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Card size='small' title="DETTAGLI">

                                    <Form.Item
                                        label="ID"
                                        name="userID"
                                        initialValue={currentUser.id}
                                        hidden
                                    >
                                        <Input />
                                    </Form.Item>

                                    <Row gutter={{ lg: 24 }}>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            <Form.Item
                                                label="Data Acquisto"
                                                name="purchaseDate"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <DatePicker style={{ width: "100%" }} format={dateFormat} onChange={(date) => onPurchaseDateSelect(date)}/>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            <Form.Item
                                                label="Data Adesione"
                                                name="warrantyDate"
                                                tooltip="Data adesione deve essere uguale o maggiore alla data di acquisto"
                                                rules={[{ required: true, message: requiredMessages.requiredField }
                                                ]}
                                            >
                                                <DatePicker style={{ width: "100%" }} format={dateFormat} disabledDate={disabledDataAdesioneAndRicevuta} />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            <Form.Item
                                                label="Tipo Contratto"
                                                name="warrantyTypeID"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Select
                                                    placeholder="Tipo Contratto"
                                                    allowClear={false}
                                                    options={warrantyTypePicklist}
                                                    onChange={getWarrantyTypeConfig}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            <Form.Item
                                                label="Codice Appalto"
                                                name="appaltoCode"
                                                initialValue={currentUser.appaltoCode}
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Input placeholder="Appalto" disabled={true} />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            <Form.Item
                                                label="Ragione Sociale"
                                                name="companyName"
                                                initialValue={currentUser.userName}
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Input placeholder="Ragione Sociale" disabled={true} />
                                            </Form.Item>
                                        </Col>


                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            <Form.Item
                                                label="Durata"
                                                name="duration"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Select
                                                    placeholder="Durata"
                                                    allowClear={false}
                                                    options={warrantyDurationPickList}
                                                    disabled={selectedWarrantyType.label !== tipoContratto.singolo && isCat(currentUser)}
                                                    loading={loadingConfig}
                                                />
                                            </Form.Item>
                                        </Col>

                                    </Row>

                                    <Row gutter={{ lg: 24 }}>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            <Form.Item
                                                label="Tipo Adesione"
                                                name="unionTypeCode"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Select
                                                    placeholder="Tipo Adesione"
                                                    allowClear={false}
                                                    options={unionTypePicklist}
                                                    onChange={handleTipoAdesioneChange}
                                                    disabled={tipoAdesioneDisabled || isCat(currentUser)}
                                                    loading={loadingConfig}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            <Form.Item
                                                label="Tipo Pagamento"
                                                name="paymentType"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Select
                                                    placeholder="Tipo Pagamento"
                                                    allowClear={false}
                                                    // options={paymentTypeValues}
                                                    options={paymentTypePicklist}
                                                    onChange={onSelectPaymentType}
                                                    disabled={isCat(currentUser)}
                                                    loading={loadingConfig}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            <Form.Item
                                                label="Campagne"
                                                name="promotionalCode"
                                                rules={[
                                                    {
                                                        required: promozioneRequired,
                                                        message: requiredMessages.requiredField
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    showSearch
                                                    placeholder="Cerca una promozione"
                                                    disabled={promozioneDisabled}
                                                    allowClear
                                                    optionFilterProp="children"
                                                    filterOption={(input, option) =>
                                                        (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                                    }
                                                    options={nonActivePromotionsList}
                                                    onSelect={(value) => { onSelectPromotion({ value }) }}
                                                    onClear={(value) => { onSelectPromotion({ value }) }}
                                                >
                                                    {/* {nonActivePromotionsPicklist.map(promotion => (
                                                        <Select.Option key={promotion.value} value={promotion.value}>
                                                            {promotion.label}
                                                        </Select.Option>
                                                    ))} */}
                                                </Select>
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            <Form.Item
                                                label="Marchio"
                                                name="brandID"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Select placeholder="Marchio"
                                                    allowClear={true}
                                                    options={brandList}
                                                    disabled={brandDisabled || (selectedWarrantyType.label !== tipoContratto.singolo && isCat(currentUser))}
                                                    onChange={handleMarchioChange}
                                                    loading={loadingConfig}
                                                />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            {defaultWarrantyStatus && (
                                                <Form.Item
                                                    label="Stato Garanzia"
                                                    name="status"
                                                    rules={[{ required: true, message: requiredMessages.requiredField }]}
                                                    initialValue={defaultWarrantyStatus.id}
                                                >
                                                    <Select
                                                        placeholder="Stato"
                                                        allowClear={false}
                                                        options={warrantyStatusPicklist}
                                                        disabled
                                                    />
                                                </Form.Item>
                                            )}
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                            {warrantyNr && (
                                                <Form.Item
                                                    label="No. Garanzia"
                                                    name="number"
                                                    initialValue={warrantyNr.number}
                                                    rules={[{ required: false, message: requiredMessages.requiredField }]}
                                                >
                                                    <Input placeholder="No. Garanzia" disabled={true} />
                                                </Form.Item>
                                            )}
                                        </Col>
                                    </Row>

                                </Card>
                            </Col>
                        </Row>

                        <Row gutter={{ lg: 24 }}>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Card size='small' title="DATI BENEFICIARIO"
                                    extra={
                                        <Select
                                            showSearch
                                            loading={loadingClientsList}
                                            disabled={loadingClientsList}
                                            allowClear={true}
                                            style={{ width: 400 }}
                                            placeholder="Cerca beneficiario"
                                            optionFilterProp="children"
                                            onSelect={(option) => onSelectBeneficiario(option)}
                                            onClear={resetBeneficentFields}
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={clientsList}
                                        />

                                    }

                                >


                                    <Row gutter={{ lg: 24 }}>
                                        <Form.Item
                                            label="Id"
                                            name="beneficentID"
                                            hidden={true}
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Nome"
                                                name="beneficentName"
                                            >
                                                <Input placeholder="Nome" />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Cognome"
                                                name="beneficentSurname"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Input placeholder="Cognome" />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Codice fiscale"
                                                name="beneficentFiscalCode"
                                                rules={[{ required: isCodiceFiscaleRequired, message: requiredMessages.requiredField },
                                                { validator: validateFiscalCodeLength }
                                                ]}

                                            >
                                                <Input placeholder="Codice fiscale" onInput={handleFiscalCodeInput} />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="C.A.P"
                                                name="beneficentCAP"
                                                rules={[{ required: true, message: requiredMessages.requiredField },
                                                { validator: validateCAPLength }
                                                ]}
                                            >
                                                <Input placeholder="C.A.P" type="number" onInput={handleCAPInput} />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Telefono"
                                                name="beneficentPhone"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Input placeholder="Telefono" />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Email"
                                                name="beneficentEmail"
                                                rules={[{
                                                    required: true,
                                                    message: requiredMessages.requiredField,
                                                    type: "email", message: 'E-mail non valido'
                                                }]}
                                            >
                                                <Input placeholder="email" />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Indirizzo"
                                                name="beneficentAddress"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Input placeholder="Indirizzo" />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Località"
                                                name="beneficentCity"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Input placeholder="Localita" />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Provincia"
                                                name="beneficentRegion"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Input placeholder="Provincia" />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                            <Text >Privacy: </Text>
                                            <Row gutter={{ lg: 24 }}>
                                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                                    <Form.Item
                                                        label="Email"
                                                        name="beneficentPrivacyEmail"
                                                        valuePropName="checked"
                                                    >
                                                        <Switch />
                                                    </Form.Item>
                                                </Col>

                                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                                    <Form.Item
                                                        label="SMS"
                                                        name="beneficentPrivacySMS"
                                                        valuePropName="checked"
                                                    >
                                                        <Switch />
                                                    </Form.Item>
                                                </Col>

                                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                                    <Form.Item
                                                        label="Telefono"
                                                        name="beneficentPrivacyPhone"
                                                        valuePropName="checked"
                                                    >
                                                        <Switch />
                                                    </Form.Item>
                                                </Col>

                                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                                    <Form.Item
                                                        label="Posta"
                                                        name="beneficentPrivacyPost"
                                                        valuePropName="checked"
                                                    >
                                                        <Switch />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Card size='small' title={
                                    <Text>
                                        DATI CONTRAENTE {loadingContraenteSearch ? <SyncOutlined spin /> : ""}
                                    </Text>
                                }
                                    extra={[
                                        (toggleSearch &&
                                            (!isCat(currentUser) || !hasPromotionCode) ? (
                                            <Select
                                                key={"1"}
                                                showSearch
                                                value={selectedContraente}
                                                loading={loadingClientsList}
                                                disabled={loadingClientsList}
                                                allowClear={true}
                                                style={{ width: 400, marginRight: 10 }}
                                                placeholder="Cerca contraente"
                                                optionFilterProp="children"
                                                onSelect={(option) => onSelectContaente(option)}
                                                onClear={resetContarenteFields}
                                                filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                                options={clientsList}
                                            />
                                        ) : null
                                        ),
                                        <Tooltip key={"2"} placement="top" title="Usa dati del beneficiario">
                                            <Switch checked={contraenteIsBeneficiary} onChange={(checked) => onCheck(checked)} disabled={contraenteIsBeneficiarySwitchDisabled} />
                                        </Tooltip>
                                    ]}
                                >

                                    <Row gutter={{ lg: 24 }}>


                                        <Form.Item
                                            label="Id"
                                            name="contractorID"
                                            hidden={true}
                                        >
                                            <Input />
                                        </Form.Item>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Nome"
                                                name="contractorName"
                                            >
                                                <Input placeholder="Nome" disabled={disabledContractorForCat} />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Cognome"
                                                name="contractorSurname"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Input placeholder="Cognome" disabled={disabledContractorForCat} />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Codice fiscale"
                                                name="contractorFiscalCode"
                                                rules={[{ required: false, message: requiredMessages.requiredField },
                                                { validator: validateFiscalCodeLength }
                                                ]}
                                            >
                                                <Input placeholder="Codice fiscale" onInput={handleFiscalCodeInput} disabled={disabledContractorForCat} />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="C.A.P"
                                                name="contractorPostalCode"
                                                rules={[{ required: true, message: requiredMessages.requiredField },
                                                { validator: validateCAPLength }
                                                ]}
                                            >
                                                <Input placeholder="cap" disabled={disabledContractorForCat} onInput={handelCheckSerialNumber} />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Telefono"
                                                name="contractorPhone"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Input placeholder="Telefono" disabled={disabledContractorForCat} />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Email"
                                                name="contractorEmail"
                                                rules={[{
                                                    required: true,
                                                    message: requiredMessages.requiredField,
                                                    type: "email", message: 'E-mail non valido'
                                                }]}
                                            >
                                                <Input placeholder="email" disabled={disabledContractorForCat} />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Indirizzo"
                                                name="contractorAddress"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Input placeholder="Indirizzo" disabled={disabledContractorForCat} />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Località"
                                                name="contractorCity"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Input placeholder="Localita" disabled={disabledContractorForCat} />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Provincia"
                                                name="contractorRegion"
                                                rules={[{ required: true, message: requiredMessages.requiredField }]}
                                            >
                                                <Input placeholder="Provincia" disabled={disabledContractorForCat} />
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Card>
                            </Col>

                        </Row>

                        <Row gutter={{ lg: 24 }}>
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Card size='small' title="RIVENDITORE">
                                    <Row gutter={{ lg: 24 }}>
                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                            <Form.Item
                                                label="Nome"
                                                name="resellerName"
                                            >
                                                <Input placeholder="Nome" />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                            <Form.Item
                                                label="Localita"
                                                name="resellerLocation"
                                            >
                                                <Input placeholder="Localita" />
                                            </Form.Item>
                                        </Col>
                                    </Row>

                                </Card>
                            </Col>

                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                                <Card size='small' title="ADESIONE GRATUITA">

                                    <Row gutter={{ lg: 24 }}>
                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="No. Ricevuta Intervento"
                                                name="rfiNumber"
                                                rules={[
                                                    {
                                                        required: adesioneGratuitaRequired,
                                                        message: requiredMessages.requiredField
                                                    },
                                                ]}
                                            >
                                                <Input placeholder="Nr. RFI" onInput={handleNrRfiInput} />
                                            </Form.Item>
                                        </Col>


                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Data Ricevuta Intervento"
                                                name="rfiDate"
                                                tooltip="Data ricevuta deve essere uguale o maggiore alla data di acquisto"
                                                rules={[
                                                    {
                                                        required: adesioneGratuitaRequired,
                                                        message: requiredMessages.requiredField
                                                    }
                                                ]}
                                            >
                                                <DatePicker style={{ width: "100%" }} format={dateFormat} disabledDate={disabledDataAdesioneAndRicevuta} />
                                            </Form.Item>
                                        </Col>

                                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 8 }} md={{ span: 8 }} lg={{ span: 8 }}>
                                            <Form.Item
                                                label="Tecnico"
                                                name="technicianID"
                                                rules={[
                                                    {
                                                        required: adesioneGratuitaRequired,
                                                        message: requiredMessages.requiredField
                                                    },
                                                ]}
                                            >
                                                <Select
                                                    placeholder="Tecnico"
                                                    allowClear={true}
                                                    options={tecnicsPicklist}
                                                />
                                            </Form.Item>
                                        </Col>

                                    </Row>

                                </Card>
                            </Col>
                        </Row>

                        <Row gutter={{ lg: 24 }}>
                            <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Divider orientation="center">PRODOTTI</Divider>
                                <WarrantyProducts2 key={selectedTipoAdesione ? selectedTipoAdesione : ""}
                                    onProductLoad={loadSelectedProducts}
                                    chackSerialNumberDublicates={handelCheckSerialNumber}
                                    selectedTipoAdesione={selectedTipoAdesione}
                                    searchProductsDisabled={searchProductsDisabled}
                                    selectedMarchio={selectedMarchio}
                                />
                            </Col>
                        </Row>

                        <Divider />
                        <Row gutter={{ lg: 24 }}>
                            <Col className="gutter-row justifySpaceBetween" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                <Button size='small' icon={<ArrowLeftOutlined />} onClick={() => { navigate("/warranties"); }}> Indietro </Button>

                                <Form.Item>
                                    <Space>
                                        {/* <Button type='primary' disabled={!fieldChanged} loading={loadingSave} htmlType='submit' icon={<SaveOutlined />}> Salva </Button> */}
                                        <Button type='primary' disabled={salvaBtnDisabled} loading={loadingSave} htmlType='submit' icon={<SendOutlined />}> Salva e Invia </Button>
                                    </Space>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>

                </Col>
            </Row>

        </>
    );
}
export default NewWarranty;
