
import React from 'react';
import { Row, Col, Typography, Space, Divider, Card, Form, Switch, Button } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import SimpleLoader from '../../../shared/components/simpleLoader/simpleLoader';
import { useNavigate } from 'react-router-dom';

const { Text } = Typography;

const ClientDetails = ({ clientId, selectedClient }) => {

    const navigate = useNavigate();

    return (
        <div className='clientDetails'>
            {!selectedClient && (<SimpleLoader />)}

            {selectedClient && (
                <>
                    <Row gutter={[16, 16]}>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Card size='small'>
                                <Space direction='horizontal'>
                                    <Text >Nome: </Text>
                                    <Text strong>{selectedClient.name}</Text>
                                </Space>
                                <Divider />

                                <Space direction='horizontal'>
                                    <Text >Cognome: </Text>
                                    <Text strong>{selectedClient.surname}</Text>
                                </Space>
                                <Divider />

                                <Space direction='horizontal'>
                                    <Text >Codice Fiscale: </Text>
                                    <Text strong copyable>{selectedClient.fiscalCode}</Text>
                                </Space>
                                <Divider />

                                <Space direction='horizontal'>
                                    <Text >CAP: </Text>
                                    <Text strong>{selectedClient.cap}</Text>
                                </Space>
                                <Divider />

                                <Space direction='horizontal'>
                                    <Text >Attiva: </Text>
                                    <Text strong>{selectedClient.enabled ? <CheckCircleOutlined style={{ color: "#4da93d" }} /> : <CloseCircleOutlined style={{ color: "#828181", marginLeft: 5 }} />}</Text>
                                </Space>
                                <Divider />

                                <Space direction='horizontal'>
                                    <Text >Privacy: </Text>
                                    <Text strong>{selectedClient.privacy ? <CheckCircleOutlined style={{ color: "#4da93d" }} /> : <CloseCircleOutlined style={{ color: "#828181", marginLeft: 5 }} />}</Text>
                                </Space>
                            </Card>
                        </Col>

                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Card size='small'>
                                <Space direction='horizontal'>
                                    <Text >Email: </Text>
                                    <Text strong>{selectedClient.email}</Text>
                                </Space>
                                <Divider />

                                <Space direction='horizontal'>
                                    <Text >Telefono: </Text>
                                    <Text strong>{selectedClient.phone}</Text>
                                </Space>
                                <Divider />

                                <Space direction='horizontal'>
                                    <Text >Regione: </Text>
                                    <Text strong>{selectedClient.region}</Text>
                                </Space>
                                <Divider />

                                <Space direction='horizontal'>
                                    <Text >Citta: </Text>
                                    <Text strong>{selectedClient.city}</Text>
                                </Space>
                                <Divider />

                                <Space direction='horizontal'>
                                    <Text >Indirizo: </Text>
                                    <Text strong>{selectedClient.address}</Text>
                                </Space>
                                <Divider />

                                <Space direction='horizontal'>
                                    <Text >Codice Cliente: </Text>
                                    <Text strong>{selectedClient.customerCode}</Text>
                                </Space>

                            </Card>
                        </Col>
                    </Row>

                    <Row gutter={[16, 16]}>
                        
                        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 12 }} md={{ span: 12 }} lg={{ span: 12 }}>
                            <Row gutter={{ lg: 24 }}>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Form.Item
                                        label="Email"
                                        name="privacyEmail"
                                    >
                                        <Switch defaultChecked={selectedClient.privacyData.privacyEmail}/>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Form.Item
                                        label="SMS"
                                        name="privacySMS"
                                        valuePropName="checked"
                                    >
                                        <Switch defaultChecked={selectedClient.privacyData.privacySMS}/>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Form.Item
                                        label="Phone"
                                        name="privacyPhone"
                                        valuePropName="checked"
                                    >
                                        <Switch defaultChecked={selectedClient.privacyData.privacyPhone}/>
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 6 }} lg={{ span: 6 }}>
                                    <Form.Item
                                        label="Post"
                                        name="privacyPost"
                                        valuePropName="checked"
                                    >
                                        <Switch  defaultChecked={selectedClient.privacyData.privacyPost}/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Divider />

                            <Row gutter={{ lg: 24 }}>
                                <Col className="gutter-row justifySpaceBetween" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <Button size='small' icon={<ArrowLeftOutlined />} onClick={() => { navigate("/clients"); }}> Indietro </Button>
                                </Col>
                            </Row>
                            
                        </Col>
                    </Row>
                </>

            )}

        </div>
    );
}
export default ClientDetails;