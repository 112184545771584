export const requiredMessages = {
    requiredField: "Campo è obbligatorio"
};

export const notifyMessages = {
    errorAccured: "Si è verificato un errore. Riprova!",
    errorUserLoad: "Failed to load user",
    operationSuccess: "Operazione effettuata con successo",
    retrieveFailed: "Impossibile caricare i dati. Riprova",
    addSuccess: "Inserimento effettuato con successo",
    addFailed: "Inserimento non riuscito",
    updateSuccess: "Aggiornamento effettuato con successo",
    emailSendSuccess: "Email inviato con successo!",
    updateFailed: "Aggiornamento non riuscito",
    deleteSuccess: "Informazioni rimosse con successo",
    deleteFailed: "Rimozione non riuscita",
    loadMenuFailed: "Impossibile caricare la barra di navigazione. Riprova ",
    changeCompanySuccess: "Cambia azienda...",
    uploadingFlux: "Flusso in fase di elaborazione. I documenti saranno aggiornati automaticamente.",
    approvementSuccess: "Approvazione effettuata con successo",
    generateDocumentPdfLoading: "Visualizzazione del documento è in caricamento",
    generateDocumentPdfDownload: "Download del documento in elaborazione",
    changingCompany: "Cambiando azienda...",
    changingRole: "Cambiando ruolo...",
    noDataFound: "Nessun dato trovato",
    noFileFound: "Nessun file trovato"
};

export const warrantiesInfoMessages = {
    serialNumberIsMandatory: "Numero Seriale è un campo obbligatorio",
    useBeneficaryDataInWarranty: "Usa dati del beneficiario in garanzia",
    useContractorDataInWarranty: "Usa dati del contraente in garanzia",
    productsNumberForSet: "Per l'adesione selezionata, il numero dei prodotti deve essere > 2",
    confirmCreateWrranty: "Stai per salvare la garanzia! Sei sicuro di voler procedere?",
    selectAtLeastOneProduct: "Si prega di selezionare almeno un prodotto",
    checkDuplicatedSerialNumber: "Il numero seriale appena inserito è stato usato su un altra riga. Si prega di verificare!",
    fisclaCodeLength: "Il codice fiscale deve essere lungo 16 caratteri.",
    postalCodeLength: "Il C.A.P deve essere composto da 5 cifre.",
};