import { createSlice } from '@reduxjs/toolkit'
import GeneralServices from '../../services/generalServices';
import WarrantyServices from '../../services/warrantyServices';


const initialState = {
  warrantyStatusList: []
};

const warrantyStatusSlice = createSlice({
  name: 'warrantyStatus',
  initialState,
  reducers: {
    warrantyStatusLoaded(state, action) {
      state.warrantyStatusList = action.payload;
    },
  },
})

export const { warrantyStatusLoaded } = warrantyStatusSlice.actions;

export default warrantyStatusSlice.reducer;


export const fetchWarrantyStatusList = () => async (dispatch) => {
  await WarrantyServices.getWarrantyStatus()
    .then(response => response.data)
    .then(resp => {
      if (resp.success) {
        const warantyStatusData = resp.responseObject.value.warrantyStatusesList;
        dispatch(warrantyStatusLoaded(warantyStatusData));
      }
      else {
      }
    }).catch(error => {
    });
}