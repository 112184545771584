import dayjs from 'dayjs';
import UserServices from '../../services/usersServices';

export const generateSorterRule = (sorter) => {
    let sorterQuote = "";
    if (sorter.order === "descend") {
        sorterQuote = '[' + sorter.field + "]" + " " + "DESC";
        return sorterQuote;
    }
    else if (sorter.order === "ascend") {
        sorterQuote = '[' + sorter.field + "]" + " " + "ASC";
        return sorterQuote;
    }
    else {
        return;
    }
}


export const userInitials = (name, lastname) => {
    let nameInitial = name ? name.charAt(0) : "";
    let lastnameInital = lastname ? lastname.charAt(0) : "";
    return nameInitial + lastnameInital;

}

export const handleDateConvertDayJs = (date) => {
    if (date) {
        let formatedDate = dayjs(date).format('YYYY-MM-DD');
        return formatedDate
    }
    return null
}


export const manageProductProductionDate = (date) => {
    if (date) {
        let dateToString = date.toString();
        let first4digits = dateToString.substring(0, 4);
        let last2digits = dateToString.slice(-2);

        let productDate = `${first4digits + "/" + last2digits}` 
        return productDate;
    }
    else {
        return "";
    }
}

export const getAppaltoCode = async (roleid) => {
    let appaltoCodeObj = { appaltoCode: null, disabledAppaltoCode: false };
    await UserServices.getInternalApplatoCode(roleid)
        .then(response => response.data)
        .then(resp => {
            if (resp.success) {
                let respData = resp.responseObject.value.appaltoCode;
                appaltoCodeObj.disabledAppaltoCode = true;
                appaltoCodeObj.appaltoCode = respData;
            }
            else {
                appaltoCodeObj = null;
            }
        }).catch(error => {
            appaltoCodeObj = null;
        });

    return appaltoCodeObj;
}

 export const statusColorIndicator = (processOrder) => {
    if(processOrder){
        if(processOrder === 5){
            return "green";
        }
        else if (processOrder === 4){
            return "orange";
        }
        else if (processOrder === 3){
            return "white";
        }
        else if (processOrder === 2){
            return "yellow";
        }
        else if (processOrder === 6){
            return "red";
        }
        else{
            return ""
        }
    }
  }