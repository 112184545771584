import React, { useEffect, useMemo, useState } from 'react';
import { Table, message, Button, Col, Row } from 'antd';
import WarrantyServices from '../../../services/warrantyServices';
import dayjs from 'dayjs';
import { dateFormat, excelfileExtension, excelfileType, tablePageSize } from '../../../shared/utils/constants';
import { statusColorIndicator } from '../../../shared/functions/sharedFunctions';
import { notifyMessages } from '../../../shared/utils/errorMesages';
import * as XLSX from 'xlsx';
import * as FileSaver from 'file-saver';
import { ExportOutlined } from '@ant-design/icons';
import moment from 'moment';


const ClientWarranties = ({tableParams, isActive}) => {

    const [warrantyList, setWarrantyList] = useState(null);
    const [loadingWarrantyList, setLoadingWarrantyList] = useState(false);
    const [loadingExport, setLoadingExport] = useState(false);
    const [pageNumber, setPageNumber] = useState(1);
    const [totalCountRecords, setTotalCountRecords] = useState(null);
    const [sorterColumn, setSorterColumn] = useState("");
    const [sorterType, setSorterType] = useState(true);
    

  useEffect(() => {
    if(isActive){
        getWarrantyList();
    }
  }, [pageNumber, sorterColumn, sorterType, tableParams]);

  const getWarrantyList = async (isExport) => {
    let filterObj = {
      elementsNo: tablePageSize,
      pageNo: isExport ? null : pageNumber,
      sortColumn: sorterColumn,
      ascOrdering: sorterType,
      ...tableParams
    };
    

    setLoadingWarrantyList(isExport ? false : true);
    setLoadingExport(isExport ? true : false);

    await WarrantyServices.getWarrantyList(filterObj)
        .then(response => response.data)
        .then(resp => {
            if (resp.success) {
                let respData = resp.responseObject.value;

                if (isExport) {
                    generateExcelFile(respData.warrantyDataList);
                }
                else {
                    let record = respData.warrantyDataList.find(function (element) {
                        return element.recordNo != null;
                    });
                    setWarrantyList(respData.warrantyDataList);
                    setTotalCountRecords(record ? record.recordNo : "")
                    setLoadingWarrantyList(false);
                }                    
            }
            else {
                setLoadingWarrantyList(false);
                message.error(notifyMessages.errorAccured);
            }
        }).catch(error => {
            setLoadingWarrantyList(false);
            message.error(notifyMessages.errorAccured);
        });
  }


  const onPageSorterChange = (page, filters, sorter) => {
      if (page) {
          if (sorter && sorter.field) {
              setSorterColumn(sorter.field);
              setPageNumber(page.current);

              if (sorter.order === "descend") {
                  setSorterType(false);
              }
              if (sorter.order === "ascend") {
                  setSorterType(true);
              }
          }
          else {
              setPageNumber(page.current);
              setSorterColumn("");
              setSorterType(true);
          }
      }
  };

  const warrantyColumns = useMemo(()=>[
      {
        width: "5px",
        render(text, record) {
          return {
            props: {                
            className: statusColorIndicator(record.processOrder)
            }
          };
        }
      },
      {
          title: 'Tipo Garanzia',
          dataIndex: 'warrantyTypeName',
          sorter: (a, b) => {
              a = a.warrantyTypeName || '';
              b = b.warrantyTypeName || '';
              return a.localeCompare(b)
          },
          showSorterTooltip: [false],
      },
      {
          title: 'Appalto Code',
          dataIndex: 'appaltoCode',
          sorter: (a, b) => {
              a = a.appaltoCode || '';
              b = b.appaltoCode || '';
              return a.localeCompare(b)
          },
          showSorterTooltip: [false],
      }, 
      {
          title: 'Ragione Sociale',
          dataIndex: 'companyName',
          sorter: (a, b) => {
              a = a.companyName || '';
              b = b.companyName || '';
              return a.localeCompare(b)
          },
          showSorterTooltip: [false],
      }, 
      {
          title: 'Stato Garanzia',
          dataIndex: 'warrantyStatusDescription',
          sorter: (a, b) => {
              a = a.warrantyStatusDescription || '';
              b = b.warrantyStatusDescription || '';
              return a.localeCompare(b)
          },
          showSorterTooltip: [false],
      }, 
      {
          title: 'Tipo Adesione',
          dataIndex: 'unionTypeName',
          sorter: (a, b) => {
              a = a.unionTypeName || '';
              b = b.unionTypeName || '';
              return a.localeCompare(b)
          },
          showSorterTooltip: [false],
      },  
      {
          title: 'Data Acquisto',
          dataIndex: 'productPurchaseDate',
          render: (text, record) => (text ? dayjs(text).format(dateFormat) : ""),
          sorter: (a, b) => {
              a = a.productPurchaseDate || '';
              b = b.productPurchaseDate || '';
              return a.localeCompare(b)
          },
          showSorterTooltip: [false],
      }, 
      {
          title: 'Data Adesione',
          dataIndex: 'warrantyDate',
          render: (text, record) => (text ? dayjs(text).format(dateFormat) : ""),
          sorter: (a, b) => {
              a = a.warrantyDate || '';
              b = b.warrantyDate || '';
              return a.localeCompare(b)
          },
          showSorterTooltip: [false],
      }, 
      {
          title: 'Data di Scadenza',
          dataIndex: 'expirationDate',
          render: (text, record) => (text ? dayjs(text).format(dateFormat) : ""),
          sorter: (a, b) => {
              a = a.expirationDate || '';
              b = b.expirationDate || '';
              return a.localeCompare(b)
          },
          showSorterTooltip: [false],
      }
  ],[]);

  const generateExcelFile = (warrantyData) => {
    let formatedData = [];

    if (warrantyData && warrantyData.length > 0) {

        warrantyData.map(item => {
            formatedData.push({
                warrantyTypeName: item.warrantyTypeName,
                appaltoCode: item.appaltoCode,
                companyName: item.companyName,
                warrantyStatusDescription: item.warrantyStatusDescription,
                unionTypeName: item.unionTypeName,
                purchaseDate: item.productPurchaseDate ? moment(item.productPurchaseDate).format(dateFormat) : "",
                creationDate: item.warrantyDate ? moment(item.warrantyDate).format(dateFormat) : "",
                expirationDate: item.expirationDate ? moment(item.expirationDate).format(dateFormat) : ""
            })
        });

        const fileName = `ESPORTAZIONE-GARANZIE-${moment().format(dateFormat)}`;
        let headers = [['Tipo Garanzia', 'Appalto Code', 'Ragione Sociale', 'Stato Garanzia', 'Tipo Adesione', 'Data Acquisto', 'Data Adesione', 'Data di Scadenza']];
        const ws = XLSX.utils.json_to_sheet([]);
        XLSX.utils.sheet_add_json(ws, formatedData, { origin: 'A2', skipHeader: true })
        XLSX.utils.sheet_add_aoa(ws, headers);
        const wb = { Sheets: { 'Esportazione Garanzie': ws }, SheetNames: ['Esportazione Garanzie'] };

        let wsColsWidthFormatter = [
            { wpx: 200 }, // column width
            { wpx: 100 }, // column width
            { wpx: 120 }, // column width
            { wpx: 100 }, // column width
            { wpx: 100 }, // column width
        ];
        ws['!cols'] = wsColsWidthFormatter;

        const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
        const data = new Blob([excelBuffer], { type: excelfileType });
        FileSaver.saveAs(data, fileName + excelfileExtension);
        setLoadingExport(false);

    }
    else {
        message.warning(notifyMessages.noDataFound);
        setLoadingExport(false);
    }
  }

  const onExportWarrantyList = () => {
    getWarrantyList(true);
  }
  const paginationObject = useMemo(()=>{
    return {
        pageSize: tablePageSize,
        showSizeChanger: false,
        total: totalCountRecords ? totalCountRecords : "",
        current: pageNumber
    }
  },[totalCountRecords,pageNumber])

  return (
    <Row justify="center" align="top" gutter={[16, 16]}>
        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Table
                size='small'
                columns={warrantyColumns}
                dataSource={warrantyList}
                rowKey={(obj) => obj.id}
                loading={loadingWarrantyList}
                onChange={(pagination, filters, sorter) => onPageSorterChange(pagination, filters, sorter)}
                pagination={paginationObject}
            />

        </Col>

        <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Button onClick={() => onExportWarrantyList()} loading={loadingExport} type="dashed" icon={<ExportOutlined />}>Esporta garanzie</Button>
        </Col>

    </Row>
  );
};

export default ClientWarranties;
