
import { Button, Col, Collapse, Input, Row, Form, Switch, DatePicker, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { dateFormat, tablePageSize } from '../../shared/utils/constants';
import ClientsServices from '../../services/clientServices';
import { useSelector } from 'react-redux';
import { formatDate, handleDateConvertDayJs } from '../../shared/functions/sharedFunctions';


const PromotionsFilters = ({ onFilterData, onResetData, filterValues, filtersInitValues }) => {

    const [form] = Form.useForm();

    const currentUser = useSelector(state => state.currentUser.loggedinUserData);

    const [startDateToggle, setStartDateToggle] = useState(filterValues.filterbystartdate);
    const [endDateToggle, setEndDateToggle] = useState(filterValues.endDate);

    const [disabledStartDate, setDisabledStartDate] = useState(false);
    const [disabledEndDate, setDisabledEndDate] = useState(false);

    const [sentC4C, setSentC4C] = useState(filterValues.sentC4C);

    const [clientsList, setClientsList] = useState(null);
    const [loadingClientsList, setLoadingClientsList] = useState(false);

    useEffect(() => {
        getClientList();
        if (startDateToggle) {
            setDisabledEndDate(true);
        }
    }, []);

    const handleStartDateToggle = (checked) => {
        setStartDateToggle(checked);
        if (checked) {
            setDisabledEndDate(true);
            setDisabledStartDate(false);
            setEndDateToggle(false);
            form.setFieldValue({ endDate: null });
            form.setFieldsValue({ endDateToggle: false });
        } else {
            form.setFieldValue('startDate', null);
            setDisabledEndDate(true);
            setDisabledStartDate(true);
        }
    };

    const handleEndDateToggle = (checked) => {
        setEndDateToggle(checked);
        if (checked) {
            setDisabledStartDate(true);
            setDisabledEndDate(false);
            setStartDateToggle(false);
            form.setFieldsValue({ startDateToggle: false });
        } else {
            form.setFieldValue('endDate', null); 
            setDisabledStartDate(true);
            setDisabledEndDate(true);
        }
    };

    const onSentC4CChange = (checked) => {
        setSentC4C(checked);
    };

    const onFinish = (values) => {
        const { startDateToggle, endDateToggle } = values;

        values.filterbystartdate = startDateToggle;
        values.filterbyenddate = endDateToggle;

        if (startDateToggle) {
            values.startDate = form.getFieldValue('startDate');
        }

        if (endDateToggle) {
            values.endDate = form.getFieldValue('endDate');
        }

        if (values.customerName) {
            const [name, surname] = values.customerName.split(' ');
            values.customerName = name;
            values.customerSurname = surname;
        }

        delete values.startDateToggle;
        delete values.endDateToggle;


        let formatedStartDate = handleDateConvertDayJs(values.startDate);
        values.startDate = formatedStartDate;

        let formatedEndDate = handleDateConvertDayJs(values.endDate);
        values.endDate = formatedEndDate;

        onFilterData(values);
    };

    const onClearFilter = () => {
        form.setFieldsValue(filtersInitValues);
        onResetData();
    };

    const getClientList = async () => {
        let filterObj = { enabled: true, name: "", surname: "", fiscalCode: "", email: "", region: "", customerCode: null, userID: null };
        filterObj["elementsNo"] = tablePageSize;
        filterObj["pageNo"] = 0;
        filterObj["sortColumn"] = "";
        filterObj["ascOrdering"] = true;
        filterObj["userID"] = currentUser.id

        setLoadingClientsList(true);
        await ClientsServices.getClientsList(filterObj)
            .then(response => response.data)
            .then(resp => {
                if (resp.success) {
                    let respData = resp.responseObject.value;
                    let clientsPiclist = respData.customerList.reduce((res, item) => {
                        //res.push({ value: item.id, label: (item.name + " " + item.surname + " - " + item.fiscalCode) });
                        let label = "";
                        if (item.name) {
                            label += item.name;
                        }
                        if (item.surname) {
                            if (label.length > 0) {
                                label += " ";
                            }
                            label += item.surname;
                        }
                        if (item.fiscalCode) {
                            if (label.length > 0) {
                                label += " - ";
                            }
                            label += item.fiscalCode;
                        }

                        if (label.length > 0) {
                            res.push({ value: item.id, label });
                        }

                        return res;
                    }, []);
                    setClientsList(clientsPiclist);
                    setLoadingClientsList(false);
                }
                else {
                    setLoadingClientsList(false);
                }
            }).catch(error => {
                setLoadingClientsList(false);
            });
    }

    const FilterContent = () => {
        return (
            <div className='promotionsFilters'>
                <Row gutter={[16, 16]} className='rowmb0'>
                    <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>

                        <Form
                            form={form}
                            name="filters"
                            layout="vertical"
                            onFinish={onFinish}
                            autoComplete="off"
                        >

                            <Row gutter={{ lg: 24 }} className='rowmb0'>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Da"
                                        name="startDate"
                                    >
                                        <DatePicker style={{ width: "100%" }} format={dateFormat} disabled={disabledStartDate} />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="A"
                                        name="endDate"
                                    >
                                        <DatePicker style={{ width: "100%" }} format={dateFormat} disabled={disabledEndDate} />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="PNC"
                                        name="pnc"
                                        initialValue={filterValues.pnc}
                                    >
                                        <Input placeholder="PNC" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>

                                    <Form.Item
                                        label="Cliente"
                                        name="customerID"
                                    >
                                        <Select
                                            showSearch
                                            loading={loadingClientsList}
                                            disabled={loadingClientsList}
                                            allowClear={false}
                                            placeholder="Cliente"
                                            optionFilterProp="children"
                                            filterOption={(input, option) => (option?.label ?? '').toLowerCase().includes(input.toLowerCase())}
                                            options={clientsList}
                                        />
                                    </Form.Item>

                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Codice Promozionale"
                                        name="promotionalCode"
                                        initialValue={filterValues.promotionalCode}
                                    >
                                        <Input placeholder="Codice Promozionale" />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Attivata"
                                        name="active"
                                        valuePropName="checked"
                                        initialValue={filterValues.active}
                                    >
                                        <Switch />
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={{ lg: 24 }} className='rowmb0'>
                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Filtra per data inizio"
                                        name="startDateToggle"
                                        valuePropName="checked"
                                        initialValue={filterValues.filterbystartdate}
                                    >
                                        <Switch onChange={handleStartDateToggle} disabled={endDateToggle} />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Filtra per data fine"
                                        name="endDateToggle"
                                        valuePropName="checked"
                                        initialValue={filterValues.endDate}
                                    >
                                        <Switch onChange={handleEndDateToggle} disabled={startDateToggle} />
                                    </Form.Item>
                                </Col>

                                <Col className="gutter-row" xs={{ span: 24 }} sm={{ span: 6 }} md={{ span: 4 }} lg={{ span: 4 }}>
                                    <Form.Item
                                        label="Inviato a C4C"
                                        name="sentC4C"
                                        valuePropName="checked"
                                        initialValue={filterValues.sentC4C}
                                    >
                                        <Switch onChange={onSentC4CChange} />
                                    </Form.Item>
                                </Col>

                            </Row>

                            <Row gutter={{ lg: 24 }} className='rowmb0'>
                                <Col className="gutter-row filterButtons" xs={{ span: 24 }} sm={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
                                    <Form.Item>
                                        <Button className='mr10' type="primary" htmlType="submit">Filtra</Button>
                                    </Form.Item>

                                    <Form.Item>
                                        <Button onClick={() => onClearFilter()}>Rimuovi</Button>
                                    </Form.Item>

                                </Col>
                            </Row>

                        </Form>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <>
            <Collapse
                size='small'
                items={[
                    {
                        key: '1',
                        label: 'Filtri',
                        children: <FilterContent />
                    },
                ]}
            />
        </>
    );
}
export default PromotionsFilters;